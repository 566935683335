import { IconButton, Tooltip } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import React from 'react';
import useBundleTranslation from 'i18n';
import { ActionMenuItem } from 'components/common/action-menu/ActionMenu';
import useWallContext from 'components/wall/hooks/useWallContext';

export default function ExpertAnalysis({ isActionMenuItem }: { isActionMenuItem?: boolean }) {
    const { t } = useBundleTranslation(['components/wall/wall']);

    const { wallActions } = useWallContext(false);
    const handleOnClick = () => {
        wallActions.setExpertAnalysisField(true);
    };

    return (
        <>
            {isActionMenuItem ? (
                <ActionMenuItem
                    label={t('expert_analysis.add_link')}
                    desc={t('expert_analysis.desc')}
                    icon="expert-analysis"
                    onClick={handleOnClick}
                />
            ) : (
                <Tooltip title={t('expert_analysis.add_link')}>
                    <IconButton sx={{ p: '6px' }} onClick={handleOnClick}>
                        <IconMi icon="expert-analysis" fontSize="16" />
                    </IconButton>
                </Tooltip>
            )}
        </>
    );
}
