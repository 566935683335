import { Box, Fade } from '@mui/material';
import React from 'react';
import { CustomCellProps } from 'components/common/grid/';
import { getCellContent, getCellWithTooltip } from './index';
import { useClipboard } from 'use-clipboard-copy';
import { useSnackbar } from 'notistack';

export default function CopyTextCell({ text, subText, icon, rawHtml, align, value, headerRef }: CustomCellProps) {
    const clipboard = useClipboard();
    const { enqueueSnackbar } = useSnackbar();

    const headerContainer = headerRef?.current?.querySelector('.rs-table-cell');

    const onCopy = () => {
        if (text) {
            const copyValue = (value ?? text).toString();

            clipboard.copy(copyValue);
            enqueueSnackbar('Copied!', {
                variant: 'success',
                TransitionComponent: Fade,
            });
        }
    };

    const showTooltip = headerContainer && headerContainer.clientWidth < 100 && text && text > '';

    const content = (
        <Box sx={{ cursor: 'copy' }} onClick={onCopy}>
            {getCellContent(text, icon, rawHtml, align, subText)}
        </Box>
    );

    if (showTooltip) {
        return getCellWithTooltip(content, text, true);
    }

    return <>{content}</>;
}
