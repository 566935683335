import { FormControlProps } from './layout/control';
import { FormElementProps } from './index';
import React, { useContext, useEffect, useState } from 'react';
import { FormTabType, TabContext } from './tab';
import useBundleTranslation from 'i18n';
import { useQueryClient } from '@tanstack/react-query';
import { RelatesFields } from 'components/common/grid';
import { processSettingsUrl } from './formTools';
import GridComponent from '../grid/GridComponent';

export interface FormControlGridProps extends FormControlProps {
    lazy: boolean;
    elementProps: FormElementProps;
    setFormDirty: boolean;
    relatedFields: string[];
    removeHeaderIndent?: boolean;
    gridDescription?: any;
    jsonStore?: boolean;
}

export default function FormGridComponent({ controlProps }: { controlProps: FormControlGridProps }) {
    const tabContext = useContext<FormTabType | null>(TabContext);
    const { t } = useBundleTranslation([controlProps.elementProps.translationNS ?? 'components/common/grid/grid']);
    const [initialData, setInitialData] = useState(false);
    const queryClient = useQueryClient();

    if (controlProps.relatedFields.length > 0) {
        controlProps.relatedFields.forEach((field) => {
            const formWatchValue = controlProps.form.hookFormWatch(field);

            useEffect(() => {
                queryClient.invalidateQueries({ queryKey: [controlProps.elementProps.component.uid] });
            }, [formWatchValue]);
        });
    }

    const getRelatedFields = (): RelatesFields => {
        const relatedFields: RelatesFields = {};

        if (controlProps.relatedFields.length > 0) {
            controlProps.relatedFields.forEach((field) => {
                relatedFields[field] = controlProps.form.hookFormGetValues(field) ?? '';
            });
        }

        return relatedFields;
    };

    const onDataStoreChange = (dataStore: any[]) => {
        const setFormDirty = initialData && controlProps.setFormDirty;
        const gridUid = controlProps.name !== '' ? controlProps.name : controlProps.elementProps.component.uid;
        const data = controlProps.jsonStore ? JSON.stringify(dataStore) : dataStore;

        controlProps.form.hookFormSetValue(gridUid, data, {
            shouldDirty: setFormDirty,
        });

        setInitialData(true);
    };

    const [tabWasActive, setTabWasActive] = useState(controlProps.lazy === false ? true : tabContext?.isActive);
    useEffect(() => {
        if (tabContext?.isActive) {
            setTabWasActive(true);
        }
    }, [tabContext?.isActive]);

    const urlParams = { ...controlProps.elementProps.urlParams, ...(controlProps?.urlParams ?? {}) };
    return tabWasActive ? (
        <>
            <GridComponent
                {...controlProps.elementProps.component}
                settingsUrl={processSettingsUrl(
                    controlProps.elementProps.component.props.settingsUrl,
                    Object.keys(urlParams),
                    urlParams,
                )}
                setFormDirty={controlProps.setFormDirty}
                onDataStoreChange={onDataStoreChange}
                formGrid
                t={t}
                relatedFields={getRelatedFields()}
                removeHeaderIndent={controlProps.removeHeaderIndent}
                gridDescription={controlProps.gridDescription}
                form={controlProps.form}
            />
        </>
    ) : null;
}
