import { Box } from '@mui/material';
import React from 'react';
import { CustomCellProps } from 'components/common/grid/';
import { getCellContent, getCellWithTooltip } from './index';

export default function TextCell({
    text,
    tooltip,
    subText,
    icon,
    rawHtml,
    align,
    rawTooltipHtml,
    color,
    t,
    multilines,
}: CustomCellProps) {
    const mainText = (
        <Box className={'richtext-holder'}>
            {getCellContent(text, icon, rawHtml, align, subText, color, multilines)}
        </Box>
    );

    if (tooltip) {
        return getCellWithTooltip(mainText, tooltip, rawTooltipHtml, t);
    }

    return mainText;
}
