import React, { useEffect, useMemo, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import useBundleTranslation from 'i18n';
import { useQuery } from '@tanstack/react-query';
import { useDebounce } from 'use-debounce';
import { editorUsersAPI, UserItem } from 'api/editor/users';
import ReactSelect from 'components/common/react-select/ReactSelect';
import { FormElementControlPropsType, FormControlProps } from 'components/common/form/layout/control';
import { FormComponentBuilder } from 'components/common/form/layout';
import { setFormElementSharedState } from 'store/formSlice';
import { useDispatch } from 'react-redux';
import Components, { ComponentKey } from 'components/common/ComponentIndex';

export interface FormControlUserSelectionProps extends FormControlProps {
    lazy: boolean;
    unassigneable: boolean;
    set: string;
    caller: string;
    showUsers: number;
}

const BaseUserSelectionUrl = '/data/editor/index/get-users';

export default function UserSelection({
    controlProps,
    elementProps,
}: FormElementControlPropsType<FormControlUserSelectionProps>) {
    const { t } = useBundleTranslation([elementProps?.translationNS ?? 'app/editor/dataset']);

    const dispatch = useDispatch();
    const [searchedUser, setSearchedUser] = useState('');
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [queryEnabled, setQueryEnabled] = useState(false);
    const [selectData, setSelectData] = useState<UserItem[]>([]);

    const [search] = useDebounce(searchedUser, 300);

    const fetchUrl = useMemo(() => {
        if (controlProps.urlParams) {
            const urlParams = Object.keys(controlProps.urlParams).map((key) => {
                return controlProps.urlParams ? `${key}=${controlProps.urlParams[key]}` : '';
            });

            if (controlProps.caller > '') {
                urlParams.push(`caller=${controlProps.caller}`);
            }

            if (search > '') {
                urlParams.push(`search=${search}`);
            }

            return `${BaseUserSelectionUrl}/set/${controlProps.set}${
                urlParams.length > 0 ? '?' + urlParams.join('&') : ''
            }`;
        }

        return `${BaseUserSelectionUrl}/set/${controlProps.set}?caller=${controlProps.caller}&search=${search}`;
    }, [search, controlProps.caller]);

    const initUserDataUrl = useMemo(() => {
        if (controlProps.value) {
            return `${BaseUserSelectionUrl}/single-user/${controlProps.value}`;
        }

        return '';
    }, [controlProps.value]);

    const {
        data,
        refetch: fetchData,
        isFetching,
    } = useQuery<UserItem[], Error>({
        queryKey: [fetchUrl, search],
        queryFn: () => editorUsersAPI.getUsersSelection(fetchUrl),
        enabled: queryEnabled,
    });

    const { refetch: fetchSelectedUserData } = useQuery<UserItem[], Error>({
        queryKey: [initUserDataUrl],
        queryFn: () => editorUsersAPI.getUsersSelection(initUserDataUrl),
        enabled: false,
    });

    useEffect(() => {
        if (data) {
            setSelectData(data);
        } else {
            setIsDataLoading(true);
            fetchSelectedUserData().then((response) => {
                setIsDataLoading(false);
                if (response.data) {
                    setSelectData(response.data);
                    if (response.data?.[0]?.is_power_user_ind) {
                        // Update component Shared State
                        dispatch(
                            setFormElementSharedState({
                                formKey: controlProps.form.formKey,
                                componentName: controlProps.name,
                                sharedState: { is_power_user: response.data[0].is_power_user_ind },
                            }),
                        );
                    }
                }
            });
        }
    }, []);

    const formattedUsers = useMemo(() => {
        if (!selectData) return [];

        let users = selectData.map(
            ({ display_name, user_id, username, groups, is_power_user_ind, is_administrator_ind }: UserItem) => {
                return {
                    label: `${display_name} [${username}] ${groups ? `(${groups})` : ''}`,
                    value: String(user_id),
                    props: {
                        is_power_user: is_power_user_ind,
                        is_administrator_user: is_administrator_ind,
                        icon: {
                            value: 'avatar',
                            type: 'mi',
                        },
                    },
                };
            },
        );

        if (controlProps.showUsers != 0b111) {
            users = users.filter((user) => {
                if (controlProps.showUsers & 0b100 && user.props.is_administrator_user == 'Y') {
                    return true;
                }
                if (controlProps.showUsers & 0b010 && user.props.is_power_user == 'Y') {
                    return true;
                }
                if (
                    controlProps.showUsers & 0b001 &&
                    user.props.is_power_user != 'Y' &&
                    user.props.is_administrator_user != 'Y'
                ) {
                    return true;
                }
                return false;
            });
        }

        if (controlProps.unassigneable) {
            users.unshift({
                label: t('unassigned'),
                value: '',
                props: {
                    is_power_user: 'N',
                    is_administrator_user: 'N',
                    icon: {
                        value: 'avatar',
                        type: 'mi',
                    },
                },
            });
        }

        return users;
    }, [selectData, controlProps.unassigneable, t]);

    const changeUser = (nextValue: string) => {
        // Update component Shared State
        dispatch(
            setFormElementSharedState({
                formKey: controlProps.form.formKey,
                componentName: controlProps.name,
                sharedState: { ...(formattedUsers.find((data) => data.value == nextValue)?.props ?? {}) },
            }),
        );

        controlProps.onChange(nextValue);
        setSearchedUser('');
    };

    useEffect(() => {
        if (data) {
            setSelectData(data);
        }
    }, [data]);

    const hasHelper = typeof controlProps.helper != 'undefined';

    return (
        <Box>
            {controlProps.label && (
                <Typography sx={{ marginBottom: '0.35em' }} className={controlProps.labelClass}>
                    {controlProps.translate ? t(controlProps.label as string) : controlProps.label}
                </Typography>
            )}

            <Stack direction="row" width="100%" sx={{ width: controlProps.width }}>
                <Box sx={{ flexGrow: 1 }}>
                    <ReactSelect
                        disabled={controlProps.readOnly}
                        data={formattedUsers}
                        isLoading={isDataLoading || isFetching}
                        value={controlProps.value}
                        name={controlProps.name}
                        update={changeUser}
                        onInputChange={setSearchedUser}
                        onMenuOpen={() => setQueryEnabled(true)}
                    />
                </Box>
                {hasHelper &&
                    React.createElement(Components[controlProps.helper.name as ComponentKey] as React.FC, {
                        // @ts-ignore
                        value: controlProps.value,
                        afterSave: () => {},
                        helperProps: controlProps.helper,
                        controlProps: controlProps,
                    })}
            </Stack>
        </Box>
    );
}

export class UserSelectionControlBuilder extends FormComponentBuilder {
    prepareProps(): FormControlUserSelectionProps {
        let showUsers = Number(this.elementProps.componentProps?.showUsers);
        showUsers = isNaN(showUsers) ? 0b111 : showUsers;

        return {
            ...this.controlProps,
            lazy: this.elementProps.componentProps?.lazy ?? true,
            set: this.elementProps.componentProps?.set ?? 'all',
            caller: this.elementProps.componentProps?.caller ?? '',
            unassigneable: this.elementProps.componentProps?.unassigneable ?? true,
            urlParams: this.elementProps.componentProps?.urlParams ?? {},
            showUsers: showUsers,
        };
    }
}
