import { ReportContentNS } from 'components/report-content/index';
import React, { lazy, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { reportAPI } from 'api/report';
import LineChartInitialDataResponse = ReportContentNS.LineChartInitialDataResponse;
import AdditionalContentSettingsLineChart = ReportContentNS.AdditionalContentSettingsLineChart;
import { AssocArray } from 'tools/types';
import { ChartAxis } from 'components/chart';
import ComponentSettingsChartDataQueries = ReportContentNS.ComponentSettingsChartDataQueries;
import ComponentUpdateProps = ReportContentNS.ComponentUpdateProps;
import ComponentSettingsLineChart = ReportContentNS.ComponentSettingsLineChart;
import { applyChartOptionsForThumbnail, parseDate } from 'components/report-content/utils/tools';
import useComponentReady from 'components/report-content/hooks/useComponentReady';
import { Chart as HChart } from 'highcharts';
import useOnApplyAccessMapChange from 'components/report-content/hooks/useOnApplyAccessMapChange';
import useOnMeasurementTime from 'components/report-content/hooks/useOnMeasurementTime';
import { alpha } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Highcharts } from 'components/chart/Chart';
import useReportContentContext from 'components/report-content/hooks/useReportContentContext';
import { formatValueFull } from 'components/report-content/utils/valueFormatting';
import DisplayMask = ReportContentNS.DisplayMask;
import TooltipContainer from 'components/chart/TooltipContainer';
import { useDatasetReportTooltip } from 'components/report-content/tooltip/useDatasetReportTooltip';
import LineChartTooltip from 'components/report-content/components/line-chart/LineChartTooltip';
const Chart = lazy(() => import('components/chart/Chart'));

function getFormattedValue(row: AssocArray<string>, field: string): string {
    return row[field + '-formatted'] ?? row[field];
}

function getChartToolTipTemplate() {
    return (
        '<div class="universal-tooltip b-radius-3">' +
        '<div class="ut-inner-block t-darkgray fs-13">' +
        '<div class="dCloseToolTip ut-close-bt mi-close mt-0" style="display: none;"></div>' +
        '<div class="mb-5 mt-5">' +
        '<div>%s</div>' +
        '</div>' +
        '%s' +
        '</div>' +
        '</div>'
    );
}

function getSeriesOptions(seriesType: string, name: string, yAxis: ChartAxis, enabled: boolean, isStacking: boolean) {
    return {
        type: seriesType,
        name: name,
        yAxis: Number(yAxis == 'right'),
        dataLabels: {
            enabled: enabled,
            padding: 3,
            borderRadius: 3,
            backgroundColor: seriesType == 'line' ? 'none' : 'rgba(255, 255, 255, 0.6)',
            style: {
                color: '#222',
                textOutline: 'none',
            },
            //@ts-ignore
            formatter: function () {
                //@ts-ignore
                const point = this.point;
                return isStacking && point.percentage ? '' + point.percentage.toFixed(0) + '%' : point.key.y_f;
            },
        },
        data: [],
    };
}

const getChartToolTipOptions = (chart: HChart) => {
    return {
        style: {
            pointerEvents: 'auto',
        },
        shape: 'rect',
        useHTML: true,
        // hideDelay: 3000,
        backgroundColor: null,
        borderWidth: 0,
        padding: 0,
        shadow: false,

        positioner: function (boxWidth: number, boxHeight: number, point: any) {
            var xPos = point.plotX + 75;
            if (xPos + boxWidth > chart.chartWidth) {
                // Drop to left
                xPos = point.plotX - boxWidth;
            }
            var yPos = point.plotY;
            if (yPos + boxHeight > chart.chartHeight) {
                yPos -= yPos + boxHeight - chart.chartHeight;
            }

            // if (self.component_name == 'Bubble') {
            //     xPos += 35;
            // }

            return { x: xPos, y: yPos };
        },
    };
};

export default function LineChart({
    contentSettings,
    component,
    updateBlockSettings,
}: ComponentUpdateProps<ComponentSettingsLineChart>) {
    const theme = useTheme();
    theme.palette.background.default;

    const { pointToShow, getTooltipPointEvents, closePointComponent, isPointSelect, setIsHoveredOnTooltip } =
        useDatasetReportTooltip();

    const getToolTipWrapperStyle = () => {
        return `
            background-color: ${theme.palette.background.default};
            border-radius: 1;
            box-shadow: 0px 1px 4px 0px ${alpha(theme.palette.text.primary, 0.32)};
            color: ${alpha(theme.palette.text.primary, 0.8)};
            padding: 6px;
            font-size: 12px;
        `;
    };

    const { filtersString } = useReportContentContext(() => {});
    const filtersStrRef = useRef(filtersString);
    useEffect(() => {
        filtersStrRef.current = filtersString;
        resetData();
    }, [filtersString]);
    // Last request to server was made with this data
    const [lastDataQueries, setDataQueries] = useState<ComponentSettingsChartDataQueries | null>(null);
    const isThumbnail = contentSettings.forScreenShot && window.location.href.includes('thumbnail');
    const dataQueryKey = `report_block_initial_${component.datasetRendererBlockComponentId}_${contentSettings.segmentValueId}`;
    const queryClient = useQueryClient();
    const resetData = () => queryClient.removeQueries({ queryKey: [dataQueryKey] });
    const { data: initialData } = useQuery<LineChartInitialDataResponse, Error>({
        queryKey: [dataQueryKey],
        queryFn: () => {
            const lineChartSettings: AdditionalContentSettingsLineChart = {
                ...contentSettings,
                page: 0,
            };
            setDataQueries(component.settings.dataQueries);
            return reportAPI.getComponentData(component.settings, lineChartSettings, filtersStrRef.current);
        },
    });

    useEffect(() => {
        // Remove data on unmount
        return () => resetData();
    }, []);

    const [data, setData] = useState<Array<AssocArray<string>>>([]);

    // Reset all data on dataQueries change
    useEffect(() => {
        if (!initialData) {
            return;
        }
        if (
            lastDataQueries != null &&
            JSON.stringify(lastDataQueries) != JSON.stringify(component.settings.dataQueries)
        ) {
            resetData();
        }
    }, [component.settings.dataQueries]);

    useOnApplyAccessMapChange(contentSettings.applyAccessMap, resetData);
    useOnMeasurementTime(contentSettings.measurement_time, resetData);
    // Reset all data on Component Filter Change
    useEffect(() => {
        if (!initialData) {
            return;
        }
        resetData();
    }, [component.settings.groupFilter, component.settings.x_axis.field]);
    useEffect(() => {
        if (!initialData) {
            return;
        }
        resetData();
    }, [component.settings.bar_orientation]);

    useEffect(() => {
        if (!initialData) {
            return;
        }
        setData(initialData.dataResults.table);
    }, [initialData]);

    // if(this.settings.measureFields.length == 1 && this.settings.series == 'multiple' && this.settings.group_field && this.settings.colorFormationRulesData && this.settings.colorFormationRulesData.length > 0){
    //     var newData = [];
    //     this.settings.colorFormationRulesData.forEach(function(rule){
    //         var text = rule.text;
    //
    //         self.data.dataResults.table.forEach(function(record){
    //             var field = record.hasOwnProperty(component.settings.group_field + '-formatted') ? component.settings.group_field + '-formatted' : component.settings.group_field;
    //             if(record[field] == text){
    //                 newData.push(record);
    //             }
    //         })
    //     });
    //     this.data.dataResults.table = newData;
    // }

    // Highcharts.setOptions({global:{useUTC:false}});
    // Highcharts.setOptions(highstockChartTheme.theme);
    // self.el = $('#'+this.uid);
    // self.el.html(this.template_({"$properties":this.properties,"$settings":this.settings,"$data":this.data}));
    // self.initHeaderActions();
    // self.cloneToolTip = false;

    const xAxisSettingsTemplate = {
        enabled: false,
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        gridLineColor: 'transparent',
        labels: {
            enabled: false,
        },
        minorTickLength: 0,
        tickLength: 0,
        minPadding: 0,
        maxPadding: 0,
    };

    const yAxisTemplate = {
        opposite: false,
        title: {
            text: '',
            useHTML: true,
        },
        labels: {
            align: 'left',
        },
        plotLines: [
            {
                value: 0,
                width: 2,
                color: 'silver',
            },
        ],
    };
    // if (component.settings.customYAxis == 'Y') {
    //     if (component.settings.minY !== '' && component.settings.minY !== null && !isNaN(component.settings.minY)) {
    //         yAxisTemplate.min = component.settings.minY;
    //     }
    //     if (component.settings.maxY !== '' && component.settings.maxY !== null && !isNaN(component.settings.maxY)) {
    //         yAxisTemplate.max = component.settings.maxY;
    //     }
    // }
    //

    const options = {
        chart: {
            height: 600,
            style: {
                fontFamily: 'Arial, helvetica, sans-serif',
            },
            zooming: {
                mouseWheel: {
                    enabled: false,
                },
            },
        },
        tooltip: { enabled: false },
        legend: { enabled: true, useHTML: true },
        credits: { enabled: false },
        title: { text: '' },
        exporting: { enabled: false },
        rangeSelector: {
            selected: 0,
            enabled: true,
            buttons: [] as Array<any>,
        },
        xAxis: 'undefined' !== typeof xAxisSettingsTemplate ? xAxisSettingsTemplate : ({} as any),
        yAxis: [],
        plotOptions: {
            series: {
                animation: !contentSettings.forScreenShot,
            },
            area: { fillOpacity: 0.75 },
            // series: {
            //     //compare: 'percent'
            //     point: {
            //         events: {
            //             click: function () {
            //                 self.drawDrillToTooltip(this);
            //             },
            //         },
            //     },
            // },
        },
        navigator: { enabled: true },
        scrollbar: { enabled: true },
        series: [],
    };
    //

    //
    // var paletteColors = Highcharts.getOptions().colors;
    //

    //
    // if ('undefined' !== typeof chartDefaults) $.extend(true, options, chartDefaults);

    // if (component.settings.width > 0) options.chart.width = component.settings.width;
    // if (component.settings.height > 0) options.chart.height = component.settings.height;
    //
    var findDisplayMask = function (display_mask_id: string) {
        const r = contentSettings.displayMasks.find((m) => m.display_mask_id == display_mask_id);
        return r ? r : false;
    };
    //
    var dm: DisplayMask | false = false;
    var yAxisFormatter = function () {
        //@ts-ignore
        const v = this.value;
        return dm != false && 'date' === dm?.mask_type && dm?.date_format_string > ''
            ? Highcharts.dateFormat('%m/%d/%Y', v)
            : //@ts-ignore
              formatValueFull(v, dm);
    };

    const chart = useRef<HChart>();
    useComponentReady(chart.current);

    const xAxisCategories = useRef<Array<string>>([]);
    const chartOptions = useMemo(() => {
        if (isThumbnail) {
            applyChartOptionsForThumbnail(options);
        } else {
            options.chart.height = component.settings.height;
        }

        let toolTipOptions = undefined;
        if (chart.current) {
            toolTipOptions = getChartToolTipOptions(chart.current);
            //@ts-ignore
            toolTipOptions.formatter = function () {
                const self = this as any;
                const str = `<div style="${getToolTipWrapperStyle()}">
                        <span style="color: ${self.series.color}">${self.series.name}</span>:
                    ${
                        (seriesMap?.[self.series.name]?.[self.point.y] ?? '') +
                        (isStacking && self.point && self.point.percentage
                            ? ' (' + self.point.percentage.toFixed(0) + '%)'
                            : '')
                    }</div>`;
                // var html = sprintf(getChartToolTipTemplate(), str, getDrillToForTooltip(this));
                return str;
            };
        }

        // Zoom Buttons
        [
            { key: '1m', type: 'month', count: 1, text: '1m' },
            { key: '3m', type: 'month', count: 3, text: '3m' },
            { key: '6m', type: 'month', count: 6, text: '6m' },
            { key: 'ytd', type: 'ytd', text: 'YTD' },
            { key: '1y', type: 'year', count: 1, text: '1y' },
            { key: 'all', type: 'all', text: 'All' },
        ].forEach((button, i) => {
            if (component.settings?.x_axis?.defaultZoom == button.key) {
                options.rangeSelector.selected = i;
            }
            options.rangeSelector.buttons.push({ type: button.type, count: button.count, text: button.text });
        });

        let // isPreview = 'undefined' !== typeof dsRendererViewer && dsRendererViewer.isPreview,
            seriesMap: AssocArray<string> = {},
            seriesOptions: any = [];

        // Stacking
        let isStacking = false;
        //@ts-ignore
        options.plotOptions.series.stacking = undefined;
        if (component.settings.stacking && -1 !== ['normal', 'percent'].indexOf(component.settings.stacking)) {
            const cntColumn = component.settings.measureFields.length;
            if (
                cntColumn > 1 ||
                (1 == cntColumn &&
                    'multiple' == component.settings.series &&
                    (component.settings.group_field?.length ?? 0 > 0))
            ) {
                isStacking = true;
            }
        }
        if (isStacking) {
            if ('horizontal' !== component.settings.bar_orientation && 'percent' == component.settings.stacking) {
                yAxisFormatter = function () {
                    //@ts-ignore
                    return isNaN(this.value) || 'undefined' === typeof this.value ? '0' : this.value.toFixed() + '%';
                };
            }
            //@ts-ignore
            options.plotOptions.series.stacking = component.settings.stacking;
        }

        // Bar Orientation
        let isBarOrientation = false;
        if (component.settings.bar_orientation == 'horizontal') {
            if (component.settings.measureFields.findIndex((f) => f.display_type == 'column') != 1) {
                isBarOrientation = true;
            }
        }

        // Area Opacity
        if ('undefined' !== typeof component.settings.fillOpacity && component.settings.fillOpacity > 0) {
            options.plotOptions.area.fillOpacity = component.settings.fillOpacity;
        }

        // Sort values
        if (component.settings.bar_order != 'alphabet') {
            let sortBy = '';
            component.settings.measureFields.forEach((measure) => {
                if (sortBy == '') {
                    sortBy = measure.reference_name;
                }
            });
            if (sortBy) {
                data.sort(function (fA, fB) {
                    const a = parseFloat(fA[sortBy]);
                    const b = parseFloat(fB[sortBy]);
                    return component.settings.bar_order == 'lowest' ? a - b : b - a;
                });
            }
        }

        if (0 == data.length) {
            // if (!isPreview) self.triggerAfterDataLoaded(false);
            // self.el
            //     .find('.block-wrapper-chart-container:first')
            //     .html(
            //         '<img style="width:' +
            //             (component.settings.width > 0 ? component.settings.width + 'px' : '100%') +
            //             ';height:' +
            //             (component.settings.height > 0 ? component.settings.height + 'px' : '100%') +
            //             ';" src="' +
            //             globalConstants.homeSite +
            //             'img/default_preview_no_data.png' +
            //             '"/>'
            //     );
        } else {
            //     if (!isPreview) self.triggerAfterDataLoaded(true);
            let seriesCounter = -1,
                names: AssocArray<any> = {},
                yAxis = { ...yAxisTemplate },
                xColumn = component.settings.x_axis.field;
            if (!isThumbnail && component.settings.x_axis.title) {
                options.xAxis.title = { text: component.settings.x_axis.title, useHTML: true };
            }

            // if ('undefined' !== typeof component.settings.x_axis && component.settings.x_axis.display_mask_id > '') {
            //@ts-ignore
            // if ('undefined' === typeof options.xAxis.labels)

            var dmXAxis = findDisplayMask(component.settings.x_axis.display_mask_id);
            options.xAxis.labels = {
                enabled: !isThumbnail,
                formatter: function () {
                    // if (
                    //     'undefined' !== typeof dmXAxis &&
                    //     'undefined' !== typeof dmXAxis.mask_type &&
                    //     'date' === dmXAxis.mask_type &&
                    //     dmXAxis.date_format_string > ''
                    // )
                    //     if (!isNaN(this.value))
                    //         return Highcharts.dateFormat(
                    //             mysql_dateformat_to_highchart_dateformat(dmXAxis),
                    //             this.value
                    //         );
                    //     else return this.value;

                    if (component.settings.x_axis.value_type == 'datetime') {
                        if (Number(this.value) == this.value) {
                            return Highcharts.dateFormat('%m/%d/%Y', this.value);
                        }
                    }

                    return this.value;
                    // return self.formatValue(this.value, dmXAxis);
                },
            };

            // if ('undefined' !== typeof dmXAxis && 'undefined' !== typeof dmXAxis.mask_type) {

            // if ('date' === dmXAxis.mask_type && dmXAxis.date_format_string > '')
            //     options.xAxis.type = 'datetime';
            // }
            // }
            //
            if (component.settings?.y_axis?.length) {
                yAxis.title.text = component.settings.y_axis[0].title;
                yAxis.labels.align = 'right';
                //@ts-ignore
                options.yAxis.push(yAxis);

                for (let i = 0; i < component.settings.measureFields.length; i++) {
                    if ('right' == component.settings.measureFields[i].y_axis) {
                        continue;
                    }
                    if (
                        ['Count', 'Count Distinct'].indexOf(component.settings.measureFields[i].aggregation_function) ==
                        -1
                    ) {
                        dm = findDisplayMask(component.settings.measureFields[i].display_mask_id);
                    }
                    break;
                }

                //@ts-ignore
                options.yAxis[0].labels.formatter = yAxisFormatter;
                if (dm != false && 'date' == dm.mask_type && dm.date_format_string > '') {
                    //@ts-ignore
                    options.yAxis[0].type = 'datetime';
                }

                if (isStacking && 'Y' == component.settings.display_label) {
                    let offsetY = -5;
                    for (let i = 0; i < component.settings.measureFields.length; i++) {
                        if ('column' != component.settings.measureFields[i].display_type) {
                            offsetY = -15;
                            break;
                        }
                    }

                    //@ts-ignore
                    options.yAxis[0].stackLabels = {
                        enabled: true,
                        // formatter: function () {
                        //     if (isNaN(this.total) || 'undefined' === typeof this.total) return '';
                        //     return self.formatValue(this.total, dm);
                        // },
                        y: offsetY,
                        style: {
                            fontWeight: 'bold',
                            color: 'black',
                        },
                    };
                }

                if (component.settings.y_axis.length > 1) {
                    const yAxis = { ...yAxisTemplate };
                    yAxis.opposite = true;
                    //@ts-ignore
                    yAxis.title.text = component.settings.y_axis[1].title;
                    yAxis.labels.align = 'left';
                    let dmRight: DisplayMask | false = false;
                    for (let i = 0; i < component.settings.measureFields.length; i++) {
                        if ('right' == component.settings.measureFields[i].y_axis) {
                            continue;
                        }
                        if (
                            ['Count', 'Count Distinct'].indexOf(
                                component.settings.measureFields[i].aggregation_function,
                            ) == -1
                        ) {
                            dmRight = findDisplayMask(component.settings.measureFields[i].display_mask_id);
                        }
                        break;
                    }
                    //@ts-ignore
                    options.yAxis.push(yAxis);
                    //@ts-ignore
                    options.yAxis[1].labels.yAxisFormatter = function () {
                        //@ts-ignore
                        const v = this.value;
                        return dm != false && 'date' === dm.mask_type && dm.date_format_string > ''
                            ? Highcharts.dateFormat('%m/%d/%Y', v)
                            : //@ts-ignore
                              formatValueFull(v, dmRight);
                    };
                    if (dm != false && 'date' === dm.mask_type && dm.date_format_string > '') {
                        //@ts-ignore
                        options.yAxis[1].type = 'datetime';
                    }
                }
            }

            //@ts-ignore
            options.plotOptions.series.turboThreshold = 0;
            //
            //     if (component.settings.colorFormationRulesData)
            //         component.settings.colorFormationRulesData.forEach(function (rule) {
            //             component.settings.localPalette[_.escape(rule.text)] = rule.color;
            //         });
            //
            if (
                component.settings.x_axis.value_type == 'datetime' &&
                !isBarOrientation &&
                component.settings.bar_order == 'alphabet'
            ) {
                if (toolTipOptions) {
                    if ('N' !== component.settings.combine_tooltips) {
                        //@ts-ignore
                        toolTipOptions.formatter = function () {
                            const self = this as any;
                            if ('undefined' === typeof self.points[0].point.key) {
                                return false;
                            }

                            let str = `<div style="${getToolTipWrapperStyle()}">
                                <div>
                                <div>${self.points[0].point.key.x_f}</div> 
                                <div class="mb-5">`;
                            for (let i = 0; i < self.points.length; i++) {
                                str += `<div><span style="color:${self.points[i].series.color} ">
                                    ${self.points[i].series.name}
                                    </span>:
                                    ${self.points[i].point.key.y_f}
                                    </div>`;
                            }

                            str += '</div>';
                            // str += self.getDrillToForTooltip(this);
                            str += '</div>' + '</div>';
                            return str;
                        };
                    } else {
                        //@ts-ignore
                        toolTipOptions.shared = false;
                        //@ts-ignore
                        toolTipOptions.formatter = function () {
                            const self = this as any;
                            if ('undefined' === typeof self.point.key) {
                                return false;
                            }
                            let str = `
                                <div style="${getToolTipWrapperStyle()}">
                                    <div>
                                        <div class="dCloseToolTip" style="display: none;"></div>
                                        <div>${self.point.key.x_f}</div>
                                        <div>
                                            <div>
                                                <span style="color: ${self.series.color}">${self.series.name}</span>
                                                :${self.point.key.y_f}
                                            </div>
                                        </div>`;
                            // str += self.getDrillToForTooltip(this);
                            str += '</div>' + '</div>';
                            return str;
                        };
                    }
                }

                //@ts-ignore
                if ('undefined' === typeof options.plotOptions.column) options.plotOptions.column = {};
                //@ts-ignore
                options.plotOptions.column.minPointLength = 3;
                //@ts-ignore
                options.plotOptions.column.dataGrouping = { enabled: false };
                //@ts-ignore
                options.plotOptions.line = {
                    dataGrouping: {
                        enabled: false,
                    },
                };

                if (component.settings.measureFields.length == 1) {
                    const field = component.settings.measureFields[0];
                    const groupColumn =
                        'multiple' == component.settings.series && typeof component.settings.group_field != 'undefined'
                            ? component.settings.group_field
                            : '';
                    const valueColumn = field.reference_name;
                    const seriesType =
                        ['line', 'area', 'column'].indexOf(field.display_type ?? '') != -1
                            ? (field.display_type ?? 'line')
                            : 'line';

                    const measureField = component.settings.measureFields[0];
                    data.forEach((row) => {
                        const xVal = row[xColumn];

                        let gVal = '' == groupColumn ? field.column_name : getFormattedValue(row, groupColumn),
                            val = row[valueColumn];
                        if (component.settings.system_other_grouping_label == gVal) {
                            gVal = component.settings.merge_small_label;
                            // component.settings.localPalette[gVal] =
                            //     component.settings.merge_small_color == '' ? null : component.settings.merge_small_color;
                        }

                        if (typeof names[gVal] == 'undefined') {
                            seriesCounter++;

                            names[gVal] = seriesCounter;

                            seriesOptions[seriesCounter] = getSeriesOptions(
                                seriesType,
                                gVal,
                                field.y_axis,
                                'Y' == component.settings.display_label,
                                isStacking && 'percent' == component.settings.stacking,
                            );

                            if ('' == groupColumn && field.color && '#ffffff' != field.color)
                                seriesOptions[seriesCounter].color = field.color;
                            else {
                                // if ('undefined' !== typeof component.settings.localPalette[gVal])
                                //     seriesOptions[seriesCounter].color = component.settings.localPalette[gVal];
                                // else seriesOptions[seriesCounter].color = self.getPaletteColor(gVal, seriesCounter, paletteColors);
                            }
                        }

                        if (!isNaN(parseFloat(val)) && null != xVal) {
                            seriesOptions[names[gVal]].data.push({
                                x: parseDate(xVal).getTime(),
                                y: parseFloat(val),
                                key: {
                                    name_f: measureField.column_name,
                                    x_f: getFormattedValue(row, xColumn),
                                    y_f: getFormattedValue(row, valueColumn),
                                },
                            });
                        }
                    });
                } else {
                    component.settings.measureFields.forEach((field, index) => {
                        const seriesType =
                            field.display_type && -1 !== ['line', 'area', 'column'].indexOf(field.display_type ?? '')
                                ? field.display_type
                                : 'line';

                        seriesOptions[index] = getSeriesOptions(
                            seriesType,
                            field.column_name,
                            field.y_axis,
                            component.settings.display_label == 'Y',
                            isStacking && 'percent' == component.settings.stacking,
                        );
                        seriesOptions[index].color = field.color;
                    });

                    data.forEach((row) => {
                        const xVal = row[xColumn];
                        component.settings.measureFields.forEach((field, fieldIndex) => {
                            const val = parseFloat(row[field.reference_name]);
                            if (isNaN(val) || null == xVal) {
                                return;
                            }
                            seriesOptions[fieldIndex].data.push({
                                x: parseDate(xVal).getTime(),
                                y: val,
                                key: {
                                    name_f: field.column_name,
                                    x_f: getFormattedValue(row, xColumn),
                                    y_f: getFormattedValue(row, field.reference_name),
                                },
                            });
                        });
                    });
                }

                if (seriesOptions.length === 1) options.legend.enabled = false;
                // self.el.find('.block-wrapper-chart-container:first').highcharts('StockChart', options);
            } else {
                options.navigator.enabled = false;
                options.scrollbar.enabled = false;
                options.rangeSelector.enabled = false;

                if (1 === component.settings.measureFields.length) {
                    const measureField = component.settings.measureFields[0];

                    const groupColumn =
                            'multiple' === component.settings.series &&
                            'undefined' !== typeof component.settings.group_field
                                ? component.settings.group_field
                                : '',
                        valueColumn = measureField.reference_name;
                    const seriesType =
                        measureField.display_type &&
                        -1 !== ['line', 'area', 'column'].indexOf(measureField.display_type ?? '')
                            ? isBarOrientation && 'column' == measureField.display_type
                                ? 'bar'
                                : (measureField.display_type ?? 'line')
                            : 'line';

                    data.forEach((row) => {
                        const xVal = getFormattedValue(row, xColumn),
                            gVal = '' == groupColumn ? measureField.column_name : getFormattedValue(row, groupColumn);

                        let index = xAxisCategories.current.indexOf(xVal);

                        const val =
                            'datetime' === measureField.value_type &&
                            -1 === ['Count', 'Count Distinct'].indexOf(measureField.aggregation_function)
                                ? parseDate(row[valueColumn]).getTime()
                                : parseFloat(row[valueColumn]);

                        //@ts-ignore
                        if (isNaN(val)) {
                            return;
                        }

                        // if (component.settings.system_other_grouping_label == gVal) {
                        //     gVal = component.settings.merge_small_label;
                        //     component.settings.localPalette[gVal] =
                        //         component.settings.merge_small_color == '' ? null : component.settings.merge_small_color;
                        // }

                        if ('undefined' === typeof seriesMap[gVal]) {
                            //@ts-ignore
                            seriesMap[gVal] = {};
                        }
                        //@ts-ignore
                        seriesMap[gVal][val] = getFormattedValue(row, valueColumn);

                        if (-1 === index) {
                            index = xAxisCategories.current.length;
                            xAxisCategories.current.push(xVal);
                        }

                        if ('undefined' === typeof names[gVal]) {
                            seriesCounter++;
                            names[gVal] = seriesCounter;

                            seriesOptions[seriesCounter] = getSeriesOptions(
                                seriesType,
                                gVal,
                                measureField.y_axis,
                                component.settings.display_label == 'Y',
                                isStacking && 'percent' == component.settings.stacking,
                            );

                            if ('' == groupColumn && measureField.color && '#ffffff' != measureField.color)
                                seriesOptions[seriesCounter].color = measureField.color;
                            else {
                                // if ('undefined' !== typeof component.settings.localPalette[gVal])
                                //     seriesOptions[seriesCounter].color = component.settings.localPalette[gVal];
                                // else
                                //     seriesOptions[seriesCounter].color = self.getPaletteColor(
                                //         gVal,
                                //         seriesCounter,
                                //         paletteColors
                                //     );
                            }
                        }
                        seriesOptions[names[gVal]].data.push({
                            x: index,
                            y: val,
                            key: {
                                x_f: measureField.reference_name,
                                name_f: measureField.column_name,
                                y_f: getFormattedValue(row, measureField.reference_name),
                            },
                        });
                    });
                } else {
                    component.settings.measureFields.forEach((field, index) => {
                        const tmp_type =
                            field.display_type && -1 !== ['line', 'area', 'column'].indexOf(field.display_type ?? '')
                                ? isBarOrientation && 'column' === field.display_type
                                    ? 'bar'
                                    : field.display_type
                                : 'line';

                        seriesOptions[index] = getSeriesOptions(
                            tmp_type,
                            field.column_name,
                            field.y_axis,
                            component.settings.display_label == 'Y',
                            isStacking && 'percent' == component.settings.stacking,
                        );
                        seriesOptions[index].color = field.color;

                        //@ts-ignore
                        seriesMap[field.column_name] = {};
                    });

                    data.forEach((row) => {
                        const xVal = row[xColumn];

                        component.settings.measureFields.forEach((field, fieldIndex) => {
                            //@ts-ignore
                            const val =
                                component.settings.bar_order != 'alphabet' &&
                                //@ts-ignore
                                'datetime' === field.value_type &&
                                -1 === ['Count', 'Count Distinct'].indexOf(field.aggregation_function)
                                    ? parseDate(row[field.reference_name]).getTime()
                                    : parseFloat(row[field.reference_name]);

                            let index = xAxisCategories.current.indexOf(xVal);

                            if (-1 === index) {
                                index = xAxisCategories.current.length;
                                xAxisCategories.current.push(xVal);
                            }
                            //@ts-ignore
                            if (!isNaN(val)) {
                                seriesOptions[fieldIndex].data.push({
                                    x: index,
                                    y: val,
                                    key: {
                                        x_f: field.reference_name,
                                        name_f: field.column_name,
                                        y_f: getFormattedValue(row, field.reference_name),
                                    },
                                });
                                //@ts-ignore
                                seriesMap[field.column_name][val] = getFormattedValue(row, field.reference_name);
                            }
                        });
                    });
                }
                //@ts-ignore
                options.xAxis['categories'] = xAxisCategories.current;
                if (seriesOptions.length === 1) options.legend.enabled = false;
                // if (seriesOptions.length > 0)
                //     try {
                //         _.each(seriesOptions, function (a) {
                //             a.data = _.sortBy(a.data, function (b) {
                //                 return b.x;
                //             });
                //         });
                //     } catch (e) {}

                // self.el.find('.block-wrapper-chart-container:first').highcharts('Chart', options);
            }
        }

        // if (toolTipOptions) {
        //     //@ts-ignore
        //     options.tooltip = toolTipOptions;
        // }
        seriesOptions.forEach((s: any) => {
            s.point = getTooltipPointEvents();
        });
        options.series = seriesOptions;
        if (options.series.length) {
            //@ts-ignore
            const s = options.series.find((s) => s.type == 'bar' || s.type == 'column');
            if (s) {
                //@ts-ignore
                options.chart.type = s.type;
            }
        }

        return options;
    }, [data, component.settings]);

    useEffect(() => {
        if (isThumbnail) {
            return;
        }
        // Fix issue with default selected range
        setTimeout(() => {
            try {
                if (chart.current) {
                    chart.current.update({ rangeSelector: { selected: 0 } });
                }
                setTimeout(() => {
                    if (chart.current) {
                        chart.current.update({ rangeSelector: { selected: chartOptions.rangeSelector.selected } });
                    }
                }, 10);
            } catch (e) {}
        }, 10);
    }, [chart.current]);

    useEffect(() => {
        // Fix issue with xAxis labels
        setTimeout(() => {
            if (!chart.current) {
                return;
            }
            try {
                chart.current.update({ xAxis: { categories: xAxisCategories.current } }, true);
            } catch (e) {}
        }, 10);
    }, [JSON.stringify(xAxisCategories.current)]);

    return initialData ? (
        <div style={{ overflow: 'hidden', maxHeight: component.settings.height + 'px', position: 'relative' }}>
            <Chart chartOptions={chartOptions} afterChartCreated={(c: HChart) => (chart.current = c)} />
            {chart.current && pointToShow && (
                <TooltipContainer
                    onMouseEnter={() => setIsHoveredOnTooltip()}
                    closeComponent={closePointComponent}
                    point={pointToShow}
                    chart={chart.current}
                >
                    {(closeComponent) => (
                        <LineChartTooltip
                            point={pointToShow}
                            settings={component.settings}
                            contentSettings={contentSettings}
                            closeComponent={isPointSelect ? closeComponent : null}
                        />
                    )}
                </TooltipContainer>
            )}
        </div>
    ) : (
        // TODO: add loading mask
        <span></span>
    );
}
