export default {
    wrapper: {
        width: 1,
        position: 'relative',
        '&.view--compact': {
            pt: 4,
        },
        '&.view--dataset': {
            pt: 4,
            height: 1,
        },
    },
    content: {
        width: 1,
        pb: 2,
        '.view--dataset &': {
            height: 1,
            overflow: 'auto',
        },
    },
    fixedButtonHolder: {
        position: 'absolute',
        pl: 2,
        backgroundColor: 'background.default',
        right: '24px',
        zIndex: 3,
    },
    stickyHeader: {
        '.view--dataset &': {
            position: 'sticky',
            top: 0,
            zIndex: 2,
            backgroundColor: 'background.default',
            minHeight: '28px',
        },
    },
};
