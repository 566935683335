import { useNavigate } from 'react-router-dom';
import { Box, Link, Typography } from '@mui/material';
import styles from '../VirtualDatasetViever.styles';
import IconHandler from 'components/common/icon/IconHandler';
import { format } from 'date-fns/format';
import React, { Suspense, useCallback, useMemo, useState } from 'react';
import useBundleTranslation from 'i18n';
import { DatasetData, DatasetInfo } from 'components/dataset-viewer';
import TopicsBlock from 'app/search/global/results/block/TopicsBlock';
import GlossaryTermPopup from 'components/glossary-term-popup';
import CertificationData from 'components/common/CertificationData';

function DatasetMainInfo({ datasetInfo, datasetData }: { datasetInfo: DatasetInfo; datasetData: DatasetData }) {
    const { t } = useBundleTranslation(['components/dataset-viewer/dataset_viewer', 'components/common/navbar/navbar']);
    const [showTermsPopup, setShowTermsPopup] = useState(false);
    const [topicId, setTopicId] = useState(0);

    const navigate = useNavigate();

    const isCertified = useMemo(() => {
        return datasetInfo.certified === 'Y' && datasetInfo.certified_date;
    }, [datasetInfo.certified, datasetInfo.certified_date]);

    const handleTagClick = useCallback((isTagsOptions: boolean, topic: { topic_id: number | null; name: string }) => {
        if (topic.topic_id) {
            if (isTagsOptions) {
                navigate(`/search/global?tag=${topic.topic_id}`);
            } else {
                setShowTermsPopup(true);
                setTopicId(topic.topic_id);
            }
        }
    }, []);

    const topicIds: number[] = useMemo(() => {
        const result: number[] = [];

        if (datasetInfo && datasetInfo.topics && Object.keys(datasetInfo.topics).length > 0) {
            Object.keys(datasetInfo.topics).forEach((topicId: string) => {
                if (topicId === 'default-topic-type') {
                    return;
                }

                datasetInfo?.topics?.[topicId].topics.forEach((topic) => {
                    if (topic.topic_id && !result.includes(topic.topic_id)) {
                        result.push(topic.topic_id);
                    }
                });
            });
        }

        return result;
    }, [datasetInfo]);

    return (
        <Box sx={styles.box}>
            <Box sx={styles.row}>
                <Box sx={styles.header}>
                    <Box display="flex">
                        {isCertified && (
                            <>
                                <IconHandler
                                    sx={{ fontSize: 16, color: 'success.main', mr: 1 }}
                                    icon={{
                                        type: 'mi',
                                        value: 'certified',
                                    }}
                                />
                                <Typography sx={{ ...styles.certificatedRow }}>
                                    <CertificationData
                                        certificationData={{
                                            color: 'success.main',
                                            lastUpdatedBy: datasetInfo.certified_by_dn ?? '',
                                            lastUpdatedTime: format(
                                                new Date(datasetInfo?.certified_date || 0),
                                                'M/dd/yyyy',
                                            ),
                                        }}
                                    />
                                </Typography>
                            </>
                        )}
                    </Box>
                </Box>

                <Box sx={styles.rowInfo}>
                    <Box sx={styles.rowInfoHalf}>
                        {datasetInfo.category_name && (
                            <Typography sx={styles.item}>
                                <Box sx={styles.labelWrapper}>
                                    <IconHandler sx={{ fontSize: 16 }} icon={{ type: 'mi', value: 'categories' }} />
                                    <Typography fontWeight="bold">{t('category')}:</Typography>
                                </Box>
                                <Typography sx={styles.value}>{datasetInfo.category_name}</Typography>
                            </Typography>
                        )}

                        {datasetInfo.business_owner_dn && (
                            <Typography sx={styles.item}>
                                <Box sx={styles.labelWrapper}>
                                    <IconHandler sx={{ fontSize: 16 }} icon={{ type: 'mi', value: 'user' }} />
                                    <Typography fontWeight="bold">{t('business_owner')}</Typography>
                                </Box>
                                <Link
                                    href={`mailto: ${datasetInfo.business_owner_email}?subject=${datasetData.name}`}
                                    sx={styles.value}
                                >
                                    {datasetInfo.business_owner_dn}
                                </Link>
                            </Typography>
                        )}

                        {datasetInfo.technical_owner_dn && (
                            <Typography sx={{ ...styles.item, ...styles.emptyItem }}>
                                <Box sx={styles.labelWrapper}>
                                    <Typography fontWeight="bold">{t('technical_owner')}</Typography>
                                </Box>
                                <Link
                                    href={`mailto: ${datasetInfo.technical_owner_email}?subject=${datasetData.name}`}
                                    sx={styles.value}
                                >
                                    {datasetInfo.technical_owner_dn}
                                </Link>
                            </Typography>
                        )}
                        {datasetInfo.data_steward_dn && (
                            <Typography sx={{ ...styles.item, ...styles.emptyItem }}>
                                <Box sx={styles.labelWrapper}>
                                    <Typography fontWeight="bold">{t('data_steward')}:</Typography>
                                </Box>
                                <Link
                                    href={`mailto: ${datasetInfo.data_steward_email}?subject=${datasetData.name}`}
                                    sx={styles.value}
                                >
                                    {datasetInfo.data_steward_dn}
                                </Link>
                            </Typography>
                        )}
                        {datasetInfo.custom_fields?.map((field) => {
                            return (
                                <Typography sx={{ ...styles.item, ...styles.emptyItem }}>
                                    <Box sx={styles.labelWrapper}>
                                        <Typography fontWeight="bold">{field.label}:</Typography>
                                    </Box>
                                    {field.fields.map((f) => {
                                        return (
                                            <>
                                                <Typography sx={styles.value}>{f.name}</Typography>:
                                                <Typography sx={styles.value}>{f.values}</Typography>
                                            </>
                                        );
                                    })}
                                </Typography>
                            );
                        })}
                    </Box>
                </Box>

                <TopicsBlock
                    topics={datasetInfo.topics}
                    handleTagClick={handleTagClick}
                    elementId={datasetData.dataset_id}
                />
            </Box>
            <Suspense>
                {showTermsPopup && (
                    <GlossaryTermPopup
                        setShowPopup={setShowTermsPopup}
                        showPopup={showTermsPopup}
                        topicIds={topicIds}
                        topicId={topicId}
                    />
                )}
            </Suspense>
        </Box>
    );
}

export default DatasetMainInfo;
