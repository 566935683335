import { SxProps } from '@mui/system';
import { FormComponentValue } from 'components/common/form/layout/control';
import { PopupSettings } from 'components/common/popup/Popup';
import { IconType } from 'components/common/icon/IconHandler';
import { TFunction } from 'i18next';
import { FormRendererAPIType } from 'components/common/form';
import { RawTranslatedText } from 'tools/types';
import { RefObject } from 'react';

export type GridProps = {
    uid: string;
    settingsUrl?: string;
    settings?: any;
    data?: any;
    title?: string;
    t?: TFunction;
    formGrid?: boolean;
    setFormDirty?: boolean;
    onDataStoreChange?: (gridData: any[]) => void;
    reloadTrigger?: number;
    relatedFields?: RelatesFields;
    hideButtons?: boolean;
    removeHeaderIndent?: boolean;
    gridDescription?: any;
    height?: number;
    form?: FormRendererAPIType;
};

export interface NoSearchResultGridConfig {
    icon?: string;
    emptyText?: string;
}

export interface InfoButtonConfig {
    label: string;
    url: string;
}

export interface GridButtonsConfig {
    gridButtons: ButtonConfig[];
    controllerUrl: string;
    uid: string;
    massActionsProps?: {
        handleCheckRow: (event: any) => void;
        checkAllChecked: () => boolean;
        handleCheckAllRows: (event: any) => void;
        checkedRowsKeys: Array<string | number>;
        setCheckedKeys: (keys: Array<string | number>) => void;
        checkedRowsNames: Array<string | number>;
    };
    reloadGridData?: () => void;
    setPopupUrl?: (url: string) => void;
    setPopupType?: (type: PopupType) => void;
    setPopupMode?: (mode: PopupMode) => void;
    setPopupRedirectAfter?: (redirect: boolean) => void;
    setPopupConfig?: (popupConfig: PopupSettings) => void;
    setFormPostData?: (fromData: any) => void;
    setPopupGetMethod?: (getMethod: boolean) => void;
    setPopupComponent?: (popupComponent: string) => void;
    setPopupComponentProps?: (componentProps: { [key: string]: string }) => void;
    gridData: GridData;
    massActionsConfig?: MassActionsConfig;
    t: TFunction;
    setShowLoader?: (value: boolean) => void;
    filtersValue: FilterValue;
    form?: FormRendererAPIType;
}

export interface ColumnType {
    title?: string;
    name: string;
    hidden?: boolean;
    sortable?: boolean;
    hidable?: boolean;
    translate?: boolean;
    resizable?: boolean; // true by default
    tooltip?: string;
    visible?: boolean;
    width?: number;
    fixed?: 'left' | 'right';
    renderer?: {
        component: string;
        text?: string;
        href?: string;
        idColumn?: string;
        confirmation?: string;
        config?: any;
    };
}

export type MassActionsProps = {
    handleCheckRow?: (event: any) => void;
    handleCheckAllRows?: (event: any) => void;
    checkedRowsKeys?: Array<number | string>;
    checkAllChecked: () => boolean;
};

export type MassActionsConfig = {
    enabled: boolean;
    idColumn?: string;
};

export type AsyncGridProps = {
    uid: string;
    settingsUrl: string;
    formGrid: boolean;
    t?: TFunction;
    setFormDirty?: boolean;
    onDataStoreChange?: (gridData: any[]) => void;
    reloadTrigger: number;
    relatedFields?: RelatesFields;
    hideButtons?: boolean;
    removeHeaderIndent?: boolean;
    gridDescription?: any;
    height?: number;
    form?: FormRendererAPIType;
};

export type RelatesFields = {
    [key: string]: string;
};

export type LegendProps = {
    legendItems: LegendConfig[];
    gridName: string;
};

export type GridData = Array<GridDataRow>;

export type GridDataRow = {
    [key: string]: TransformedDataObject | string;
};

export type ColumnsWidth = {
    [key: string]: number;
};

export type VisibleColumns = {
    [key: string]: {
        visible: boolean;
        label: string;
        hidable: boolean;
    };
};

export type FilterValue = {
    [key: string]: string | number;
};

export type GridParams = {
    columns: {
        [key: string]: {
            width: number;
            hidden: boolean;
            hidable: boolean;
        };
    };
    fullwidthMode: string;
    gridView: string;
    version: number;
};

export type TransformedDataObject = {
    component: string;
    config: TransformedComponentConfig;
};

export type CellIconType = {
    type?: 'fa' | 'mi' | 'upload';
    value?: string;
    color?: string;
    class?: string;
    size?: number;
    useWrapper?: boolean;
};

export type TextAlign = 'inherit' | 'left' | 'center' | 'right' | 'justify';

export type TransformedComponentConfig = {
    text?: string;
    subText?: string;
    href?: string;
    icon?: CellIconType;
    confirmation?: string;
    confirmationUrl?: string;
    value?: string | null;
    data?: GridDataRow;
    checked?: 'Y' | 'N';
    tooltip?: string;
    popupConfig?: PopupSettings;
    popupType?: PopupType;
    popupMode?: PopupMode;
    component?: string;
    popupComponentProps?: { [key: string]: string };
    newTab?: boolean;
    rawHtml?: boolean;
    rawTooltipHtml?: boolean;
    align?: TextAlign;
    color?: string;
    dataTestCell?: string;
    deleteColumnName?: string;
};

export interface CustomCellProps extends TransformedComponentConfig {
    reloadGridData?: () => void;
    t?: TFunction;
    columnName?: string;
    setPopupUrl?: (url: string) => void;
    setPopupConfig?: (popupConfig: PopupSettings) => void;
    setPopupType?: (type: PopupType) => void;
    setPopupMode?: (mode: PopupMode) => void;
    changes?: DataChange[];
    setShowLoader?: (value: boolean) => void;
    disabled?: boolean;
    remove?: boolean;
    setPopupComponent?: (popupComponent: string) => void;
    setPopupComponentProps?: (componentProps: { [key: string]: string }) => void;
    setErrorMessage?: (message: string) => void;
    form?: FormRendererAPIType;
    download?: boolean;
    setGridDataChanged?: (state: boolean) => void;
    multilines?: number;
    filtersValue?: FilterValue;
    headerRef?: RefObject<HTMLDivElement>;
}

export interface ToggleCellProps extends CustomCellProps {
    enabledIcon?: CellIconType;
    disabledIcon?: CellIconType;
    color?: string;
}

export interface FontPreviewCellProps extends CustomCellProps {
    file: string;
}

export interface DataChange {
    old: string;
    new: string;
    name: string | HistoryChangeName;
}

export interface HistoryChangeName {
    text: string;
    href?: string;
    newTab?: boolean;
    icon?: CellIconType;
}

export interface LineageCellProps extends TransformedComponentConfig {
    id: string;
    type: string;
    sx?: SxProps;
}

export interface TagInCellItem {
    text: string;
    href?: string;
    newTab?: boolean;
    icon?: CellIconType;
    color?: string;
}

export interface TagCellProps extends TransformedComponentConfig {
    items?: TagInCellItem[];
    defaultColor?: string;
    modification?: string;
    value?: string;
}

export interface LoginAsCellProps extends TransformedComponentConfig {
    userName: string;
    userId: number;
    loginAsView?: boolean;
    disabled?: boolean;
}

export type LegendConfig = {
    type: string;
    message: string;
    className?: string;
    hidden?: boolean; // Set true to hide record from legend
};

export type RowExtraInfoConfig = {
    idColumn: string;
    messageColumn: string;
    rowHeight?: number;
};

export type ButtonConfig = {
    name: string;
    handler?: string;
    text?: RawTranslatedText;
    type: 'create' | 'mass' | 'custom';
    redirectAfter?: boolean;
    confirmation?: string;
    remoteConfirm?: boolean;
    url?: string;
    icon?: IconType;
    color?: string;
    variant?: string;
    redirect?: boolean;
    insert?: boolean;
    popupType?: PopupType;
    popupMode?: PopupMode;
    popupConfig?: PopupSettings;
    removeText?: string;
    getMethod?: boolean;
    filterRows?: {
        column: string;
        value: string;
    };
    download?: boolean;
    component?: string;
    async?: boolean;
    popupComponentProps?: { [key: string]: string };
    withSelected?: boolean;
    showErrorPopup?: boolean;
    errorTitle?: string;
    useFilters?: string[];
    saveBefore?: boolean;
};

export type DNDConfig = {
    enabled: boolean;
    url: string;
    idColumn?: string;
    hideTitle?: boolean;
    tooltip?: string;
};

export type PagerOptions = {
    visible: boolean;
    pageSize: number;
};

export type FilterElementProps = {
    component: string;
    value: string;
    onChange: (name: string, value: string | boolean) => void;
    id?: string;
    name: string;
    label: string;
    dataUrl?: string;
    lazyLoad?: boolean;
    isMulti?: boolean;
    filterData?: Array<FormComponentValue>;
    preloadOptions?: boolean;
    showTimeSelect?: boolean;
    placeholder?: string;
    key?: string;
    setPageIndex?: (value: number) => void;
};

export interface MassActionCheckCellProps {
    column?: ColumnType;
    dataKey: string;
    rowIndex?: number;
    rowData?: any;
    onChange?: (event: any) => void;
    reloadGridData?: () => void;
    checkedRowsKeys?: Array<string | number>;
    uid: string;
}

export type PopupType = 'form' | 'page' | 'component';
export type PopupMode = 'new' | 'duplicate' | 'edit';

export type GridPopupProps = {
    popupConfig?: PopupSettings;
    popupUrl: string;
    popupType: PopupType;
    popupMode: PopupMode;
    handleHidePopup: () => void;
    handleConfirmPopup: () => void;
    reloadGridData?: () => void;
    redirectAfter?: boolean;
    formPostData?: any;
    getMethod?: boolean;
    component?: string;
    setCheckedKeys?: (ids: (string | number)[]) => void;
    componentProps?: { [key: string]: string };
    gridData?: GridData;
    uid?: string;
    afterSave?: (response: any) => void;
};

export const getColumnLabel = (column: ColumnType, t: TFunction) => {
    const columnTitle = String(undefined !== column.title ? column.title : column.name);

    return false === column.translate ? columnTitle : String(t(columnTitle));
};

export type GridFilterConfig = {
    component: string;
    name: string;
    label: string;
    visible: boolean;
    value?: string;
    unhideable?: boolean;
};

export type GridChangeViewConfig = {
    name: string;
    label: string;
    icon: string;
};
