import React from 'react';
import { Box } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import useBundleTranslation from 'i18n';
import { renderToString } from 'react-dom/server';

interface NetworkErrorProps {
    view?: 'grid';
}

export const getNetworkErrorHtmlString = () => {
    return renderToString(<NetworkError view={'grid'} />);
};

export default function NetworkError(props: NetworkErrorProps) {
    const { t } = useBundleTranslation(['components/error/network_error']);
    const { view = 'default' } = props;
    return (
        <Box className={`network-error-message network-error-message--${view}`}>
            <Box className={'network-error-message__icon'}>
                <IconMi icon={'wireless-error'} />
            </Box>
            <Box>
                <Box>{t('network_error_title')}</Box>
                <Box>{t('network_error_sub_title')}</Box>
            </Box>
        </Box>
    );
}
