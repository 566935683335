import { editorTopicsAPI, TopicAssociations, TopicsInfoTopic } from 'api/editor/topics';
import React, { useState } from 'react';
import { alpha, Box, Button, CircularProgress, Grid, Link, Stack, Typography } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import LightTooltip from 'components/common/tooltip/LightTooltip';
import { openInNewTab } from 'tools/tools';
import useBundleTranslation from 'i18n';
import showdown from 'showdown';
import isOwnerVisible from 'tools/owners';
import { UserAuth } from 'store/auth';
import { useAppSelector } from 'store/hooks';
import DatabaseIcon from 'components/common/icon/DatabaseIcon';
import parse from 'html-react-parser';

const entity2icon: { [key: string]: string } = {
    dashboard_element: 'elements-2',
    dataset: 'datasets',
};
const converter = new showdown.Converter();

export default function TopicItem({
    topicInfo,
    sectionIcon,
}: {
    topicInfo: TopicsInfoTopic;
    sectionIcon: JSX.Element | null;
}) {
    const { t } = useBundleTranslation('components/glossary-term/glossary');
    const { configuration }: UserAuth = useAppSelector((state) => state.auth);

    const [topicAssociations, setTopicAssociations] = useState<TopicAssociations | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const showOwners =
        (topicInfo.business_owner_dn || topicInfo.technical_owner_dn || topicInfo.data_steward_dn) &&
        (isOwnerVisible(configuration.owners.business_owner, 'glossary') ||
            isOwnerVisible(configuration.owners.technical_owner, 'glossary') ||
            isOwnerVisible(configuration.owners.data_steward, 'glossary'));

    const getTopicPluginLabel = (pluginName: string, amount: number): string => {
        if (pluginName.includes('Microsoft Power BI')) {
            pluginName = 'Microsoft Power BI';
        }

        let label = pluginName + ' Object';

        if (amount > 1) {
            label = label + 's';
        }

        return `${amount} ${label}`;
    };

    const getTooltip = () => {
        if (topicAssociations) {
            const entities = topicAssociations.entities;
            const showPlugins = topicAssociations.plugin && topicAssociations.plugin_name;

            if (!entities) {
                return null;
            }

            return (
                <Stack direction={'column'} spacing={1} sx={{ fontSize: '12px' }}>
                    {Object.keys(entities).map((entity) => (
                        <Stack
                            key={entity}
                            direction={'row'}
                            justifyContent={'start'}
                            alignItems={'center'}
                            spacing={1}
                        >
                            <IconMi icon={entity2icon[entity]} sx={{ fontSize: 16 }} />
                            <Box>{` ${entities[entity]} ${
                                entities[entity] > 1 ? t(entity) : t(entity + '_singular')
                            }`}</Box>
                        </Stack>
                    ))}
                    {showPlugins &&
                        Object.keys(topicAssociations.plugin).map((pluginId) => (
                            <Stack
                                key={'plugin'}
                                direction={'row'}
                                justifyContent={'start'}
                                alignItems={'center'}
                                spacing={1}
                            >
                                <DatabaseIcon name={topicAssociations.plugin_name[parseInt(pluginId)]} />
                                <Box>
                                    {getTopicPluginLabel(
                                        topicAssociations.plugin_name[parseInt(pluginId)],
                                        topicAssociations.plugin[parseInt(pluginId)],
                                    )}
                                </Box>
                            </Stack>
                        ))}
                </Stack>
            );
        }

        return (
            <Stack direction={'row'} spacing={1}>
                <CircularProgress size={'16px'} />
                <Typography>{t('loading')}</Typography>
            </Stack>
        );
    };

    const loadAssociations = () => {
        if (!isLoading && !topicAssociations) {
            setIsLoading(true);
            editorTopicsAPI.getTopicsAssociations(topicInfo.topic_id).then((response) => {
                if (response.status === 'OK' && response.data) {
                    setTopicAssociations(response.data.topicAssociations);
                    setIsLoading(false);
                }
            });
        }
    };

    const onButtonClick = () => {
        openInNewTab(`/search/global?tag=${topicInfo.topic_id}`);
    };

    return (
        <>
            <Stack direction="row" justifyContent="space-between" marginBottom={2}>
                <Stack direction="row" alignItems="center">
                    {sectionIcon}
                    <Box
                        sx={{
                            ml: sectionIcon ? 1 : 0,
                            wordWrap: 'break-word',
                            overflow: 'hidden',
                            fontSize: '14px',
                            color: 'primary.main',
                            fontWeight: 600,
                        }}
                    >{` ${topicInfo.name}`}</Box>
                </Stack>
                <LightTooltip
                    title={getTooltip()}
                    arrow={false}
                    placement={'bottom-end'}
                    sx={{ '.MuiTooltip-tooltip': { mt: '4px !important', p: 1, borderRadius: '3px' } }}
                >
                    <Box display="flex" justifyContent="end">
                        <Button
                            variant="text"
                            color={'primary'}
                            startIcon={<IconMi icon={'external-link'} />}
                            onClick={onButtonClick}
                            onMouseOver={loadAssociations}
                        >
                            {t('view_other_items')}
                        </Button>
                    </Box>
                </LightTooltip>
            </Stack>
            <Grid container direction={'row'} justifyContent={'space-between'} spacing={2}>
                <Grid item xs={8}>
                    <Stack
                        fontWeight={600}
                        fontSize={'12px'}
                        color="primary"
                        display="flex"
                        alignItems="center"
                        sx={{ mr: 4 }}
                    >
                        {topicInfo.definition ? (
                            <Stack width={1}>
                                <Stack direction="row" alignItems="center">
                                    <Box sx={{ mr: 1, color: 'primary.main', fontSize: '10px' }}>{t('definition')}</Box>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: '1px',
                                            background: (theme) => alpha(theme.palette.primary.main, 0.24),
                                        }}
                                    ></Box>
                                </Stack>
                                <Box
                                    sx={{ mb: 2, mt: 0.5, color: 'text.primary', fontWeight: 400 }}
                                    className={'richtext-holder'}
                                >
                                    {parse(topicInfo.definition)}
                                </Box>
                            </Stack>
                        ) : null}
                        {topicInfo.description ? (
                            <Stack width={1}>
                                <Stack direction="row" alignItems="center">
                                    <Box sx={{ mr: 1, color: 'primary.main', fontSize: '10px' }}>
                                        {t('description')}
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: '1px',
                                            background: (theme) => alpha(theme.palette.primary.main, 0.24),
                                        }}
                                    ></Box>
                                </Stack>
                                <Box
                                    sx={{ mb: 2, mt: 0.5, color: 'text.primary', fontWeight: 400 }}
                                    className={'richtext-holder'}
                                >
                                    {parse(topicInfo.description)}
                                </Box>
                            </Stack>
                        ) : null}
                        {topicInfo.customFields[0]?.fields?.length ? (
                            <>
                                {topicInfo.customFields.map((customField) => {
                                    return (
                                        <Stack width={1}>
                                            <Stack direction="row" alignItems="center">
                                                <Box
                                                    sx={{
                                                        mr: 1,
                                                        color: 'primary.main',
                                                        fontSize: '10px',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    {customField.label}
                                                </Box>
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        height: '1px',
                                                        background: (theme) => alpha(theme.palette.primary.main, 0.24),
                                                    }}
                                                ></Box>
                                            </Stack>
                                            <Box sx={{ mb: 2, mt: 0.5, color: 'text.primary', fontWeight: 400 }}>
                                                {customField.fields.map((field) => {
                                                    return (
                                                        <Stack
                                                            width={1}
                                                            direction="row"
                                                            sx={{
                                                                padding: '8px 0',
                                                                '&:last-child': {
                                                                    paddingBottom: 0,
                                                                },
                                                                borderTop: (theme) =>
                                                                    `1px solid ${alpha(
                                                                        theme.palette.text.primary,
                                                                        0.08,
                                                                    )}}`,
                                                            }}
                                                        >
                                                            <Grid xs={6} direction="row" alignItems="center">
                                                                <Box
                                                                    sx={{
                                                                        mr: 1,
                                                                        fontSize: '12px',
                                                                        whiteSpace: 'nowrap',
                                                                    }}
                                                                >
                                                                    {field.name}
                                                                </Box>
                                                            </Grid>
                                                            <Grid xs={6}>
                                                                <Box
                                                                    sx={{
                                                                        fontSize: '12px',
                                                                        color: 'text.primary',
                                                                        fontWeight: 400,
                                                                        '& p': { m: 0 },
                                                                    }}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: converter.makeHtml(
                                                                            field.values.join(','),
                                                                        ),
                                                                    }}
                                                                ></Box>
                                                            </Grid>
                                                        </Stack>
                                                    );
                                                })}
                                            </Box>
                                        </Stack>
                                    );
                                })}
                            </>
                        ) : null}
                    </Stack>
                </Grid>
                <Grid item xs={4}>
                    {showOwners && (
                        <Stack
                            direction={'row'}
                            alignItems={'flex-start'}
                            spacing={1}
                            sx={{
                                padding: '12px',
                                background: (theme) => alpha(theme.palette.primary.main, 0.04),
                            }}
                        >
                            <Stack direction={'column'} width={1}>
                                {topicInfo.business_owner_dn &&
                                    isOwnerVisible(configuration.owners.business_owner, 'glossary') && (
                                        <Stack
                                            sx={{
                                                width: '100%',
                                                paddingBottom: '12px',
                                            }}
                                        >
                                            {`${configuration.owners.business_owner.label}: `}
                                            <Link
                                                href={`mailto: ${topicInfo.business_owner_email}?subject=${topicInfo.name}`}
                                                sx={{
                                                    cursor: 'pointer',
                                                    textDecoration: 'none',
                                                    display: 'flex',
                                                    mt: 1,
                                                }}
                                            >
                                                <IconMi sx={{ mr: 1 }} fontSize={'16'} icon={'user-groups'} />
                                                {topicInfo.business_owner_dn}
                                            </Link>
                                        </Stack>
                                    )}
                                {topicInfo.technical_owner_dn &&
                                    isOwnerVisible(configuration.owners.technical_owner, 'glossary') && (
                                        <Stack
                                            sx={{
                                                width: '100%',
                                                paddingBottom: '12px',
                                                paddingTop: '12px',
                                                borderTop: (theme) =>
                                                    `1px solid ${alpha(theme.palette.text.primary, 0.08)}}`,
                                            }}
                                        >
                                            {`${configuration.owners.technical_owner.label}: `}
                                            <Link
                                                href={`mailto: ${topicInfo.technical_owner_email}?subject=${topicInfo.name}`}
                                                sx={{
                                                    cursor: 'pointer',
                                                    textDecoration: 'none',
                                                    display: 'flex',
                                                    mt: 1,
                                                }}
                                            >
                                                <IconMi sx={{ mr: 1 }} fontSize={'16'} icon={'user'} />
                                                {topicInfo.technical_owner_dn}
                                            </Link>
                                        </Stack>
                                    )}
                                {topicInfo.data_steward_dn &&
                                    isOwnerVisible(configuration.owners.data_steward, 'glossary') && (
                                        <Stack
                                            sx={{
                                                width: '100%',
                                                paddingTop: '12px',
                                                borderTop: (theme) =>
                                                    `1px solid ${alpha(theme.palette.text.primary, 0.08)}}`,
                                            }}
                                        >
                                            {`${configuration.owners.data_steward.label}: `}
                                            <Link
                                                href={`mailto: ${topicInfo.data_steward_email}?subject=${topicInfo.name}`}
                                                sx={{
                                                    cursor: 'pointer',
                                                    textDecoration: 'none',
                                                    display: 'flex',
                                                    mt: 1,
                                                }}
                                            >
                                                <IconMi sx={{ mr: 1 }} fontSize={'16'} icon={'user'} />
                                                {topicInfo.data_steward_dn}
                                            </Link>
                                        </Stack>
                                    )}
                            </Stack>
                        </Stack>
                    )}
                </Grid>
            </Grid>
        </>
    );
}
