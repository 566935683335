import React, { useState } from 'react';
import { FormLayoutComposer, IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';
import { ActSubmit } from 'components/common/form/act';
import useBundleTranslation from 'i18n';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { instance } from 'api/api';
import { APIResponse } from 'tools/types';

export default function PluginSourceForm(props: IFormLayoutProps) {
    const { t } = useBundleTranslation('app/editor/plugin-source/collection');
    const [isLoading, setIsLoading] = useState(false);
    props.hookForm.form.formAct.overrideAct(
        'submit',
        class extends ActSubmit {
            async exec(data: any): Promise<any> {
                const paramsList = [];
                for (const [field] of Object.entries(props.hookForm.form.hookFormState.dirtyFields)) {
                    const match = field.match(/^parameter_([0-9]+)/);
                    if (match) {
                        paramsList.push(match[1]);
                    }
                }
                // Key params has been changed, provide server side validation
                if (paramsList.length) {
                    setIsLoading(true);
                    data['modified_params'] = paramsList;
                    const profileId = props.hookForm.form.hookFormGetValues('plugin_connection_profile_id');
                    try {
                        const response = await instance.get<APIResponse<{ reports: number }>>(
                            `data/editor/plugin-source/${profileId}/check-params-in-templates`,
                            {
                                params: { modified_params: paramsList },
                            }
                        );
                        setIsLoading(false);
                        if (response.data.data.reports > 0) {
                            if (confirm(t('automatically_url', { reports: response.data.data.reports }))) {
                                return super.exec(data);
                            } else {
                                return Promise.resolve(false);
                            }
                        }
                        return super.exec(data);
                    } catch (e) {
                        setIsLoading(false);
                        return Promise.resolve(false);
                    }
                } else {
                    return super.exec(data);
                }
            }
        }
    );

    return (
        <>
            {isLoading && <LoadingPlaceholder />}
            <FormLayoutComposer props={{ ...props }} />
        </>
    );
}
