import { FormControlRichTextProps, FormElementControlPropsType } from './index';
import { FormComponentBuilder } from '../index';
import ReactQuill from 'react-quill';
import styles from './FormRichText.styles';
import { Box, ClickAwayListener } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
import IconMi from 'components/common/icon/IconMi';
import RichTextLinkTooltip from './FormRichTextParts/RichTextLinkTooltip';

let icons: any = ReactQuill.Quill.import('ui/icons');
icons['bold'] = renderToString(<IconMi icon={'bold'} />);
icons['italic'] = renderToString(<IconMi icon={'italic'} />);
icons['underline'] = renderToString(<IconMi icon={'underline'} />);
icons['list']['bullet'] = renderToString(<IconMi icon={'sort-list'} />);
icons['list']['ordered'] = renderToString(<IconMi icon={'sort-numbers'} />);
icons['link'] = renderToString(<IconMi icon={'link'} />);
icons['image'] = renderToString(<IconMi icon={'image'} />);
icons['color'] = renderToString(<IconMi icon={'text-color'} />);

export default function FormRichText({
    controlProps,
    elementProps,
}: FormElementControlPropsType<FormControlRichTextProps>) {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const richTextContainer = useRef<any>(null);
    const quillRef = useRef<any>(null);

    const [showLinkTooltip, setShowLinkTooltip] = useState<false | number>(false);

    const selectionChangeHandler = (
        range: null | { index: number; length: number },
        oldRange: null | { index: number; length: number },
        source: string,
    ) => {
        const quill = quillRef.current.getEditor();

        if (range) {
            setShowLinkTooltip(false);
            if (range.length == 0) {
                //set cursor without selection (like focus)
                const currentFormat = quill.getFormat();
                if (currentFormat.link) {
                    setShowLinkTooltip(Date.now());
                }
                //console.log('User cursor is on', range.index);
            } else {
                //selection range
                //console.log('User has highlighted', quill.getText(range.index, range.length));
            }
        } else {
            //like blur, click out off editor
            //console.log('Cursor not in the editor');
        }
    };

    useEffect(() => {
        if (richTextContainer.current) {
            const selectIcons = richTextContainer.current.querySelectorAll('.ql-header .ql-picker-label svg');
            selectIcons.forEach((icon: any) => {
                icon.outerHTML = renderToString(<IconMi icon={'chevron-down'} />);
            });
        }
    }, []);

    useEffect(() => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();
            quill.on('selection-change', selectionChangeHandler);
        }
        return () => {
            if (quillRef.current) {
                const quill = quillRef.current.getEditor();
                quill.off('selection-change', selectionChangeHandler);
            }
        };
    }, [quillRef]);

    return (
        <>
            <ClickAwayListener
                onClickAway={(event: Event | React.SyntheticEvent) => {
                    setShowLinkTooltip(false);
                }}
            >
                <Box
                    sx={styles.root}
                    onFocus={() => {
                        setIsFocused(true);
                    }}
                    onBlur={() => {
                        setIsFocused(false);
                    }}
                    className={isFocused || showLinkTooltip ? 'focused' : undefined}
                    ref={richTextContainer}
                >
                    <ReactQuill
                        ref={quillRef}
                        theme="bubble"
                        value={controlProps.value}
                        onChange={controlProps.onChange}
                        readOnly={controlProps.readOnly}
                        modules={{
                            toolbar: [
                                [{ header: [2, 3, false] }],
                                ['bold', 'italic', 'underline'],
                                [
                                    {
                                        color: styles.colors,
                                    },
                                    { list: 'ordered' },
                                    { list: 'bullet' },
                                ],
                                ['link', 'image'],
                            ],
                        }}
                    />
                    {quillRef.current && (
                        <RichTextLinkTooltip
                            showTooltip={showLinkTooltip}
                            setShowTooltip={setShowLinkTooltip}
                            quill={quillRef.current}
                            richTextContainerRef={richTextContainer}
                        />
                    )}
                </Box>
            </ClickAwayListener>
        </>
    );
}

export class FormRichTextBuilder extends FormComponentBuilder {
    prepareProps(): FormControlRichTextProps {
        return {
            ...this.controlProps,
        };
    }
}
