import React, { useEffect, useRef, useState } from 'react';
import { Navigate, Outlet, useLocation, useMatches } from 'react-router-dom';
import { UserAuth } from 'store/auth';
import { useAppSelector } from 'store/hooks';
import useBundleTranslation from 'i18n';
import { useDispatch, useSelector } from 'react-redux';
import { setPageMenu } from 'store/pageMenuSlice';
import { removeBreadcrumbs, setBreadcrumbs, setBreadcrumbsInstanceKey } from 'store/breadcrumbsSlice';
import { useConfigConstantValue, useIsConfigConstantEqual } from 'hooks/useConfigConstant';
import { setHelpLink } from 'store/helpLinkSlice';
import { removePageTitle, setPageTitle, setPageTitleInstanceKey } from 'store/pageTitleSlice';
import { licenseKeyAPI } from 'api/liceseKey';
import { LicensePopup } from 'components/common/license-popup/LicensePopup';

export default function ProtectedRoute() {
    const { t } = useBundleTranslation('components/common/navbar/page_title_and_breadcrumbs');
    const dispatch = useDispatch();
    const userAuth: UserAuth = useAppSelector((state) => state.auth);
    const [initFrom, setInitFrom] = useState<string>('');
    const [portalPagePath, setPortalPagePath] = useState<string>('');
    const [needToCheckLicense, setNeedToCheckLicense] = useState<boolean>(true);
    const [licensePopupShow, setLicensePopupShow] = useState<boolean>(false);
    const [licensePopupResponse, setLicensePopupResponse] = useState<any>({});

    const matches = useMatches();
    const routeDefinition: any = Array.isArray(matches) && matches.length ? matches[matches.length - 1] : undefined;
    const routeData: any = routeDefinition?.handle;
    const location = useLocation();

    const pageTitle = useAppSelector((state) => state.pageTitle.title);
    const portalName = useSelector((state: any) => state.auth?.userInfo?.portalName);

    const isEnableAppleAppBanner = useIsConfigConstantEqual('ENABLE_APPLE_APP_BANNER', true);
    const mobileAppAppleId = useConfigConstantValue('MOBILE_APP_APPLE_ID');

    const checkIsPortalPage = () => {
        return location.pathname.indexOf('/p/') === 0 || location.pathname.indexOf('/pl/') === 0;
    };
    const isPP = useRef<boolean>(checkIsPortalPage());

    //Set up Apple banner
    useEffect(() => {
        if (isEnableAppleAppBanner && mobileAppAppleId > '') {
            let meta = document.createElement('meta');
            meta.name = 'apple-itunes-app';
            meta.content = `app-id=${mobileAppAppleId}`;
            document.getElementsByTagName('head')[0].appendChild(meta);
        }

        //Don't set favicons on PP (it's set in PP layout)
        if (isPP.current) return;

        const favicons: any = userAuth?.userInfo?.faviconsList ?? [];
        const defaultIcons = ['ico', '16x16', '32x32', '48x48'];
        defaultIcons.forEach((type: string) => {
            if (favicons.hasOwnProperty(type)) {
                const icon = favicons[type];
                let link = document.createElement('link');
                link.rel = type == 'ico' ? 'shortcut icon' : 'icon';
                link.setAttribute('sizes', icon.sizes);
                link.type = icon.type;
                link.href = icon.src;
                document.getElementsByTagName('head')[0].appendChild(link);
            }
        });
    }, []);

    //Set up page menu
    useEffect(() => {
        dispatch(setPageMenu(routeData?.pageMenu));
    }, [routeData?.pageMenu]);

    //Set up help link for User Menu
    useEffect(() => {
        dispatch(setHelpLink(routeData?.helpLink));
    }, [routeData?.helpLink]);

    //Set up common breadcrumbs
    useEffect(() => {
        if (routeData?.breadcrumbs !== false) {
            const breadcrumbs = routeData?.breadcrumbs?.list ?? [{ label: routeData?.name }];

            dispatch(setBreadcrumbs(breadcrumbs));
            dispatch(setBreadcrumbsInstanceKey(routeData?.breadcrumbs?.instanceDataKey));
        } else dispatch(removeBreadcrumbs());
    }, [routeData?.name]);

    //set common page title
    useEffect(() => {
        //Don't set page title on PP (it's set in PP layout)
        if (isPP.current) return;

        if (routeData?.pageTitle !== false) {
            let pageTitle = routeData?.pageTitle?.value ?? routeData?.name;

            //false value will use portalName if exist else empty ''
            if (routeData?.pageTitle?.value === false) {
                pageTitle = '';
            }
            dispatch(setPageTitle(t(pageTitle)));
            dispatch(setPageTitleInstanceKey(routeData?.pageTitle?.instanceDataKey));
        } else dispatch(removePageTitle());
    }, [routeData?.name]);

    //change pageTitle in store
    useEffect(() => {
        //Don't set page title on PP (it's set in PP layout)
        if (isPP.current) return;

        let pageTitleValue = '';
        if (pageTitle !== false) {
            const portalNameBrand = portalName > '' ? portalName : '';
            pageTitleValue = pageTitle > '' ? pageTitle : portalNameBrand;
        }
        document.title = pageTitleValue;
    }, [pageTitle]);

    useEffect(() => {
        isPP.current = checkIsPortalPage();

        //manage movement between main app and pp app
        const nextPageIsPP = isPP.current;

        if (
            //Click to another main app page from PP
            !nextPageIsPP &&
            initFrom === 'portalPage'
        ) {
            window.location.href = location.pathname;
        }

        if (
            //Click to another PP after init (ex
            initFrom &&
            nextPageIsPP
        ) {
            if (location.pathname + location.search !== portalPagePath)
                window.location.href = location.pathname + location.search + location.hash;
        }

        if (!initFrom) {
            //setup on load init environment app
            setInitFrom(nextPageIsPP ? 'portalPage' : 'mainApp');
            setPortalPagePath(nextPageIsPP ? location.pathname + location.search : '');
        }
    }, [location]);

    useEffect(() => {
        if (
            (process.env.REACT_APP_ENVIRONMENT && process.env.REACT_APP_ENVIRONMENT === 'development') ||
            !userAuth.isLoggedIn
        )
            return;
        if (needToCheckLicense) {
            setLicensePopupShow(false);
            setLicensePopupResponse({});
            licenseKeyAPI
                .checkLicense()
                .then((response) => {
                    if (response?.status === 200) {
                        setLicensePopupResponse(response.data);
                        const isLicenseBad =
                            !response.data?.success || response.data?.expired == 1 || response.data?.trial;
                        setLicensePopupShow(isLicenseBad);
                        if (!isLicenseBad) {
                            setNeedToCheckLicense(false);
                            setTimeout(function () {
                                setNeedToCheckLicense(true);
                            }, 10800000); //3h
                        }
                    }
                })
                .catch(() => {});
        }
    }, [routeData?.name]);

    if (!userAuth.isLoggedIn && !routeData?.isPublic) {
        //Login Redirect
        const checkLoginRedirect =
            !(window.location.href && -1 !== window.location.href.indexOf('/auth/')) &&
            (userAuth?.configuration?.constants?.SAML_ENABLED ||
                userAuth?.configuration?.constants?.GOOGLE_OAUTH2_LOGIN);

        if (checkLoginRedirect) {
            if (userAuth.configuration.constants.SAML_ENABLED) {
                window.location.href = '/auth/saml/login';
                return null;
            } else if (userAuth.configuration.constants.GOOGLE_OAUTH2_LOGIN) {
                window.location.href = '/auth/google/login';
                return null;
            }
        }

        return <Navigate to="/login" />;
    }

    if (
        !routeData?.isPublic &&
        userAuth?.userInfo?.isChangePasswordRequired &&
        routeDefinition?.pathname != '/auth/change'
    ) {
        return <Navigate to="/auth/change" />;
    }

    return (
        <>
            <Outlet />
            {licensePopupShow && (!routeData?.isPublic || isPP.current) && (
                <LicensePopup
                    response={licensePopupResponse}
                    onHide={() => {
                        setLicensePopupShow(false);
                    }}
                />
            )}
        </>
    );
}
