import { Column, HeaderCell, Table } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';
import { RowDataType, SortType } from 'rsuite-table/lib/@types/common';
import useBundleTranslation from 'i18n';
import {
    ColumnsWidth,
    ColumnType,
    DNDConfig,
    FilterValue,
    GridData,
    GridDataRow,
    LegendConfig,
    MassActionsConfig,
    MassActionsProps,
    PopupMode,
    PopupType,
    RowExtraInfoConfig,
    VisibleColumns,
} from './index';
import { useLegend } from './hooks/useLegend';
import React, { RefObject, useMemo, useRef, useState } from 'react';
import { Grid, Box, Checkbox, alpha } from '@mui/material';
import DraggableRow from './DraggableRow';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import buildColumn from './cell';
import MassActionCheckCell from './cell/MassActionCheckCell';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import styles from './MITable.styles';
import Legend from './Legend';
import SortCell from './cell/SortCell';
import { TFunction } from 'i18next';
import { useGridExtraInfo } from './hooks/useGridExtraInfo';
import SelectedItemsLabel from './SelectedItemsLabel';
import LoadingPlaceholder from '../loading-placeholder/LoadingPlaceholder';
import parse from 'html-react-parser';
import { FormRendererAPIType } from 'components/common/form';

export default function MITable({
    data,
    columns,
    isLoading,
    orderBy,
    order,
    onSortColumn,
    autoHeight,
    height,
    legendConfig,
    dragAndDrop,
    handleDropRow,
    handleClickRow,
    massActionsConfig,
    reloadGridData,
    onColumnResize,
    massActionsProps,
    setPopupUrl,
    setPopupConfig,
    setPopupType,
    setPopupMode,
    t,
    multilines,
    autoRowHeight,
    columnsWidth,
    customCells,
    setShowLoader,
    gridName,
    visibleColumns,
    setPopupComponent,
    setPopupComponentProps,
    rowExtraInfoConfig,
    handleScroll,
    shouldUpdateScroll,
    emptyGridMessage,
    gridReorderLoading,
    form,
    setGridDataChanged,
    uid,
    filtersValue,
    customLegend,
}: {
    data: GridData;
    columns: Array<ColumnType>;
    isLoading?: boolean;
    orderBy?: string;
    order?: SortType;
    autoHeight?: boolean;
    height?: number;
    onSortColumn?: (dataKey: string, sortType?: SortType) => void;
    legendConfig?: Array<LegendConfig>;
    dragAndDrop?: DNDConfig;
    handleDropRow?: (sourceId: string, targetId: string) => void;
    handleClickRow?: (rowData: GridDataRow) => void;
    massActionsConfig: MassActionsConfig;
    reloadGridData?: () => void;
    onColumnResize?: (columnWidth?: number, dataKey?: string) => void;
    setPopupUrl?: (url: string) => void;
    setPopupConfig?: (popupConfig: PopupSettings) => void;
    setPopupComponent?: (popupComponent: string) => void;
    setPopupComponentProps?: (componentProps: { [key: string]: string }) => void;
    massActionsProps?: MassActionsProps;
    setPopupType?: (type: PopupType) => void;
    setPopupMode?: (mode: PopupMode) => void;
    t?: TFunction;
    multilines?: boolean;
    autoRowHeight?: boolean;
    columnsWidth?: ColumnsWidth;
    customCells?: { [key: string]: any };
    setShowLoader?: (value: boolean) => void;
    gridName: string;
    uid?: string;
    visibleColumns?: VisibleColumns;
    rowExtraInfoConfig?: RowExtraInfoConfig;
    handleScroll?: (scrollX: number, scrollY: number) => void;
    shouldUpdateScroll?: boolean;
    emptyGridMessage?: string;
    gridReorderLoading?: boolean;
    form?: FormRendererAPIType;
    setGridDataChanged?: (state: boolean) => void;
    filtersValue?: FilterValue;
    customLegend?: boolean;
}) {
    const { t: t2 } = useBundleTranslation();
    if (undefined === t) t = t2;

    const isDraggable = dragAndDrop?.enabled;
    const draggableConfig: any = {};
    const defaultRowHeight = 32;
    const tableHeight = height ?? 200;
    const idColumn = dragAndDrop?.idColumn ?? 'id';
    const dataKey = massActionsConfig?.idColumn ?? 'id';

    const [errorMessage, setErrorMessage] = useState('');
    const [isDragging, setIsDragging] = useState(false);

    const headerRefs = useRef<{ refObj: RefObject<HTMLDivElement>; name: string }[]>(null);

    const { extraInfoConfig } = useGridExtraInfo(data, rowExtraInfoConfig);

    const gridDataTest = useMemo(() => uid ?? gridName, [gridName, uid]);

    if (isDraggable && handleDropRow) {
        draggableConfig.renderRow = (children?: React.ReactNode, rowData?: RowDataType) => {
            if (!rowData) {
                return children;
            }

            const rowIndex = data.findIndex((row) => row[idColumn] === rowData[idColumn]);

            return (
                <DraggableRow
                    key={rowData[idColumn]}
                    rowData={rowData}
                    idColumn={idColumn}
                    onDrop={handleDropRow}
                    rowIndex={rowIndex}
                    setIsDragging={setIsDragging}
                >
                    {children}
                </DraggableRow>
            );
        };
    }

    const errorPopupConfig: PopupSettings = {
        title: t('error'),
        noCancel: true,
        textOK: t('ok_button'),
    };

    const renderEmpty = emptyGridMessage
        ? () => {
              return <div className="rs-table-body-info">{emptyGridMessage}</div>;
          }
        : undefined;

    const showCheckboxColumn = massActionsConfig.enabled && massActionsProps !== undefined;
    const checkedRowsKeys =
        showCheckboxColumn && massActionsProps.checkedRowsKeys ? massActionsProps.checkedRowsKeys : [];
    const { legendItems, getRowClassName } = useLegend(data, { checkedRowsKeys, dataKey }, legendConfig, customLegend);

    const getRowHeight = (rowData: RowDataType) => {
        let height = defaultRowHeight;

        if (rowData && !autoRowHeight && multilines) {
            for (const prop in rowData) {
                if (
                    rowData.hasOwnProperty(prop) &&
                    rowData[prop].hasOwnProperty('config') &&
                    rowData[prop]['config'].hasOwnProperty('multilines')
                ) {
                    const calcHeight = (defaultRowHeight * rowData[prop]['config']['multilines']) / 1.5;

                    if (height < calcHeight) {
                        height = calcHeight;
                    }
                }
            }
        }

        if (rowData && rowExtraInfoConfig && extraInfoConfig && extraInfoConfig.expandedRowKeys) {
            if (extraInfoConfig.expandedRowKeys.includes(rowData[rowExtraInfoConfig.idColumn])) {
                height += extraInfoConfig.rowExpandedHeight;
            }
        }

        return height;
    };

    return (
        <Box sx={styles.wrapper}>
            <DndProvider backend={HTML5Backend}>
                <SelectedItemsLabel show={false} />
                <Table
                    className={`custom-grid--rs-table ${isDraggable ? 'rs-table--draggable' : ''}`}
                    data-test={`grid_${gridDataTest}_body`}
                    virtualized
                    sortColumn={orderBy}
                    rowClassName={getRowClassName}
                    sortType={order}
                    data={data}
                    rowHeight={multilines && autoRowHeight ? undefined : getRowHeight}
                    wordWrap={multilines && autoRowHeight ? 'break-word' : undefined}
                    onSortColumn={onSortColumn}
                    height={tableHeight}
                    autoHeight={autoHeight}
                    loading={isLoading}
                    headerHeight={32}
                    {...draggableConfig}
                    onRowClick={handleClickRow ? handleClickRow : undefined}
                    {...extraInfoConfig}
                    onScroll={handleScroll}
                    shouldUpdateScroll={shouldUpdateScroll}
                    renderEmpty={renderEmpty}
                >
                    {isDraggable && (
                        <Column width={50} align="center" resizable={false} fixed="left">
                            <HeaderCell align={'left'}>
                                {dragAndDrop?.hideTitle ? '' : t('column_sort_header')}
                            </HeaderCell>
                            <SortCell tooltip={dragAndDrop?.tooltip} isDragging={isDragging} />
                        </Column>
                    )}
                    {showCheckboxColumn ? (
                        <Column width={24} align="right" resizable={false} fixed="left">
                            <HeaderCell
                                className="rs-table-cell--checkbox"
                                data-test={`grid_${gridDataTest}_checkbox_column_header`}
                            >
                                <Grid
                                    container
                                    sx={{ height: 1, alignItems: 'center', justifyContent: 'right' }}
                                    className={'checkbox-holder'}
                                >
                                    <Checkbox
                                        data-test={`grid_${gridDataTest}_checkbox_column_check_all`}
                                        sx={{ padding: 0 }}
                                        onChange={massActionsProps.handleCheckAllRows}
                                        checked={massActionsProps.checkAllChecked()}
                                    />
                                </Grid>
                            </HeaderCell>
                            <MassActionCheckCell
                                dataKey={dataKey}
                                onChange={massActionsProps.handleCheckRow}
                                checkedRowsKeys={checkedRowsKeys}
                                uid={gridDataTest}
                            />
                        </Column>
                    ) : null}
                    {columns.map((column: ColumnType, index) => {
                        let existRef = headerRefs.current?.find((ref) => ref.name === column.name);

                        if (!existRef) {
                            existRef = {
                                name: column.name,
                                refObj: React.createRef<HTMLDivElement>(),
                            };
                            headerRefs.current?.push(existRef);
                        }

                        if (
                            column.hidden ||
                            (visibleColumns && visibleColumns[column.name] && !visibleColumns[column.name].visible)
                        ) {
                            return;
                        }

                        const width = columnsWidth ? columnsWidth[column.name] : undefined;

                        return buildColumn(
                            column,
                            index,
                            t!,
                            gridDataTest,
                            dataKey,
                            width,
                            reloadGridData,
                            onColumnResize,
                            setPopupUrl,
                            setPopupConfig,
                            setPopupType,
                            setPopupMode,
                            customCells,
                            setShowLoader,
                            order,
                            orderBy,
                            setPopupComponent,
                            setPopupComponentProps,
                            setErrorMessage,
                            form,
                            setGridDataChanged,
                            filtersValue,
                            existRef.refObj,
                        );
                    })}
                </Table>
            </DndProvider>
            <Legend legendItems={legendItems} gridName={gridName} />
            {gridReorderLoading && (
                <LoadingPlaceholder
                    sx={{
                        position: 'absolute',
                        backgroundColor: (theme) => alpha(theme.palette.background.default, 0.5),
                        color: (theme) => alpha(theme.palette.text.primary, 0.4),
                    }}
                />
            )}
            {errorMessage > '' && (
                <Popup
                    settings={errorPopupConfig}
                    open={errorMessage > ''}
                    onHide={() => {
                        setErrorMessage('');
                    }}
                    maxWidth={'popupMd'}
                >
                    <Box>{parse(errorMessage)}</Box>
                </Popup>
            )}
        </Box>
    );
}
