import { alpha, Box, Chip, Stack, Tooltip } from '@mui/material';
import IconHandler from 'components/common/icon/IconHandler';
import React, { useState } from 'react';
import { SearchTopics } from 'api/search';
import IconMi from 'components/common/icon/IconMi';
import styles from './TopicsBlock.styles';

export default function TopicsBlock({
    topics,
    handleTagClick,
    elementId,
}: {
    topics: SearchTopics;
    elementId: number;
    handleTagClick?: (
        isTagsOptions: boolean,
        topic: { topic_id: number | null; name: string },
        elementId: number,
    ) => void;
}) {
    const [collapseTermSectionList, setCollapseTermSectionList] = useState<any>({});

    if (!(topics && Object.keys(topics).length > 0)) return null;

    const tagsSectionKeys = Object.keys(topics).filter((key) => key == 'default-topic-type');
    const termsSectionKeys = Object.keys(topics).filter((key) => key != 'default-topic-type');

    const toggleCollapseTermSection = (sectionKey: string) => {
        const newCollapseSectionsList = { ...collapseTermSectionList };
        newCollapseSectionsList[sectionKey] = !newCollapseSectionsList?.[sectionKey];
        setCollapseTermSectionList(newCollapseSectionsList);
    };

    return (
        <Box sx={{ mt: 1.5 }}>
            {tagsSectionKeys.map((sectionKey, i) => {
                const section = topics[sectionKey];
                return (
                    <Stack key={section.topic_type_name} direction={'row'} sx={styles.tagSection}>
                        <Box flexShrink={0}>
                            <IconHandler sx={styles.tagIcon} icon={{ type: 'mi', value: 'tags' }} />
                        </Box>
                        <Box sx={styles.tagsItemsHolder}>
                            {section.topic_type_name && <Box sx={styles.tagLabel}>{section.topic_type_name}</Box>}
                            {section.topics.map((topic: any) => {
                                if (topic.topic_id) {
                                    return (
                                        <Chip
                                            onClick={() => {
                                                if (handleTagClick) {
                                                    handleTagClick(true, topic, elementId);
                                                }
                                            }}
                                            label={topic.name}
                                            color="primary"
                                            sx={styles.tagItem}
                                        />
                                    );
                                } else {
                                    return (
                                        <Chip
                                            label={topic.name}
                                            sx={{
                                                ...styles.tagItem,
                                                color: (theme) => alpha(theme.palette.text.primary, 0.64),
                                                pointerEvents: 'none',
                                            }}
                                        />
                                    );
                                }
                            })}
                        </Box>
                    </Stack>
                );
            })}

            {termsSectionKeys.length > 0 && (
                <>
                    {termsSectionKeys.map((sectionKey, i) => {
                        const section = topics[sectionKey];
                        return (
                            <Stack
                                key={i}
                                sx={styles.termSection}
                                width={1}
                                className={collapseTermSectionList?.[sectionKey] ? 'collapse' : 'expand'}
                            >
                                <Stack
                                    direction={'row'}
                                    onClick={() => {
                                        toggleCollapseTermSection(sectionKey);
                                    }}
                                    className={'term-section__header'}
                                >
                                    <Box sx={styles.iconHolder}>
                                        {section.use_icon ? (
                                            <IconHandler
                                                sx={{ fontSize: '14px' }}
                                                icon={{
                                                    type: section.use_icon == 'uploaded' ? 'upload' : section.use_icon,
                                                    value:
                                                        section.use_icon == 'uploaded'
                                                            ? `data:${section.icon};base64, ${section.icon}`
                                                            : section.fa_icon,
                                                }}
                                            />
                                        ) : (
                                            <IconHandler
                                                sx={{ fontSize: '14px' }}
                                                icon={{ type: 'mi', value: 'tags' }}
                                            />
                                        )}
                                    </Box>

                                    <Box flexGrow={1}>{section.topic_type_name}</Box>
                                    <Box sx={{ ...styles.iconHolder, ...{ mr: 0, ml: 1 } }}>
                                        <IconMi
                                            icon={
                                                collapseTermSectionList?.[sectionKey] ? 'chevron-right' : 'chevron-down'
                                            }
                                            sx={{ fontSize: 16 }}
                                        />
                                    </Box>
                                </Stack>
                                <Stack direction="row" flexWrap={'wrap'} className={'term-section__items'}>
                                    {section.topics.map((topic: any) => {
                                        const needToCutLabel = topic.name.length > 30;
                                        const label = needToCutLabel ? topic.name.substring(0, 30) + '...' : topic.name;

                                        return (
                                            <Box sx={{ mt: 0.5, ml: 0.25 }} key={topic.topic_id}>
                                                <Tooltip title={needToCutLabel ? topic.name : ''}>
                                                    <Chip
                                                        onClick={() => {
                                                            if (handleTagClick) {
                                                                handleTagClick(false, topic, elementId);
                                                            }
                                                        }}
                                                        label={label}
                                                        color="primary"
                                                        sx={{ lineHeight: '20px', fontSize: '12px' }}
                                                    />
                                                </Tooltip>
                                            </Box>
                                        );
                                    })}
                                </Stack>
                            </Stack>
                        );
                    })}
                </>
            )}
        </Box>
    );
}
