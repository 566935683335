import { alpha, Box, Stack, Theme } from '@mui/material';
import IconHandler from 'components/common/icon/IconHandler';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { TopicsInfo } from 'api/editor/topics';
import TopicItem from './TopicItem';
import useBundleTranslation from 'i18n';
import IconMi from '../common/icon/IconMi';

export default function GlossaryPopupBody({
    topicsInfo,
    selectedTopic,
}: {
    topicsInfo: TopicsInfo;
    selectedTopic: number;
}) {
    const glossaryTerms = useMemo(() => Object.keys(topicsInfo), [topicsInfo]).sort((a, b) => {
        const termA = topicsInfo[a].topic_type_name;
        const termB = topicsInfo[b].topic_type_name;

        return termA.toLowerCase() > termB.toLowerCase() ? 1 : -1;
    });
    const itemsRef = useRef<{ [key: string]: any }>({});

    useEffect(() => {
        setTimeout(() => {
            itemsRef.current[selectedTopic]?.scrollIntoView({ behavior: 'smooth' });
        }, 200);
    }, [selectedTopic]);

    return (
        <Stack
            direction={'column'}
            key={'glossary-terms-body'}
            sx={{ height: '672px', flexGrow: 1, overflow: 'auto', marginTop: '0 !important' }}
        >
            {glossaryTerms.map((term) => {
                const topicInfo = topicsInfo[term];

                const icon =
                    topicInfo.use_icon === 'fa' ? (
                        <Stack
                            flexShrink={0}
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            width={16}
                            height={16}
                        >
                            <IconHandler
                                sx={{ fontSize: 14, mt: '2px' }}
                                icon={{ type: 'fa', value: topicInfo.fa_icon }}
                            />
                        </Stack>
                    ) : topicInfo.use_icon === 'uploaded' && topicInfo.icon ? (
                        <Stack
                            flexShrink={0}
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            width={16}
                            height={16}
                        >
                            <Box
                                component={'img'}
                                src={`data:${topicInfo.icon_type};base64, ${topicInfo.icon}`}
                                alt={`tag-icon-${term}`}
                                width={14}
                                height={14}
                                sx={{ mt: '2px' }}
                            />
                        </Stack>
                    ) : null;

                return topicInfo.topics.map((topic) => (
                    <Box
                        ref={(el) => (itemsRef.current[topic.topic_id] = el)}
                        key={`glossary-item-${topic.topic_id}`}
                        sx={{
                            position: 'relative',
                            p: 2,
                            borderTop: (theme: Theme) =>
                                topic.topic_id == selectedTopic
                                    ? `2px solid ${theme.palette.primary.main}`
                                    : `1px solid ${alpha(theme.palette.text.primary, 0.08)}`,
                        }}
                    >
                        <Box sx={{ overflow: 'hidden' }}>
                            <TopicItem topicInfo={topic} sectionIcon={icon} />
                        </Box>
                    </Box>
                ));
            })}
        </Stack>
    );
}
