import { FormComponentValue, FormControlProps, FormElementControlPropsType } from './control';
import { Controller as ReactFormController, useFormState } from 'react-hook-form';
import buildFormControlProperties from 'components/common/form/buildFormControlProperties';
import React, { Suspense, useEffect, useRef } from 'react';
import { FormDataAPIType, FormElementProps, RawFormElementProps } from 'components/common/form';
import { ComponentKey } from 'components/common/ComponentIndex';
import { AssocArray } from 'tools/types';
import { prepareFormElementProps } from 'components/common/form/formTools';
import { Box } from '@mui/material';
import { FormComponentDependency } from 'components/common/form/dependency';
import useBundleTranslation from 'i18n';
import { FormElementWrapper } from 'components/common/form/element/FormElementWrapper';
import { FormElementErrorMessage } from 'components/common/form/element/FormElement';

interface TemplateFormComponentProps {
    component: ComponentKey;
    componentValues?: Array<FormComponentValue>;
    elementProps: RawFormElementProps | FormDataAPIType;
    name: string;
    props?: AssocArray<any>;
    deps?: Array<FormComponentDependency>;
    wrapper?: boolean;
    label?: string;
}

export function TemplateFormComponent({
    component,
    componentValues,
    elementProps,
    name,
    props = {},
    deps = [],
    wrapper = false,
    label,
}: TemplateFormComponentProps) {
    const { t } = useBundleTranslation();
    const fullElementProps = prepareFormElementProps({
        ...elementProps,
        component: {
            name: name,
            label: label ?? name,
            component: component,
            props: props,
            deps: deps,
        },
    });

    const componentValuesRef = useRef<any>(componentValues);
    const componentPropsRef = useRef<any>(fullElementProps);
    const componentStatePropsRef = useRef<any>(null);

    const { errors } = useFormState({ control: elementProps?.form?.hookFormControl });

    useEffect(() => {
        if (elementProps.form) {
            elementProps.form.addElementValuesRegister(
                fullElementProps,
                componentValuesRef,
                componentPropsRef,
                componentStatePropsRef,
            );
        }
    }, []);

    const controller = (
        <>
            <ReactHookFormController componentValues={componentValues} elementProps={fullElementProps} />
            <FormElementErrorMessage errors={errors} elementProps={fullElementProps} t={t} />
        </>
    );

    return wrapper ? (
        <FormElementWrapper elementProps={fullElementProps} componentValues={componentValues}>
            {controller}
        </FormElementWrapper>
    ) : (
        <Box>{controller}</Box>
    );
}

export default function ReactHookFormController({
    componentValues,
    elementProps,
    afterChange,
}: {
    componentValues?: Array<FormComponentValue>;
    elementProps: FormElementProps;
    afterChange?: (event: any) => void;
}) {
    return (
        <Suspense>
            <ReactFormController
                name={elementProps.component.name}
                defaultValue={elementProps.data[elementProps.component.name]}
                control={elementProps.form.hookFormControl}
                render={({ field }) => {
                    let component = <div>ERROR</div>;
                    const controlData = buildFormControlProperties(elementProps, field, componentValues, afterChange);
                    if (controlData.isValid) {
                        component = React.createElement(
                            controlData.componentName as React.FC<FormElementControlPropsType<FormControlProps>>,
                            {
                                controlProps: controlData.controlProps,
                                elementProps: elementProps,
                            },
                        );
                    }
                    return component;
                }}
            />
        </Suspense>
    );
}
