import React, { startTransition, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import useBundleTranslation from 'i18n';
import {
    AccessDeniedTileInfo,
    buildFolderMap,
    decompressTiles,
    getGroupByOptions,
    getSegmentOptions,
    HomePageData,
    HomePageState,
    TileInfo,
    TopicsPopupProps,
} from 'app/home/index.functions';
import { Tile4FavoriteData } from 'app/home/index';
import { homePageDefaultSettings } from 'app/home/index.functions';
import { HomeContext } from 'app/home/context';
import { cloneDeep } from 'lodash';
import { homePage } from 'api/home-page';
import { useQuery } from '@tanstack/react-query';
import md5 from 'crypto-js/md5';
import GlossaryTermPopup from 'components/glossary-term-popup';
let isDataLoaded = false;

export default function EmbedWrapper({
    dataParams,
    children,
}: {
    dataParams: { [key: string]: string };
    children: any;
}) {
    const { t } = useBundleTranslation(['app/home']);
    const navigationBack = undefined !== dataParams['navigation_back'] && 'Y' == dataParams['navigation_back'];
    const newTab = undefined !== dataParams['new_tab'] && 'Y' == dataParams['new_tab'];

    const {
        data: loadedData,
        isLoading,
        refetch,
    } = useQuery<any, Error>({
        queryKey: ['embedPageData_' + md5(JSON.stringify(dataParams))],
        queryFn: () => {
            return homePage.getData(dataParams).then((data) => {
                isDataLoaded = false;
                return data;
            });
        },
        refetchOnMount: true,
    });

    const [showTermsPopup, setShowTermsPopup] = useState(false);
    let trashBucketRef = React.useRef<HTMLDivElement>(null);
    const [tilePreview, setTilePreview] = useState<TileInfo | null>(null);
    const [accessDeniedPopupTile, setAccessDeniedPopupTile] = useState<AccessDeniedTileInfo | null>(null);
    const [visibleTiles, setVisibleTiles] = useState<TileInfo[]>([]);
    const [topicId, setTopicId] = useState<number>(0);
    const [elementId, setElementId] = useState<number>(0);
    const [tile4Favorite, setTile4Favorite] = useState<Tile4FavoriteData | null>(null);

    const [data, setData] = useState<HomePageData>({
        data: { vocabulary: [], rows: [] },
        settings: homePageDefaultSettings,
        hierarchyFolders: [],
        folderItems: [],
        tags: [],
        tileIcons: {},
        contentTypes: [],
        logoImageUrl: '',
        customFieldsDisplayOnTileInfo: {},
    });
    const [homePageState, setHomePageState] = useState<HomePageState>({
        hierarchyPanelOpen: true,
        filterState: { groupBy: 'None' },
    });

    const topicPopupProps: TopicsPopupProps = useMemo(() => {
        return {
            setShowTermsPopup,
            setTopicId,
            setElementId,
        };
    }, []);

    const tiles: TileInfo[] = useMemo(() => decompressTiles(data), [data]);

    const topicIds: number[] = useMemo(() => {
        const result: number[] = [];

        if (tiles && elementId > 0)
            tiles.forEach((tile) => {
                if (tile.topics && tile.element_id === elementId) result.push(...tile.topics);
            });

        return result;
    }, [tiles, elementId]);

    useEffect(() => {
        if (!showTermsPopup) {
            setElementId(0);
        }
    }, [showTermsPopup]);

    const [favorites, folders, categories, folderMap] = useMemo(
        () => buildFolderMap(cloneDeep(data.hierarchyFolders)),
        [data.hierarchyFolders],
    );

    const refetchData = useCallback(() => {
        refetch();
        isDataLoaded = false;
    }, [refetch]);

    useEffect(() => {
        if (!isLoading && !isDataLoaded) {
            isDataLoaded = true;
            startTransition(() => {
                setData({
                    data: loadedData.data,
                    settings: loadedData.settings,
                    hierarchyFolders: loadedData.hierarchyFolders,
                    folderItems: loadedData.folderItems,
                    tags: loadedData.topics,
                    tileIcons: loadedData.tileIcons,
                    contentTypes: loadedData.contentTypes,
                    logoImageUrl: loadedData.logoImageUrl,
                    customFieldsDisplayOnTileInfo: loadedData.customFieldsDisplayOnTileInfo,
                });
            });
        }
    }, [loadedData]);

    const handleContextMenu = (event: React.MouseEvent, tile: TileInfo | null) => {};

    const groupByOptions = getGroupByOptions(data, t);
    const segmentOptions = getSegmentOptions(data, tiles, t);
    const tagOptions = data?.tags.map((t) => ({ value: String(t.topic_id), label: t.full_name }));

    if (null == data || null == data.hierarchyFolders || 0 == data.hierarchyFolders.length) return <></>;

    return (
        <>
            <HomeContext.Provider
                value={{
                    tiles,
                    favorites,
                    folders,
                    categories,
                    folderMap,
                    homePageState,
                    setHomePageState,
                    data,
                    setData,
                    refetchData,
                    trashBucketRef,
                    tilePreview,
                    setTilePreview,
                    setAccessDeniedPopupTile,
                    setVisibleTiles,
                    handleContextMenu,
                    setTile4Favorite,
                    groupByOptions,
                    segmentOptions,
                    tagOptions,
                    isEmbedded: true,
                    navigationBack,
                    newTab,
                    topicPopupProps,
                }}
            >
                {children}
                <Suspense>
                    {showTermsPopup && (
                        <GlossaryTermPopup
                            setShowPopup={setShowTermsPopup}
                            showPopup={showTermsPopup}
                            topicIds={topicIds}
                            topicId={topicId}
                        />
                    )}
                </Suspense>
            </HomeContext.Provider>
        </>
    );
}
