import { alpha, Box, Stack, Typography } from '@mui/material';
import Infobar from './infobar';
import InstanceInfo from './instance-info';
import QueryBuilder from './query-builder';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DataPreview from 'app/editor/dataset/DataPreview';
import { datasetViewAPI } from 'api/viewer/dataset';
import { DatasetDataResponse, ElementsAmount, FilterData, InstanceMode, RulesConfig } from './index';
import { FormComponentValue } from 'components/common/form/layout/control';
import useBundleTranslation from 'i18n';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import { stringifyFilter } from './query-builder/helpers';
import { Params as UrlParams } from '@remix-run/router/dist/utils';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { setBreadcrumbs } from 'store/breadcrumbsSlice';
import { useDispatch } from 'react-redux';
import { SortType } from 'rsuite-table/lib/@types/common';
import { useIsAdmin, useIsPowerUser } from 'hooks/useUserPrivilege';
import { cloneDeep, isEqual } from 'lodash';
import parse from 'html-react-parser';
import showdown from 'showdown';
import VirtualDatasetViewer from '../virtual-dataset-viewer/VirtualDatasetViewer';
import MetricBuilder from './metric-builder';
import { setPageTitle } from 'store/pageTitleSlice';
import useOnLeavePrompt from 'hooks/useOnLeavePrompt';

const converter = new showdown.Converter();

export function getInitialFilterState(): RulesConfig {
    return {
        resultFields: [],
        derivedFields: [],
        fieldsOrder: [],
        resultFieldAggregations: {},
        sort: {},
        leftTab: 'fields',
        history: false,
        joinFields: [],
        trackNewRows: true,
        trackRemovedRows: true,
        trackChangedRows: true,
        group: {
            operator: 'AND',
            rules: [],
        },
        group_new: {
            operator: 'AND',
            rules: [],
        },
    };
}

function DatasetViewer() {
    const { t } = useBundleTranslation(['components/dataset-viewer/dataset_viewer']);

    const isAdmin = useIsAdmin();
    const isPowerUser = useIsPowerUser();

    const initFilterState = getInitialFilterState();

    const urlParams: UrlParams = useParams();
    const datasetId = Number(urlParams.datasetId ?? 0);
    const filterId = Number(urlParams.filterId ?? 0);
    const instanceId = Number(urlParams.instanceId ?? 0);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [datasetData, setDatasetData] = useState<DatasetDataResponse>();
    const [filterSelectData, setFilterSelectData] = useState<FormComponentValue[]>([]);
    const [selectedFilter, setSelectedFilter] = useState('');
    const [tmpSelectedFilter, setTmpSelectedFilter] = useState('');
    const [selectedFilterData, setSelectedFilterData] = useState<FilterData | null>(null);
    const [filterDirty, setFilterDirty] = useState(false);
    const [builderDirty, setBuilderDirty] = useState(false);
    const [currentInstance, setCurrentInstance] = useState('');
    const [priorInstance, setPriorInstance] = useState('');
    const [showInstancesSelector, setShowInstancesSelector] = useState(false);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [json, setJson] = useState<string>(stringifyFilter(initFilterState));
    const [json4Preview, setJson4Preview] = useState<string>(stringifyFilter(initFilterState));
    const [showLoader, setShowLoader] = useState(false);
    const [filter, setFilter] = useState<RulesConfig>(cloneDeep(initFilterState));
    const [dataPreviewUrl, setDataPreviewUrl] = useState<string>('');
    const [order, setOrder] = useState<SortType>('asc');
    const [orderBy, setOrderBy] = useState<string>('');
    const [errorPopupMessage, setErrorPopupMessage] = useState('');
    const [errorPopupTitle, setErrorPopupTitle] = useState('error');
    const [showMetricBuilder, setShowMetricBuilder] = useState(false);

    const [elementsAmount, setElementsAmount] = useState<ElementsAmount>({
        datasets: 0,
        can_be_deleted: 'N',
        metrics: 0,
        reports: 0,
        segments: 0,
    });

    const loadDatasetData = (newFilterId = filterId) => {
        datasetViewAPI.getDatasetData(datasetId, newFilterId, instanceId).then((response) => {
            if (response.status === 'OK') {
                if (instanceId === 0) {
                    const datasetInstances = response.data.datasetInstances;
                    let newUrl = `/dataset/${datasetId}`;
                    let redirect = false;

                    if (datasetInstances.length > 0) {
                        newUrl = newUrl + `/instance/${datasetInstances[0].dataset_instance_id}`;
                        redirect = true;
                    }

                    if (newFilterId > 0) {
                        newUrl = newUrl + `/filter/${newFilterId}`;
                        redirect = true;
                    }

                    const needRedirect = !window.location.href.includes(newUrl);

                    if (redirect && needRedirect) {
                        window.location.href = newUrl;
                    }
                }

                loadElementsAmount(datasetId, newFilterId);

                setDatasetData(response.data);
                setBuilderDirty(false);
                setFilterDirty(false);
            }
        });
    };

    const loadElementsAmount = useCallback(
        (dataset: number = datasetId, filter: number = filterId) => {
            datasetViewAPI.getElementsAmount(dataset, filter).then((response) => {
                if (response.status === 'OK') {
                    setElementsAmount(response.data);
                }
            });
        },
        [datasetId, filterId],
    );

    useOnLeavePrompt(t('form_leave_confirm'), filterDirty, 'page');

    useEffect(() => {
        loadDatasetData();
    }, [datasetId, filterId]);

    const onInstanceModeUpdate = (newInstanceMode: InstanceMode) => {
        setFilter((prevState) => {
            const newState = { ...prevState };
            newState.history = newInstanceMode === 'lastTwo';

            if (newInstanceMode === 'lastTwo') {
                newState.leftTab = 'trackChanges';
            } else {
                newState.leftTab = 'fields';
            }

            return newState;
        });
    };

    useEffect(() => {
        if (instanceId > 0 && datasetData) {
            setCurrentInstance(String(instanceId));

            const currentIndex = datasetData.datasetInstances.findIndex((item) => {
                return Number(item.dataset_instance_id) == instanceId;
            });
            if (currentIndex < datasetData.datasetInstances.length) {
                const priorInstanceData = datasetData.datasetInstances[currentIndex + 1];
                if (priorInstanceData) {
                    setPriorInstance(priorInstanceData.dataset_instance_id);
                } else {
                    setPriorInstance('');
                }
            }
        }
    }, [instanceId, datasetData]);

    useEffect(() => {
        if (datasetData) {
            const newFilterSelectData: FormComponentValue[] = [];
            const isInstancesMode: boolean = datasetData.datasetData.save_historical_instances_ind === 'Y';
            newFilterSelectData.push({
                label: t('all_data_select_label'),
                value: '0',
            });

            if (datasetData.filters && datasetData.filters.length > 0) {
                newFilterSelectData.push({
                    label: '---------------------------------',
                    value: 'separator_1',
                    disabled: true,
                });

                datasetData.filters.forEach((filter) => {
                    newFilterSelectData.push({
                        label: filter.name,
                        value: filter.uid,
                    });
                });
            }

            setFilterSelectData(newFilterSelectData);
            setSelectedFilter(datasetData.filterId);
            setShowInstancesSelector(isInstancesMode);

            const filterData = datasetData.filters.find((filter) => {
                return filter.dataset_filter_id === datasetData.filterId;
            });

            setSelectedFilterData(filterData ?? null);
            if (filterData && filterData.rules_serialized) {
                setFilter(() => {
                    const rawRuleData = JSON.parse(filterData.rules_serialized);
                    if (!rawRuleData.fieldsOrder) {
                        rawRuleData.fieldsOrder = [];
                        rawRuleData.resultFields = [];
                    }

                    return rawRuleData;
                });
                setJson(filterData.rules_serialized);
                setJson4Preview(filterData.rules_serialized);
            } else {
                setFilter((prevState) => {
                    const newState = { ...prevState };

                    newState.resultFields = datasetData.columnsData.map((column) => column.id);
                    newState.resultFieldAggregations = {};

                    return newState;
                });
                setJson(stringifyFilter(initFilterState));
                setJson4Preview(stringifyFilter(initFilterState));
            }

            const filterParam =
                '?' +
                (instanceId > 0 ? `instanceId=${instanceId}` : '') +
                (filterId > 0 ? `&filterId=${datasetData.filterId}` : '');

            setDataPreviewUrl(`/data/dataset/${datasetId}/querybuilder/run-sql${filterParam}`);

            if (datasetData.datasetData.dataset_type === 'external_dataset') {
                dispatch(
                    setBreadcrumbs([
                        {
                            label: datasetData?.sdc?.name,
                            href: `/editor/data-source/${datasetData?.sdct?.sourceDatabaseConnectionId}#datasets`,
                            translate: false,
                        },
                        {
                            label: datasetData?.sdct?.name,
                            translate: false,
                        },
                    ]),
                );
            } else if (datasetData.datasetData.can_be_used_as_access_map_ind === 'Y') {
                dispatch(
                    setBreadcrumbs([
                        {
                            label: t('breadcrumbs.user_map_grid'),
                            href: `/editor/dataset-access-map`,
                        },
                        {
                            label: datasetData.datasetData.name,
                            href: `/editor/dataset-access-map/${datasetId}`,
                            translate: false,
                        },
                        {
                            label: filterData ? filterData.name : t('breadcrumbs.all_data'),
                            translate: false,
                        },
                    ]),
                );
            } else {
                dispatch(
                    setBreadcrumbs([
                        {
                            label: t('breadcrumbs.grid'),
                            href: `/editor/dataset`,
                        },
                        {
                            label: datasetData.datasetData.name,
                            href: `/editor/dataset/${datasetId}`,
                            translate: false,
                        },
                        {
                            label: filterData ? filterData.name : t('breadcrumbs.all_data'),
                            translate: false,
                        },
                    ]),
                );
            }

            if (datasetData.datasetData.can_be_used_as_access_map_ind === 'Y') {
                dispatch(setPageTitle(`${t('breadcrumbs.um_page_title')} ${datasetData.datasetData.name}`));
            } else {
                dispatch(setPageTitle(`${t('breadcrumbs.page_title')} ${datasetData.datasetData.name}`));
            }
        }
    }, [datasetData]);

    useEffect(() => {
        if (datasetData && selectedFilter > '') {
            let newUrl = `/dataset/${datasetId}`;

            if (instanceId > 0) {
                newUrl = newUrl + `/instance/${instanceId}`;
            }

            if (Number(selectedFilter) !== 0) {
                newUrl = newUrl + `/filter/${selectedFilter}`;
            }

            if (Number(selectedFilter) === 0) {
                setFilter(cloneDeep(initFilterState));
            }

            navigate(newUrl);
        }
    }, [selectedFilter]);

    const onLeftTabChange = (index: number) => {
        setFilter((prevState) => {
            const newState = { ...prevState };

            newState.leftTab = index === 0 ? 'fields' : 'trackChanges';

            return newState;
        });
    };

    const onCurrentFilterUpdate = (newFilter: RulesConfig) => {
        const currentFilterNoOrder = { ...filter, fieldsOrder: [] };
        const newFilterNoOrder = { ...newFilter, fieldsOrder: [] };

        if (isEqual(currentFilterNoOrder, newFilterNoOrder)) {
            return;
        }

        if (
            filter.fieldsOrder &&
            filter.fieldsOrder.length === 0 &&
            newFilter.fieldsOrder &&
            newFilter.fieldsOrder.length > 0 &&
            filter.resultFields.length === newFilter.resultFields.length
        ) {
            setJson(stringifyFilter(newFilter));
            setFilter(newFilter);
            return;
        }

        setFilterDirty(true);
        setBuilderDirty(true);
        setJson(stringifyFilter(newFilter));
        setFilter(newFilter);
    };

    const onFilterChange = (value: string) => {
        if (selectedFilter == value) {
            return;
        }

        if (value.includes('_')) {
            const parts = value.split('_');
            const dsId = Number(parts[0]);
            const fltId = Number(parts[1]);

            let newUrl = `/dataset/${dsId}`;
            if (fltId > 0) {
                newUrl = newUrl + `/filter/${fltId}`;
            }

            navigate(newUrl);

            return;
        }

        if (filterDirty) {
            setShowConfirmationPopup(true);
            setTmpSelectedFilter(value);

            return;
        }

        setSelectedFilter(value);
        setJson(stringifyFilter(initFilterState));
        setFilter(cloneDeep(initFilterState));
    };

    const onConfirmationHide = () => {
        setShowConfirmationPopup(false);
        setTmpSelectedFilter('');
    };

    const confirmPopupSettings: PopupSettings = {
        title: t('switch_view.title'),
        textOK: t('switch_view.button'),
    };

    const onConfirm = () => {
        setSelectedFilter(tmpSelectedFilter);
        setBuilderDirty(false);
        setFilterDirty(false);
        onConfirmationHide();
    };

    const applyChanges = () => {
        if (builderDirty) {
            setJson4Preview(json);
            setBuilderDirty(false);
        }
    };

    const saveFilter = async () => {
        setShowLoader(true);

        return await datasetViewAPI
            .saveFilter(datasetId, filterId, json)
            .then((response) => {
                if (response.status === 'OK') {
                    setBuilderDirty(false);
                    setFilterDirty(false);
                    setErrorPopupTitle('error');

                    return true;
                }

                if (response.status === 'ERROR') {
                    if (response.message) {
                        setErrorPopupMessage(response.message);
                    } else {
                        setErrorPopupMessage(t('visibility.error_switch_visibility'));
                    }
                    setErrorPopupTitle('view.error_title');

                    return false;
                }

                return false;
            })
            .finally(() => {
                setShowLoader(false);
            });
    };

    const switchFilterVisibility = async () => {
        if (selectedFilterData) {
            const newPublic = selectedFilterData.public_ind === 'Y' ? 'N' : 'Y';

            const result = await datasetViewAPI
                .switchFilterPublic(datasetId, Number(selectedFilter), newPublic)
                .then((response) => {
                    if (response.status === 'OK') {
                        setSelectedFilterData((prevState) => {
                            if (prevState) {
                                const newState = { ...prevState };
                                newState.public_ind = newPublic;

                                return newState;
                            }

                            return null;
                        });

                        return true;
                    }

                    if (response.status === 'ERROR') {
                        if (response.message) {
                            setErrorPopupMessage(response.message);
                        } else {
                            setErrorPopupMessage(t('visibility.error_switch_visibility'));
                        }

                        return false;
                    }

                    return false;
                });

            return result;
        }

        return false;
    };

    const reloadDatasetView = (withFilter = true, newFilterId = filterId) => {
        if (!withFilter) {
            let newUrl = `/dataset/${datasetId}`;

            if (instanceId > 0) {
                newUrl = newUrl + `/instance/${instanceId}`;
            }

            setFilter(cloneDeep(initFilterState));
            navigate(newUrl);
            return;
        }

        loadDatasetData(newFilterId);
    };

    const onInstanceChange = (newInstanceId: string) => {
        let url = `/dataset/${datasetId}/instance/${newInstanceId}`;
        if (filterId) {
            url = url + `/filter/${filterId}`;
        }

        const filterParam =
            '?' +
            (newInstanceId > '' ? `instanceId=${newInstanceId}` : '') +
            (filterId > 0 ? `&filterId=${filterId}` : '');

        setDataPreviewUrl(`/data/dataset/${datasetId}/querybuilder/run-sql${filterParam}`);
        setCurrentInstance(newInstanceId);
        setJson4Preview(json);
        setBuilderDirty(false);
        navigate(url, { replace: true });
    };

    const onSortColumn = useCallback(
        (dataKey: string, sortType?: SortType) => {
            const newFilter = { ...filter };
            newFilter.sort = {
                field: dataKey,
                dir: sortType === 'asc' ? 'ASC' : 'DESC',
            };
            const newJson = stringifyFilter(newFilter);
            setOrder(sortType ?? 'asc');
            setOrderBy(dataKey);
            setJson(newJson);
            setFilter(newFilter);
            setFilterDirty(true);
            setJson4Preview(newJson);
        },
        [filter],
    );

    const showRestrictData =
        datasetData &&
        datasetData.datasetData.has_access_map_ind === 'Y' &&
        (isAdmin || isPowerUser) &&
        datasetData.isPermitted4EditDataset === 'Y';

    const errorPopupSettings: PopupSettings = {
        title: t(errorPopupTitle),
        noOk: true,
        textCancel: t('close'),
        needTranslation: false,
    };

    const showErrorPopup = errorPopupMessage > '';

    const hasNumericAndDateFields = useMemo(() => {
        let hasNumeric = false;
        let hasDate = false;

        if (datasetData && filter.resultFields) {
            filter.resultFields.forEach((field) => {
                const fieldData = datasetData.columnsData.find((column) => column.id === field);

                if (fieldData) {
                    if (fieldData.value_type === 'numeric') {
                        hasNumeric = true;
                    }
                    if (fieldData.value_type === 'datetime') {
                        hasDate = true;
                    }
                }
            });
        }

        return hasNumeric && hasDate;
    }, [filter, datasetData]);

    return datasetData ? (
        <Stack direction={'column'} alignItems="stretch" sx={{ height: 1, overflow: 'hidden' }}>
            {datasetData.datasetData.dataset_type === 'external_dataset' ? (
                <VirtualDatasetViewer
                    filterId={filterId}
                    datasetId={datasetId}
                    datasetData={datasetData.datasetData}
                    datasetInfoData={datasetData.datasetInfo}
                />
            ) : (
                <>
                    {showMetricBuilder ? (
                        <MetricBuilder
                            setShowMetricBuilder={setShowMetricBuilder}
                            datasetData={datasetData}
                            viewName={selectedFilter}
                            selectedFilterData={selectedFilterData}
                            filter={filter}
                            filterId={filterId}
                            datasetId={datasetId}
                            reloadElementsAmount={loadElementsAmount}
                        />
                    ) : (
                        <>
                            <Infobar
                                selectedFilter={selectedFilter}
                                filterSelectData={filterSelectData}
                                onFilterSelect={onFilterChange}
                                filterDirty={filterDirty}
                                selectedFilterData={selectedFilterData}
                                isAdmin={datasetData.isAdmin === 'Y'}
                                isCreateAllowed={datasetData.isCreateAllowed === 'Y'}
                                isPermitted4EditDataset={datasetData.isPermitted4EditDataset === 'Y'}
                                userId={datasetData.userId}
                                elementsAmount={elementsAmount}
                                datasetId={datasetId}
                                originalDatasetId={datasetData.originalDatasetId}
                                json={json}
                                saveFilter={saveFilter}
                                switchFilterVisibility={switchFilterVisibility}
                                reloadDatasetView={reloadDatasetView}
                                instanceId={instanceId}
                                setShowLoader={setShowLoader}
                                datasetInfo={datasetData.datasetInfo}
                                setShowMetricBuilder={setShowMetricBuilder}
                                hasNumericAndDateFields={hasNumericAndDateFields}
                                reloadElementsAmount={loadElementsAmount}
                            />
                            <InstanceInfo
                                datasetData={datasetData.datasetData}
                                datasetInstances={datasetData.datasetInstances}
                                lastUpdate={datasetData.updateFormattedDate}
                                currentInstance={currentInstance}
                                setCurrentInstance={onInstanceChange}
                                priorInstance={priorInstance}
                                setPriorInstance={setPriorInstance}
                                instanceMode={filter.history ? 'lastTwo' : 'current'}
                                setInstanceMode={onInstanceModeUpdate}
                            />
                            <QueryBuilder
                                columnsData={datasetData.columnsData}
                                selectedFilterData={selectedFilterData}
                                filter={cloneDeep(filter)}
                                onCurrentFilterUpdate={onCurrentFilterUpdate}
                                datasetId={datasetId}
                                multiInstancesMode={showInstancesSelector}
                                builderDirty={builderDirty}
                                json={json}
                                applyChanges={applyChanges}
                                onLeftTabChange={onLeftTabChange}
                            />
                        </>
                    )}
                    <DataPreview
                        show
                        defaultHeight={350}
                        onResize={() => {}}
                        gridConfigUrl={dataPreviewUrl}
                        pkId={datasetId.toString()}
                        uid={'dataset-preview'}
                        showLimitSelector
                        showRestrictData={showRestrictData}
                        dataPayload={json4Preview}
                        infiniteScroll
                        onSortColumn={onSortColumn}
                        order={order}
                        orderBy={orderBy}
                        emptyGridMessage={datasetData.datasetData.generate_empty_instance_message}
                    />
                </>
            )}
            {showConfirmationPopup && (
                <Popup
                    settings={confirmPopupSettings}
                    open={showConfirmationPopup}
                    onHide={onConfirmationHide}
                    onConfirm={onConfirm}
                    maxWidth={'popupSm'}
                >
                    <Typography>You have unsaved changes in your current view.</Typography>
                    <Typography>By switching to another view you will lose your changes.</Typography>
                </Popup>
            )}
            {showLoader && (
                <LoadingPlaceholder
                    sx={{
                        position: 'absolute',
                        backgroundColor: (theme) => alpha(theme.palette.background.default, 0.6),
                        color: (theme) => alpha(theme.palette.text.primary, 0.5),
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                />
            )}
            {showErrorPopup && (
                <Popup
                    settings={errorPopupSettings}
                    open={showErrorPopup}
                    onHide={() => setErrorPopupMessage('')}
                    maxWidth={'popupSm'}
                >
                    <Box>{parse(converter.makeHtml(errorPopupMessage))}</Box>
                </Popup>
            )}
        </Stack>
    ) : null;
}

export default DatasetViewer;
