import React, { useEffect, useState } from 'react';
import { Backdrop, Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { ButtonConfig } from 'components/common/grid/';
import { TFunction } from 'i18next';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import { gridAPI } from 'api/grid';
import IconMi from 'components/common/icon/IconMi';
import { prepareTranslatedText } from 'tools/tools';

type MassDeleteFailedMessageExcludes = {
    message: string;
    items: string[];
};

type MassDeleteFailedMessage = {
    excludes?: MassDeleteFailedMessageExcludes[];
};

type MassDeleteCheckResponse = {
    data: {
        data: {
            success: number[];
            failedMessage?: MassDeleteFailedMessage;
        };
    };
};

export default function MassDeleteButton({
    config,
    controllerUrl,
    uid,
    selectedItems,
    selectedItemsNames,
    reloadGridData,
    setCheckedKeys,
    t,
    id,
    isRemove,
    groupTitles,
    removeText,
}: {
    config: ButtonConfig;
    controllerUrl: string;
    uid: string;
    selectedItems: Array<string | number>;
    selectedItemsNames: Array<string | number>;
    reloadGridData?: () => void;
    setCheckedKeys?: (keys: Array<string | number>) => void;
    t: TFunction;
    id: string;
    isRemove: boolean;
    removeText?: string;
    groupTitles?: {
        single: string;
        multi: string;
    };
}) {
    const singleItem = selectedItems.length === 1;
    const deleteUrl = config.url ?? controllerUrl + '/form?mass-delete=true';
    const countItems = selectedItems.length;

    const adjustConfirmation = (isRemove: boolean, singleItem: boolean, countItems: number) => {
        const deleteItem =
            groupTitles && t(`mass_delete_items.${singleItem ? groupTitles?.single : groupTitles.multi}`);

        if (config.confirmation) {
            const confirmationExist = `${config.confirmation}_one` !== t(`${config.confirmation}_one`);

            return t(`${config.confirmation}${singleItem ? (confirmationExist ? '_one' : '') : '_other'}`, {
                count: countItems,
                name: selectedItemsNames[selectedItemsNames.length - 1],
            });
        } else if (deleteItem) {
            return t(isRemove ? 'mass_remove_item_description' : 'mass_delete_item_description', {
                count: (countItems > 1 ? countItems : '') as any,
                item: deleteItem,
            });
        } else {
            return t(
                isRemove
                    ? `mass_delete_remove_default_confirmation${singleItem ? '_one' : '_other'}`
                    : `mass_delete_default_confirmation${singleItem ? '_one' : '_other'}`,
                {
                    count: countItems,
                },
            );
        }
    };

    const adjustPopupSettings = (isRemove: boolean, singleItem: boolean) => {
        const deleteItem =
            groupTitles && t(`mass_delete_items.${singleItem ? groupTitles?.single : groupTitles.multi}`);

        const deleteTitle = deleteItem
            ? t('mass_delete_item_title', { item: deleteItem })
            : t('mass_delete_popup_title', {
                  count: selectedItems.length,
              });

        const removeTitle = deleteItem
            ? t('mass_remove_item_title', { item: deleteItem })
            : t(removeText && singleItem ? removeText : `${removeText}_other`);

        return {
            title: isRemove ? removeTitle : deleteTitle,
            textOK: t(isRemove ? 'mass_delete_remove_popup_confirm' : 'mass_delete_popup_confirm'),
            needTranslation: false,
            colorOk: !isRemove ? 'error' : undefined,
            iconOK: !isRemove ? 'trash' : undefined,
        };
    };
    const [isFailedMessage, setIsFailedMessage] = useState<boolean>(false);
    const [successItems, setSuccessItems] = useState<number[]>([]);
    const [remainingItemsText, setRemainingItemsText] = useState<string>('');
    const [failedMessage, setFailedMessage] = useState<MassDeleteFailedMessage | string>({});
    const [open, setOpen] = useState<boolean>(false);
    const [loadingMaskShow, setLoadingMaskShow] = useState<boolean>(false);
    const [commonLoadingMaskShow, setCommonLoadingMaskShow] = useState<boolean>(false);
    const [popupSettings, setPopupSettings] = useState<PopupSettings>(adjustPopupSettings(isRemove, singleItem));
    const [confirmation, setConfirmation] = useState<string>(adjustConfirmation(isRemove, singleItem, countItems));

    useEffect(() => {
        setPopupSettings(adjustPopupSettings(isRemove, singleItem));
        setConfirmation(adjustConfirmation(isRemove, singleItem, countItems));
    }, [singleItem, isRemove, countItems]);

    const makeMassDelete = () => {
        if (!deleteUrl) {
            return;
        }

        let itemsToDelete: Array<string | number> = config.remoteConfirm ? successItems : selectedItems;
        if (0 == itemsToDelete.length) {
            setOpen(false);
            if (setCheckedKeys) {
                setCheckedKeys([]);
            }
            setIsFailedMessage(false);
            setFailedMessage({});
            setSuccessItems([]);

            return;
        }

        setLoadingMaskShow(true);

        gridAPI
            .massDelete(
                deleteUrl + (config.remoteConfirm ? (-1 === deleteUrl.indexOf('?') ? '?' : '&') + 'confirm=true' : ''),
                { ids: itemsToDelete },
            )
            .then(() => {
                if (reloadGridData) {
                    reloadGridData();
                }
                if (setCheckedKeys) {
                    setCheckedKeys([]);
                }
            })
            .finally(() => {
                setOpen(false);
                setLoadingMaskShow(false);
            });
    };

    const onDeleteClick = () => {
        if (config.remoteConfirm) {
            setCommonLoadingMaskShow(true);
            gridAPI
                .massDelete(deleteUrl, { ids: selectedItems })
                .then((response: MassDeleteCheckResponse) => {
                    setPopupSettings({
                        ...popupSettings,
                        textOK: t(isRemove ? 'mass_delete_remove_popup_confirm' : 'mass_delete_popup_confirm'),
                    });

                    if (response.data?.data?.failedMessage) {
                        setIsFailedMessage(true);
                        setFailedMessage(response.data.data.failedMessage);

                        if (0 == response.data?.data?.success?.length) {
                            setSuccessItems([]);
                            setRemainingItemsText('');
                            setPopupSettings({
                                ...popupSettings,
                                noOk: true,
                            });
                        } else {
                            setSuccessItems(response.data.data.success);
                            setRemainingItemsText(
                                t('mass_delete_remaining_confirmation', {
                                    count: response.data.data.success.length,
                                }),
                            );
                        }
                    } else {
                        if (response.data?.data?.success?.length > 0) {
                            setSuccessItems(response.data.data.success);
                        }

                        setIsFailedMessage(false);
                        setFailedMessage({});
                    }

                    setOpen(true);
                })
                .catch(() => {
                    setOpen(false);
                })
                .finally(() => {
                    setCommonLoadingMaskShow(false);
                });
        } else setOpen(true);
    };

    const { text: btnText, needTranslation } = prepareTranslatedText(config.text ?? 'delete');

    return (
        <Grid item key={config.name} sx={{ flexShrink: 0 }}>
            <Button
                data-test={id}
                onClick={onDeleteClick}
                key={uid + config.name}
                startIcon={<IconMi icon={isRemove ? 'times' : 'delete'} />}
                variant="outlined"
            >
                {needTranslation === false ? btnText : t(btnText)}
            </Button>
            <Popup
                maxWidth={'popupSm'}
                settings={popupSettings}
                open={open}
                onHide={() => {
                    setOpen(false);
                    setCheckedKeys && setCheckedKeys([]);
                }}
                onConfirm={makeMassDelete}
                t={t}
            >
                {isFailedMessage ? (
                    typeof failedMessage === 'object' && failedMessage.excludes ? (
                        <div>
                            {failedMessage.excludes?.map((s: MassDeleteFailedMessageExcludes, key: number) => {
                                if (s.items.length) {
                                    return (
                                        <div key={key}>
                                            <span>{s.message}</span>
                                            <ul>
                                                {s.items.map((i: string) => (
                                                    <li>{i}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    );
                                } else return '';
                            })}
                            {remainingItemsText ?? ''}
                        </div>
                    ) : (
                        <Box
                            dangerouslySetInnerHTML={{
                                __html: typeof failedMessage === 'string' ? failedMessage : confirmation,
                            }}
                        />
                    )
                ) : (
                    <Typography>{confirmation}</Typography>
                )}
                <Backdrop
                    sx={{ color: 'background.default', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loadingMaskShow}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Popup>
            <Backdrop
                sx={{ color: 'background.default', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={commonLoadingMaskShow}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    );
}
