import { PopupSettings } from 'components/common/popup/Popup';
import { ButtonConfig, FilterValue, PopupMode, PopupType } from '../index';
import { downloadAPI } from 'api/api';
import { gridAPI } from 'api/grid';
import { formAPI } from 'api/form';
import { NavigateFunction } from 'react-router';
import pollingService from 'tools/pollingService';
import { openInNewTab } from 'tools/tools';

function processPopupConfig(
    config: ButtonConfig,
    controllerUrl: string,
    buttonType: 'create' | 'custom' | 'mass',
    popupActions?: {
        setPopupUrl?: (url: string) => void;
        setPopupConfig?: (popupConfig: PopupSettings) => void;
        setPopupType?: (type: PopupType) => void;
        setPopupMode?: (mode: PopupMode) => void;
        setPopupRedirectAfter?: (redirect: boolean) => void;
        setFormPostData?: (formData: any) => void;
        setPopupGetMethod?: (getMethod: boolean) => void;
        setPopupComponent?: (popupComponent: string) => void;
        setPopupComponentProps?: (componentProps: { [key: string]: string }) => void;
    },
    selectedItems?: (string | number)[],
) {
    if (popupActions) {
        if (popupActions.setPopupType) {
            popupActions.setPopupType(config.popupType ? config.popupType : buttonType === 'create' ? 'form' : 'page');
        }
        if (popupActions.setPopupMode) {
            popupActions.setPopupMode(config.popupMode ? config.popupMode : 'new');
        }
        if (popupActions.setPopupRedirectAfter) {
            popupActions.setPopupRedirectAfter(config.redirectAfter ? config.redirectAfter : false);
        }
        if (popupActions.setPopupConfig) {
            popupActions.setPopupConfig(config.popupConfig ? config.popupConfig : {});
        }
        if (popupActions.setPopupUrl) {
            popupActions.setPopupUrl(
                config.url ? config.url : controllerUrl + '/' + (buttonType === 'create' ? 'form' : config.handler),
            );
        }
        if (popupActions.setFormPostData && selectedItems) {
            popupActions.setFormPostData({ ids: selectedItems });
        }
        if (popupActions.setPopupGetMethod) {
            popupActions.setPopupGetMethod(config.getMethod ? config.getMethod : false);
        }
        if (popupActions.setPopupComponent) {
            popupActions.setPopupComponent(config.component ? config.component : '');
        }
        if (popupActions.setPopupComponentProps) {
            popupActions.setPopupComponentProps(config.popupComponentProps ? config.popupComponentProps : {});
        }
    }
}

function getUrl(config: ButtonConfig, controllerUrl: string) {
    return config.url ?? controllerUrl + '/' + config.handler;
}

function checkParams(config: ButtonConfig, controllerUrl: string) {
    return !(!controllerUrl && !config.url);
}

export interface ConfirmationProps {
    confirmation: string;
    confirmed: boolean;
    showConfirmation: () => void;
    setConfirmed: (value: boolean) => void;
}

export function onButtonClickAction(
    config: ButtonConfig,
    controllerUrl: string,
    buttonType: 'create' | 'custom' | 'mass',
    navigate: NavigateFunction,
    popupActions?: {
        setPopupUrl?: (url: string) => void;
        setPopupConfig?: (popupConfig: PopupSettings) => void;
        setPopupType?: (type: PopupType) => void;
        setPopupMode?: (mode: PopupMode) => void;
        setPopupRedirectAfter?: (redirect: boolean) => void;
        setFormPostData?: (formData: any) => void;
        setPopupGetMethod?: (getMethod: boolean) => void;
        setPopupComponent?: (popupComponent: string) => void;
        setPopupComponentProps?: (componentProps: { [key: string]: string }) => void;
    },
    selectedItems?: (string | number)[],
    setShowLoader?: (value: boolean) => void,
    reloadGridData?: () => void,
    confirmationProps?: ConfirmationProps,
    setCheckedKeys?: (keys: Array<string | number>) => void,
    setErrorMessage?: (value: string) => void,
    filtersValue?: FilterValue,
    setErrorLink?: (value: string) => void,
) {
    const onSuccess = (successResponseData: any) => {
        if (setShowLoader) {
            setShowLoader(false);
        }
        if (successResponseData?.warning) {
            alert(successResponseData.warning);
        }
        if (reloadGridData) {
            reloadGridData();
        }
        if (setCheckedKeys) {
            setCheckedKeys([]);
        }
    };

    const onError = (response: { message: string; link?: string }) => {
        if (setShowLoader) {
            setShowLoader(false);
        }

        if (config.showErrorPopup && setErrorMessage) {
            setErrorMessage(response.message);
            if (response.link && setErrorLink) {
                setErrorLink(response.link);
            }
        }
    };

    if (checkParams(config, controllerUrl)) {
        const url = getUrl(config, controllerUrl);

        if (config.redirect === true && config.insert === true) {
            formAPI.saveFormData(url, {}).then((response: any) => {
                if ('ERROR' === response.data.status) {
                    if (response.data.showPopup && setErrorMessage) {
                        setErrorMessage(response.data.message);
                        if (response.data.link && setErrorLink) {
                            setErrorLink(response.data.link);
                        }
                    } else {
                        alert(response.data.message);
                    }
                } else if (response.data.redirectUrl) navigate(response.data.redirectUrl);
                else if (response.redirectUrl) navigate(response.redirectUrl);
            });

            return;
        }

        if (config.redirect === true) {
            navigate(url);
            return;
        }

        if (config.download === true) {
            const options: FilterValue = {};

            if (config.useFilters && filtersValue) {
                config.useFilters.forEach((filterName) => {
                    if (filtersValue.hasOwnProperty(filterName)) {
                        options[filterName] = filtersValue[filterName];
                    }
                });
            }

            downloadAPI.export.getDownload(url, options);
            return;
        }

        if (config.url && config.async === true) {
            if (confirmationProps && !confirmationProps.confirmed) {
                confirmationProps.showConfirmation();
                return;
            }

            if (setShowLoader) {
                setShowLoader(true);
            }

            gridAPI
                .buttonAsyncAction(config.url, selectedItems)
                .then((response) => {
                    if (response.status === 'QUEUE') {
                        const { run } = pollingService({
                            onSuccess,
                            onError,
                            requestData: {},
                        });

                        run(response.data.ppl_id);
                    }

                    if (response.status === 'OK') {
                        const responseData = response.data;

                        if (responseData && responseData.reloadGridData && reloadGridData) {
                            reloadGridData();
                        }

                        if (setCheckedKeys) {
                            setCheckedKeys([]);
                        }

                        if (responseData && responseData.href) {
                            if (responseData.newTab) {
                                openInNewTab(responseData.href);
                            } else {
                                navigate(responseData.href);
                            }
                        }

                        if (setShowLoader) {
                            setShowLoader(false);
                        }
                    }
                })
                .finally(() => {
                    if (confirmationProps) {
                        confirmationProps.setConfirmed(false);
                        return;
                    }
                });

            return;
        }

        processPopupConfig(config, controllerUrl, buttonType, popupActions, selectedItems);

        return;
    }

    throw new Error('controllerUrl is not provided in grid settings');
}
