import React, { MutableRefObject, useContext, useState } from 'react';
import { wallAPI } from 'api/wall';
import { AssocArray } from 'tools/types';

export type WallActions = {
    setExpertAnalysisField: (state: boolean) => void;
    onDeleteAnnotation: (ann_id: any) => void;
    onDeleteEvent: (event_id: any) => void;
    setActiveAddCommentField: (type: string, id: any) => void;
};

export type WallContextType = {
    wallActions: WallActions;
    setWallActions: (actions: WallActions) => void;
    addOnUpdateCallback: (name: string, callback: Function) => void;
    triggerOnUpdate: (response: any) => void;
    editAnnotationId: number;
    setEditAnnotationId: (annotationId: number) => void;
    editEventId: number;
    setEditEventId: (annotationId: number) => void;
};

const WallActionsDefaults: WallActions = {
    setExpertAnalysisField: () => {},
    onDeleteAnnotation: (ann_id) => {},
    onDeleteEvent: (event_id) => {},
    setActiveAddCommentField: (type, id) => {},
};

export const WallContext = React.createContext<WallContextType>({
    wallActions: WallActionsDefaults,
    setWallActions: () => {},
    addOnUpdateCallback: (name: string, callback: Function) => {},
    triggerOnUpdate: (response: any) => {},
    editAnnotationId: 0,
    setEditAnnotationId: (annotationId: number) => {},
    editEventId: 0,
    setEditEventId: (annotationId: number) => {},
});

export function wallDeleteAnnotation(
    elementId: number,
    segmentValueId: number,
    instance: number,
    annotationId: number,
    isMetric: boolean,
): Promise<any> {
    return wallAPI.deleteAnnotation({
        element: elementId,
        segment: segmentValueId,
        instance: instance,
        ann_id: annotationId,
        isMetric: isMetric,
    });
}

export function wallDeleteEvent(eventId: number, elementId: number, segmentValueId: number): Promise<any> {
    return wallAPI.deleteEvent(eventId, elementId, segmentValueId);
}

export default function useWallContext(isHost: boolean): WallContextType {
    const [wallActions, _setWallActions] = useState<WallActions>(WallActionsDefaults);

    const setWallActions = (actions: WallActions) => {
        _setWallActions((prevState) => {
            return { ...prevState, ...actions };
        });
    };

    const [onUpdateCallback, setOnUpdateCallback] = useState<AssocArray<Function>>({});

    const addOnUpdateCallback = (name: string, callback: Function) => {
        setOnUpdateCallback((prevState) => {
            const result = { ...prevState };
            result[name] = callback;
            return result;
        });
    };

    const triggerOnUpdate = (response: any) => {
        for (const [key, callback] of Object.entries(onUpdateCallback)) {
            callback(response);
        }
    };

    const wallContext = useContext<WallContextType>(WallContext);
    if (isHost) {
        //@ts-ignore
        return {
            wallActions,
            setWallActions,
            addOnUpdateCallback,
            triggerOnUpdate,
            // editAnnotationId,
            // setEditAnnotationId,
        };
    }
    return { ...wallContext };
}
