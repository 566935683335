import * as React from 'react';
import { ReportContentNS } from 'components/report-content';
import ComponentSettings = ReportContentNS.ComponentSettings;
import DatasetField = ReportContentNS.DatasetField;
import { alpha, Backdrop, Box } from '@mui/material';
import DisplayMask = ReportContentNS.DisplayMask;
import { useEffect } from 'react';

export interface EditPanelContextType {
    updateBlockSettings: (blockUID: string, newSettings: ComponentSettings, closePanel: boolean) => void;
    closeEditPanel: (blockUID: string, newSettings: ComponentSettings) => void;
    datasetFields: Array<DatasetField>;
    displayMasks: Array<DisplayMask>;
    panelOptions: any;
}

export const EditPanelContext = React.createContext<EditPanelContextType | null>(null);

export default function BlockEditPanel({
    content,
    updateBlockSettings,
    closeEditPanel,
    datasetFields,
    displayMasks,
    panelOptions,
}: {
    content: any;
    updateBlockSettings: (blockUID: string, newSettings: ComponentSettings, closePanel: boolean) => void;
    closeEditPanel: (blockUID: string, newSettings: ComponentSettings) => void;
    datasetFields: Array<DatasetField>;
    displayMasks: Array<DisplayMask>;
    panelOptions: any;
}) {
    useEffect(() => {
        document.body.classList.add('content-editor-aside-panel-active');
        return () => document.body.classList.remove('content-editor-aside-panel-active');
    }, []);

    return (
        <Backdrop sx={{ zIndex: (theme) => theme.zIndex.tabsPanel + 1 }} open={true} onClick={() => {}}>
            <Box
                sx={{
                    position: 'fixed',
                    display: 'flex',
                    flexDirection: 'column',
                    top: 0,
                    right: 0,
                    height: '100%',
                    width: 640,
                    zIndex: 4,
                    backgroundColor: (theme) => theme.palette.background.default,
                    boxShadow: (theme) => `0px 8px 32px ${alpha(theme.palette.text.primary, 0.4)}`,
                    pt: (theme) => theme.size.navbarHeight + 'px',
                }}
                className={'content-editor-aside-panel'}
            >
                <EditPanelContext.Provider
                    value={{
                        panelOptions: panelOptions,
                        updateBlockSettings: updateBlockSettings,
                        closeEditPanel: closeEditPanel,
                        datasetFields: datasetFields,
                        displayMasks: displayMasks,
                    }}
                >
                    {content}
                </EditPanelContext.Provider>
            </Box>
        </Backdrop>
    );
}
