import { AnyElementsViewerDataType, ElementDocumentType, ElementRowData, ElementType } from 'components/element-viewer';
import useBundleTranslation from 'i18n';
import { MetricViewerDataType } from 'components/metric/MetricViewer';
import { Box, Stack } from '@mui/material';
import styles from './ElementPreviewSummary.styles';
import IconMi from 'components/common/icon/IconMi';
import ElementTopics from 'components/element-viewer/element-topics/ElementTopics';
import EmailLine from 'components/element-viewer/common/EmailLine';
import React, { Fragment, useEffect, useState } from 'react';
import { TFunction } from 'i18next';
import Moment from 'moment';
import isOwnerVisible from 'tools/owners';
import { UserAuth } from 'store/auth';
import { useAppSelector } from 'store/hooks';
import { MenuDocument } from 'components/element-viewer/controls-panel-icon/DocumentsButton';
import { instance } from 'api/api';

export interface IElementPreviewSummary {
    elementInfo: ElementType;
    viewerRequestExtraData: AnyElementsViewerDataType;
    onToggleVisibility: () => void;
    isVisible: boolean;
    isPreview?: boolean;
}

export default function ElementPreviewSummary({
    elementInfo,
    isPreview,
    isVisible,
    onToggleVisibility,
    viewerRequestExtraData,
}: IElementPreviewSummary) {
    let sections = null;

    const { configuration }: UserAuth = useAppSelector((state) => state.auth);
    const { t } = useBundleTranslation(['components/common/element']);
    const [descriptionCut, setDescriptionCut] = useState<boolean>(false);
    const [descriptionExpand, setDescriptionExpand] = useState<boolean>(false);
    const descriptionContentRef = React.useRef<HTMLDivElement>(null);

    const showOwners =
        (isOwnerVisible(configuration.owners.business_owner, 'elements') &&
            isOwnerVisible(configuration.owners.business_owner, 'tile_preview')) ||
        (isOwnerVisible(configuration.owners.technical_owner, 'elements') &&
            isOwnerVisible(configuration.owners.technical_owner, 'tile_preview')) ||
        (isOwnerVisible(configuration.owners.data_steward, 'elements') &&
            isOwnerVisible(configuration.owners.data_steward, 'tile_preview'));

    if (['metric', 'multi-metric chart'].includes(elementInfo.row.type)) {
        sections = (
            <MetricChartSummarySection
                t={t}
                elementRow={elementInfo.row}
                viewerRequestExtraData={viewerRequestExtraData as MetricViewerDataType}
            />
        );
    }
    const lastUpdate = elementInfo.row.lastDisplayGenerationTime?.length
        ? Moment(elementInfo.row.lastDisplayGenerationTime).format('MMMM DD, yyyy h:m')
        : '';

    const maxDescContainerHeight = 90; //5 rows (1row = 18px)

    useEffect(() => {
        if (descriptionContentRef.current) {
            setDescriptionCut(descriptionContentRef.current.clientHeight > maxDescContainerHeight);
        }
    }, []);

    return (
        <>
            {isVisible && (
                <Box sx={styles.wrapper} className={isPreview ? 'summary--preview' : 'summary--default'}>
                    <Box sx={styles.scrollContainer}>
                        <Box sx={styles.contentHolder}>
                            <Stack sx={styles.section}>
                                {isPreview && elementInfo.documents.length > 0 && (
                                    <DocumentsSection documents={elementInfo.documents} />
                                )}
                                {(elementInfo.row.description || sections != null) && (
                                    <Stack
                                        sx={styles.heading}
                                        spacing={1}
                                        direction="row"
                                        justifyContent="space-between"
                                    >
                                        <Box>{t('summary.summary')}</Box>
                                        {!isPreview && (
                                            <Box sx={styles.closeIconHandler}>
                                                <IconMi
                                                    onClick={onToggleVisibility}
                                                    icon={'times'}
                                                    fontSize="16"
                                                    sx={styles.closeIcon}
                                                />
                                            </Box>
                                        )}
                                    </Stack>
                                )}
                                {elementInfo.row.description && (
                                    <Stack sx={{ ...styles.row, flexDirection: 'column' }}>
                                        <Box
                                            sx={{
                                                ...styles.descriptionHolder,
                                                maxHeight:
                                                    descriptionCut && !descriptionExpand
                                                        ? `${maxDescContainerHeight}px`
                                                        : 'none',
                                            }}
                                        >
                                            <Box ref={descriptionContentRef}>
                                                {elementInfo.row.descriptionMarkdownInd ? (
                                                    <span
                                                        className={'markdown-holder'}
                                                        dangerouslySetInnerHTML={{
                                                            __html: elementInfo.row.descriptionMarkdown,
                                                        }}
                                                    />
                                                ) : (
                                                    elementInfo.row.description
                                                )}
                                            </Box>
                                        </Box>
                                        {descriptionCut && (
                                            <Box
                                                onClick={() => {
                                                    setDescriptionExpand(!descriptionExpand);
                                                }}
                                                sx={styles.descriptionExpandControl}
                                            >
                                                {descriptionExpand
                                                    ? t('description_show_less')
                                                    : t('description_show_more')}
                                            </Box>
                                        )}
                                    </Stack>
                                )}
                                {sections}
                                <ElementTopics styles={styles} topics={elementInfo.topics} />
                                {showOwners && (
                                    <>
                                        {isOwnerVisible(configuration.owners.business_owner, 'elements') &&
                                            isOwnerVisible(configuration.owners.business_owner, 'tile_preview') && (
                                                <Stack sx={styles.row}>
                                                    <Box sx={styles.label}>
                                                        <IconMi icon="user" sx={{ ...styles.icon }} />
                                                        {configuration.owners.business_owner.label}
                                                    </Box>
                                                    <Box sx={styles.value}>
                                                        <EmailLine
                                                            email={elementInfo.info.businessOwnerEmail}
                                                            ownerName={elementInfo.info.businessOwnerDn}
                                                            elementName={elementInfo.row.name}
                                                        />
                                                    </Box>
                                                </Stack>
                                            )}
                                        {isOwnerVisible(configuration.owners.data_steward, 'elements') &&
                                            isOwnerVisible(configuration.owners.data_steward, 'tile_preview') &&
                                            elementInfo.info.dataStewardDn?.length && (
                                                <Stack sx={styles.row}>
                                                    <Box sx={styles.label}>
                                                        <IconMi
                                                            icon="user"
                                                            sx={{
                                                                ...styles.icon,
                                                                visibility: !isOwnerVisible(
                                                                    configuration.owners.business_owner,
                                                                    'elements',
                                                                )
                                                                    ? 'visible'
                                                                    : 'hidden',
                                                            }}
                                                        />
                                                        {configuration.owners.data_steward.label}
                                                    </Box>
                                                    <Box sx={styles.value}>
                                                        <EmailLine
                                                            email={elementInfo.info.dataStewardEmail}
                                                            ownerName={elementInfo.info.dataStewardDn}
                                                            elementName={elementInfo.row.name}
                                                        />
                                                    </Box>
                                                </Stack>
                                            )}
                                        {isOwnerVisible(configuration.owners.technical_owner, 'elements') &&
                                            isOwnerVisible(configuration.owners.technical_owner, 'tile_preview') && (
                                                <Stack sx={styles.row}>
                                                    <Box sx={styles.label}>
                                                        <IconMi
                                                            icon="user"
                                                            sx={{
                                                                ...styles.icon,
                                                                visibility:
                                                                    !isOwnerVisible(
                                                                        configuration.owners.business_owner,
                                                                        'elements',
                                                                    ) &&
                                                                    !isOwnerVisible(
                                                                        configuration.owners.data_steward,
                                                                        'elements',
                                                                    )
                                                                        ? 'visible'
                                                                        : 'hidden',
                                                            }}
                                                        />
                                                        {configuration.owners.technical_owner.label}
                                                    </Box>
                                                    <Box sx={styles.value}>
                                                        <EmailLine
                                                            email={elementInfo.info.technicalOwnerEmail}
                                                            ownerName={elementInfo.info.technicalOwnerDn}
                                                            elementName={elementInfo.row.name}
                                                        />
                                                    </Box>
                                                </Stack>
                                            )}
                                    </>
                                )}
                                <Stack sx={styles.row}>
                                    <Box sx={styles.label}>
                                        <IconMi icon="engagement" sx={{ ...styles.icon }} />
                                        {t('engagement.engagement')}
                                    </Box>
                                    <Box sx={styles.value}>{elementInfo.engagement}</Box>
                                </Stack>
                                {elementInfo.row.lastMeasurementTimeFormatted?.length > 0 && (
                                    <Stack sx={styles.row}>
                                        <Box sx={styles.label}>{t('summary.last_data_point')}</Box>
                                        <Box sx={styles.value}>{elementInfo.row.lastMeasurementTimeFormatted}</Box>
                                    </Stack>
                                )}
                                {lastUpdate.length > 0 && (
                                    <Stack sx={styles.row}>
                                        <Box sx={styles.label}>{t('summary.last_updated')}</Box>
                                        <Box sx={styles.value}>{lastUpdate}</Box>
                                    </Stack>
                                )}
                                {elementInfo.customFields.map((section) => (
                                    <Stack key={section.cfs_id} sx={styles.section}>
                                        <Stack sx={styles.heading}>{section.label}</Stack>
                                        {section.fields.map((field) => {
                                            const value = Object.values(field.values).join(', ');
                                            return (
                                                <Stack key={field.id} sx={styles.row}>
                                                    <Box sx={styles.label}>{field.name}</Box>
                                                    {field.type == 'email' ? (
                                                        <Box
                                                            dangerouslySetInnerHTML={{ __html: value }}
                                                            sx={styles.value}
                                                        ></Box>
                                                    ) : (
                                                        <Box
                                                            sx={styles.value}
                                                            className={'markdown-holder'}
                                                            dangerouslySetInnerHTML={{
                                                                __html: value,
                                                            }}
                                                        />
                                                    )}
                                                </Stack>
                                            );
                                        })}
                                    </Stack>
                                ))}
                            </Stack>
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
}

function MetricChartSummarySection({
    elementRow,
    viewerRequestExtraData,
    t,
}: {
    elementRow: ElementRowData;
    viewerRequestExtraData: MetricViewerDataType;
    t: TFunction;
}) {
    if (viewerRequestExtraData.metrics.length == 0) {
        // At least one metric always should be present
        return <div></div>;
    }
    const metric = viewerRequestExtraData.metrics[0];
    return (
        <>
            <Stack sx={styles.row}>
                <Box sx={styles.label}>{t('summary.current_value')}</Box>
                <Box sx={styles.value}>
                    <Box component="strong">{metric.currValue}</Box> ({elementRow.lastMeasurementTimeFormatted})
                </Box>
            </Stack>
            {metric.metricTileDisplayPctVariance != null && (
                <Stack sx={styles.row}>
                    <Box sx={styles.label}>% {t('summary.change')}</Box>
                    <Box sx={styles.value}>
                        {metric.metricTileDisplayPctVariance != 0
                            ? metric.metricTileDisplayPctVariance > 0
                                ? t('summary.up')
                                : t('summary.down')
                            : ''}{' '}
                        {Math.abs(metric.metricTileDisplayPctVariance).toFixed(2)}%{' '}
                        {'from last day' == metric.pctVarianceText
                            ? t('summary.from_yesterday')
                            : metric.pctVarianceText}
                    </Box>
                </Stack>
            )}
            {metric.movingAverageValue && (
                <Stack sx={styles.row}>
                    <Box sx={styles.label}>
                        {metric.metricMovingAverageInterval} {t('summary.avg')}
                    </Box>
                    <Box sx={styles.value}>{metric.movingAverageValue}</Box>
                </Stack>
            )}
            {metric.minValue && (
                <Stack sx={styles.row}>
                    <Box sx={styles.label}>{t('summary.min_ever')}</Box>
                    <Box sx={styles.value}>
                        <Box component="strong">{metric.minValue}</Box>{' '}
                        {metric.minReachedOn && <>({metric.minReachedOn})</>}
                    </Box>
                </Stack>
            )}
            {metric.maxValue && (
                <Stack sx={styles.row}>
                    <Box sx={styles.label}>{t('summary.max_ever')}</Box>
                    <Box sx={styles.value}>
                        <Box component="strong">{metric.maxValue}</Box>{' '}
                        {metric.maxReachedOn && <>({metric.maxReachedOn})</>}
                    </Box>
                </Stack>
            )}
        </>
    );
}

function DocumentsSection({ documents }: { documents: Array<ElementDocumentType> }) {
    const { t } = useBundleTranslation(['components/common/navbar/navbar_menu']);
    const [open, setOpen] = useState(false);

    if (documents.length == 0) {
        return null;
    }

    useEffect(() => {
        if (!open) {
            return;
        }
        instance.post('data/content/mark-docs-as-viewed', { docIds: documents.map((doc) => doc.documentId) });
    }, [open]);

    return (
        <Stack sx={{ ...styles.row, flexDirection: 'column' }} className={'default-link-behavior border-top'}>
            <Box width={1} sx={styles.termSection} className={open ? 'expand' : 'collapse'}>
                <Stack
                    direction="row"
                    className={'term-section__header'}
                    onClick={() => {
                        setOpen((prevState) => !prevState);
                    }}
                >
                    <Box sx={styles.iconHolder}>
                        <IconMi icon="paperclip" sx={{ fontSize: '16px' }} />
                    </Box>
                    <Box flexGrow={1}>{t('content.documents')}</Box>
                    <Box sx={{ ...styles.iconHolder, ...{ mr: 0, ml: 1 } }}>
                        <IconMi icon={open ? 'chevron-down' : 'chevron-right'} sx={{ fontSize: 16 }} />
                    </Box>
                </Stack>
                <Stack direction="column" flexWrap={'wrap'} sx={{ pl: '24px' }} className={'term-section__items'}>
                    {documents.map((doc, index) => (
                        <Fragment key={doc.documentId}>
                            {(index == 0 || documents[index].documentType != documents[index - 1].documentType) && (
                                <Box sx={{ pt: index != 0 ? 1 : 0.5 }}>{doc.documentType}</Box>
                            )}
                            <Box sx={{ mt: 0.5 }}>
                                <MenuDocument doc={doc} />
                            </Box>
                        </Fragment>
                    ))}
                </Stack>
            </Box>
        </Stack>
    );
}
