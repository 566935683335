import useBundleTranslation from 'i18n';
import React, { useEffect, useState } from 'react';
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { useQuery } from '@tanstack/react-query';
import { editorAPI } from 'api/editor';
import { FormComponentValue, prepareFormComponentValues } from 'components/common/form/layout/control';
import { Button, FormControl, FormLabel, Grid } from '@mui/material';
import { Popup } from 'components/common/popup/Popup';
import { TemplateFormComponent } from 'components/common/form/layout/ReactHookFormController';
import { FormControlDataFetchCommandProps } from 'components/common/form/DataFetchCommand';
import { instance } from 'api/api';
import { APIResponse } from 'tools/types';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import IconMi from 'components/common/icon/IconMi';

export default function DataFetchCommandDelete({
    controlProps,
    afterDeleteValues,
    segmentId,
    getDefaultsUrl,
    checkBeforeDeleteUrl,
    deleteUrl,
}: {
    controlProps: FormControlDataFetchCommandProps;
    afterDeleteValues: (response: any) => void;
    segmentId: number;
    getDefaultsUrl: string;
    checkBeforeDeleteUrl: string;
    deleteUrl: string;
}) {
    const { t } = useBundleTranslation(['components/common/form/data_fetch_command']);
    const [showDeleteDataPopup, setShowDeleteDataPopup] = useState(false);

    const deleteExistingValuesText = ('metric' === controlProps.type ? 'metric_' : '') + 'delete_existing_values';
    let rangeLabel = 'values';
    if (controlProps.type == 'metric') {
        rangeLabel = 'metric_values';
    } else if (controlProps.type == 'calendar') {
        rangeLabel = 'events';
    }

    // Form
    const formApi = useCustomSimplifiedForm({
        delete_all: 'Y',
        segment_value_id: -1,
    });
    const segmentValueId = formApi.form.hookFormWatch('segment_value_id');

    // Delete Defaults
    const { data: formDefaults, isLoading: isLoadingFormDefaults } = useQuery<{ start: string; end: string }, Error>({
        queryKey: [getDefaultsUrl, segmentValueId, showDeleteDataPopup],
        queryFn: () => {
            if (!showDeleteDataPopup) {
                return {
                    start: '',
                    end: '',
                };
            }
            return instance
                .get<
                    APIResponse<{
                        start: string;
                        end: string;
                    }>
                >(getDefaultsUrl, { params: { sv_id: segmentValueId } })
                .then((response) => {
                    if (response.data.status == 'OK') {
                        return response.data.data;
                    }
                    return {
                        start: '',
                        end: '',
                    };
                });
        },
    });
    useEffect(() => {
        if (!formDefaults) {
            return;
        }
        if (formDefaults.start != '') {
            formApi.form.hookFormSetValue('start_date', formDefaults.start);
        }
        if (formDefaults.end != '') {
            formApi.form.hookFormSetValue('end_date', formDefaults.end);
        }
    }, [formDefaults]);

    // Segment Values
    const { data: segmentValuesListRaw } = useQuery<Array<any>, Error>({
        queryKey: ['getSegmentValues', segmentId],
        queryFn: () => (segmentId > 0 ? editorAPI.getSegmentValuesList(segmentId) : []),
    });
    const [segmentValues, setSegmentValues] = useState<Array<FormComponentValue>>([]);
    useEffect(() => {
        const result = prepareFormComponentValues(segmentValuesListRaw);
        result.unshift({ label: t('all_values'), value: '-1' });
        setSegmentValues(result);
    }, [segmentValuesListRaw]);

    const removeAllFlag = formApi.form.hookFormWatch('delete_all') == 'Y';

    if (!['metric', 'calendar'].includes(controlProps.type)) {
        return null;
    }

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const checkBeforeDelete = (): Promise<number> => {
        setIsLoading(true);
        return instance
            .post<APIResponse<{ values: number }>>(checkBeforeDeleteUrl, formApi.form.hookFormGetValues())
            .then((response) => {
                if (response.data.status == 'OK') {
                    return response.data.data.values;
                }
                return 0;
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const runDeleteRequest = (): Promise<any> => {
        setIsLoading(true);
        return instance
            .post<APIResponse<{ values: number }>>(deleteUrl, formApi.form.hookFormGetValues())
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleDeleteClick = async () => {
        const amount = await checkBeforeDelete();
        if (amount == 0) {
            alert(t('nothing_to_delete'));
            return;
        }
        if (!confirm(t('click_to_delete', { count: amount, entity: 'Event' + (amount > 1 ? 's' : '') }))) {
            return false;
        }

        setShowDeleteDataPopup(false);
        runDeleteRequest().then((response) => afterDeleteValues(response));
    };

    return (
        <>
            <Button
                data-test={'data-fetch-command-delete-values'}
                onClick={() => setShowDeleteDataPopup(true)}
                variant={'outlined'}
                sx={{ ml: 2 }}
                startIcon={<IconMi icon={'delete'} />}
            >
                {t(deleteExistingValuesText)}
            </Button>
            {!showDeleteDataPopup && (
                <LoadingPlaceholder
                    dataTest={'data-fetch-command-loading-placeholder'}
                    open={isLoadingFormDefaults || isLoading}
                />
            )}
            {showDeleteDataPopup && (
                <Popup
                    settings={{ title: t('delete_data'), textOK: t('delete') }}
                    open={showDeleteDataPopup}
                    onHide={() => setShowDeleteDataPopup(false)}
                    onConfirm={handleDeleteClick}
                    maxWidth={'popupSm'}
                >
                    <LoadingPlaceholder
                        dataTest={'data-fetch-command-loading-placeholder'}
                        open={isLoadingFormDefaults || isLoading}
                    />
                    <Grid item xs={12}>
                        <TemplateFormComponent component={'FormCheckbox'} elementProps={formApi} name={'delete_all'} />
                    </Grid>
                    {!removeAllFlag && (
                        <>
                            {segmentId > 0 && (
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <FormLabel>
                                            {t('dimension')} {t('value')}
                                        </FormLabel>
                                        <TemplateFormComponent
                                            componentValues={segmentValues}
                                            component={'FormSelect'}
                                            elementProps={formApi}
                                            name={'segment_value_id'}
                                        />
                                    </FormControl>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel>{`${t('delete')} ${t(rangeLabel)} ${t('from')}`}</FormLabel>
                                    <TemplateFormComponent
                                        component={'FormDatePicker'}
                                        elementProps={formApi}
                                        name={'start_date'}
                                        props={{ showTimeSelect: true }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel>{`${t('delete')} ${t(rangeLabel)} ${t('through')}`}</FormLabel>
                                    <TemplateFormComponent
                                        component={'FormDatePicker'}
                                        elementProps={formApi}
                                        name={'end_date'}
                                        props={{ showTimeSelect: true }}
                                    />
                                </FormControl>
                            </Grid>
                        </>
                    )}
                </Popup>
            )}
        </>
    );
}
