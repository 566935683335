import Components, { ComponentKey } from 'components/common/ComponentIndex';
import {
    FormComponentValue,
    FormControlProps,
    FormControlRadioProps,
    FormControlDataSourceProps,
    FormControlSwitchProps,
    FormControlTinyMCEProps,
    FormControlTagsProps,
    FormControlSliderProps,
    FormControlDateRangeProps,
    FormControlTimeFieldProps,
    FormControlTimeSelectFieldProps,
    FormControlDateSelectFieldProps,
    FormControlCommonLazyLoad,
    FormControlURLFieldProps,
    FormControlButtonProps,
    FormControlPeriodFieldProps,
    FormControlCompareFieldProps,
    FormControlCompareInputFieldProps,
    FormControlUniquenessTextProps,
    FormControlMigrationCheckboxProps,
    FormControlToggleButtonGroupProps,
} from './layout/control';
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { ReferenceExternalFiltersManagerBuilder } from 'components/external-reference/editor/ReferenceExternalFiltersManager';
import { Params as UrlParams } from 'react-router';
import { FormControlSelectMapProps } from 'components/common/select-map';
import { FormGenericYNControlProps } from 'components/common/form/layout/control/FormGenericYNControl';
import { FormControlSelectMapGridProps } from 'components/common/select-map-grid';
import { FormControlIndexManagerProps } from 'app/editor/dataset/IndexManager';
import { FormControlCSVUploaderProps } from 'app/editor/dataset/CSVUploader';
import { FormControlCommandDetailsDownloadProps } from 'app/editor/dataset/CommandDetailsDownload';
import { FormElementProps } from './index';
import { FormControlGridProps } from 'components/common/form/FormGridComponent';
import { FormControlShowDataProps } from 'components/common/form/data-fetch-command/ShowData';
import { ElementViewerInfoProps } from 'components/common/element-info/ElementInfo';
import { FormControlPublicImagePreviewProps } from 'components/element-image/public-image-preview';
import { ElementImageControlBuilder } from 'components/element-image';
import { FormControlLogoImageProps } from 'components/branding';
import { UserSelectionControlBuilder } from 'components/common/user-selection';
import { FormControlUploadFileProps } from 'components/common/upload-control/UploadFile';
import { FormControlUploadImageProps } from 'components/common/upload-control/UploadImage';
import { FormControlStateButtonProps } from './layout/control/FormStateButton';
import { FormControlColorPickerProps } from 'components/common/color-picker/ColorPicker';
import { FormTermsControlProps } from 'components/common/terms-control';
import { FormControlUserActionLinksProps } from 'app/editor/user/ActionLinks';
import { FormControlFolderContentProps } from 'app/editor/folder/FolderContent';
import { FormBurstControlProps } from 'app/notification/burst/BurstControl';
import { FormEmbedCodePopupProps } from 'app/notification/embed-code-popup';
import { FormBurstSendToGroupControlProps } from 'app/notification/send-now/SendToGroup';
import { FormControlMobilePreferenceProps } from 'app/preferences';
import { FormControlNotificationFrequencyProps } from 'app/distribution/notification-schedules/NotificationFrequency';
import { FormControlImageListHandlerProps } from 'components/common/image-list-handler/ImageListHandler';
import { FormControlSendToList } from 'components/common/send-to-list';
import { FormControlGlobalVariablesProps, FormControlSyncWithGitProps } from 'app/editor/page-template/index';
import { FormControlAssetsTreeProps } from 'app/editor/page-template/AssetsTree';
import { FormControlPageVariablesProps } from 'app/editor/page/variables';
import { FormControlPipelineListProps } from 'app/monitor/pipeline/PipelineList';
import { FormControlFoldersTreeProps } from 'app/content/index';
import { FormControlReportContentEditorProps } from 'app/editor/report';
import { FormControlUserAlertProps } from 'app/editor/user-alerts';
import { FormControlChangeTemplateProps } from 'app/editor/page';
import { FormControlDropFileUploadProps } from 'app/editor/asset';
import { FormControlAutoSyncProps } from 'app/extreport/AutoSyncInfo';
import { FormControlRuleConditionProps } from 'app/editor/content-workflow/RuleCondition';
import { FormEmailSubjectLineControlProps } from 'app/notification/burst/EmailSubjectLine';
import { FormControlInvolvedFieldsProps } from 'app/editor/content-workflow/InvolvedFields';
import { FormControlSimpleScheduleProps } from 'app/notification/simple-schedule/index';
import { FormControlBurstFolderSelectProps } from 'app/notification/folder-select/BurstFolderSelect';
import { FormControlLineageFormButtonProps } from 'components/lineage/LineageFormButton';
import { FormControlElementSourceProps } from 'app/content-workflow/element-editor/ElementSource';
import { FormControlContentFolderSelectProps } from 'app/content-workflow/element-editor/ContentFolderSelect';
import { ExternalReportCustomUrlControlProps } from 'app/editor/element/extreport/ExternalReportCustomUrl';
import { ExternalContentCustomUrlControlProps } from 'app/editor/element/ext-content/ExternalContentCustomUrl';
import { FilterUsageRefreshButtonBuilder } from 'app/editor/external-filters/FilterUsageRefreshButton';
import { FormBurstSharingAlertProps } from 'app/notification/sharing-alert';
import { FormTokenlessUsersBuilder } from 'app/notification/tokenless-users/TokenlessUsers';
import { FormComponentBuilder } from 'components/common/form/layout';
import { FormSelectBuilder } from 'components/common/form/layout/control/FormSelect';
import { EditorExternalFiltersGridBuilder } from 'app/editor/external-filters/EditorExternalFiltersGrid';
import { FormTransferListBuilder } from 'components/common/transfer-list/TransferList';
import { getPkId } from 'components/common/form/formTools';
import { FormTriggerEventMessagesBuilder } from 'app/editor/trigger/TriggerEventMessages';
import { FormEmailTemplateCustomizationBuilder } from 'app/admin/email-template/EmailTemplateCustomization';
import { FormCodeEditorBuilder } from 'components/common/code-editor/FormCodeEditorBuilder';
import { CheckBoxListBuilder } from 'components/common/check-box-list/CheckBoxList';
import { MetricResetForAllUsersBuilder } from 'app/editor/element/metric/MetricResetForAllUsers';
import { FormCheckboxBuilder } from 'components/common/form/layout/control/FormCheckbox';
import { FormTextManagerBuilder } from 'components/common/form/layout/control/FormText';
import { DisplayMaskExampleBuilder } from 'components/common/display-mask-example/DisplayMaskExample';
import { FormKpiRuleInfoBuilder } from 'app/editor/kpi-alert-rule/KpiRuleInfo';
import { DataFetchCommandManagerBuilder } from 'components/common/form/DataFetchCommand';
import { RunCustomScriptBuilder } from 'app/admin/custom-script/RunCustomScript';
import { FolderSelectionBuilder } from 'app/editor/element/FolderSelection';
import { FormTemplateFieldBuilder } from 'components/common/form/layout/control/FormTemplateField';
import { PluginGetTokenBuilder } from 'components/common/plugin_get_token/PluginGetToken';
import { DatasetDeleteDataBuilder } from 'components/common/dataset-delete-data/DatasetDeleteData';
import { FormKpiRuleAssignWorkflowLinkBuilder } from 'app/editor/kpi-alert-rule/KpiRuleAssignWorkflowLink';
import { DatasetDataStorageBuilder } from 'components/common/dataset-data-storage/DatasetDataStorage';
import { FormKpiRuleUserMapAccessBuilder } from 'app/editor/kpi-alert-rule/KpiRuleUserMapAccess';
import { VersionIdentifierBuilder } from 'app/editor/page/VersionIdentifier';
import { CertificationControlBuilder } from 'app/editor/element/CertificationControl';
import { SyncNowFormButtonBuilder } from 'app/editor/segment/SyncNowFormButton';
import { EditorTestUrlBuilder } from 'components/common/editor-test-url/EditorTestUrl';
import { FormTriggerCompletedBuilder } from 'app/notification/trigger-completed';
import { ReferenceSelectionBuilder } from 'components/external-reference-selection/ReferenceSelection';
import { MyNotificationInfoBuilder } from 'app/notification/MyNotificationInfo';
import { UncommittedChangesAlertBuilder } from 'app/admin/system-variables/UncommittedChangesAlert';
import { SystemNotificationInfoBuilder } from 'app/notification/SystemNotificationInfo';
import { FormBurstStatusBuilder } from 'components/common/form/layout/control/FormBurstStatus';
import { FormThemeExampleBuilder } from 'app/editor/brand-theme/theme-example/ThemeExample';
import { FormCollectionTriggerBuilder } from 'app/content-workflow/element-editor/CollectionTrigger';
import { FormDescriptionManagerBuilder } from 'components/common/form/layout/control/FormDescription';
import { DatasetBackFillBuilder } from 'components/common/dataset-data-storage/DatasetBackFill';
import { JDBCControlBuilder } from 'components/storage/JDBCControl';
import { FormImagePreviewBuilder } from './layout/control/FormImagePreview';
import { ExternalReportTableauNameBuilder } from 'app/editor/element/extreport/ExternalReportTableauName';
import { FormComponentSlotBuilder } from 'components/common/form/layout/control/FormComponentSlot';
import { FormDatePickerBuilder } from 'components/common/form/layout/control/FormDatePicker';
import { FormThemeUsagePreviewBuilder } from 'app/editor/brand-theme/ThemeUsagePreview';
import { FilterDateDefaultBuilder } from 'app/editor/element/extreport/FilterDateDefault';
import { FormSecureJsonEditorBuilder } from 'components/common/secure-json-editor/FormSecureJsonEditorBuilder';
import { FormRichTextBuilder } from './layout/control/FormRichText';
import { InternalNameBuilder } from 'app/editor/page/InternalName';
import { SelectMapBuilder } from 'components/common/select-map/SelectMap';
import { FormControlPageEntityFsAccessProps } from 'app/editor/page/entity/FsAccess';

export const FormComponentsBuilderMap: {
    [key in ComponentKey]: typeof FormComponentBuilder | undefined;
} = {
    AddCompareLineList: undefined,
    AddOverlayList: undefined,
    ApplyButton: undefined,
    AssetsTree: undefined,
    AutoSyncInfo: undefined,
    BrandingLogoControl: undefined,
    BurstControl: undefined,
    BurstSharingAlert: undefined,
    BurstFolderSelect: undefined,
    ButtonEditWithAdd: undefined,
    CustomFieldEdit: undefined,
    CSVUploader: undefined,
    ChangeTemplate: undefined,
    CommandDetailsDownload: undefined,
    CheckBoxList: CheckBoxListBuilder,
    ChooseIcon: undefined,
    CodeEditor: FormCodeEditorBuilder,
    SecureJsonEditor: FormSecureJsonEditorBuilder,
    ColorPicker: undefined,
    DataFetchCommand: DataFetchCommandManagerBuilder,
    ShowData: undefined,
    DropFileUpload: undefined,
    EditorExternalFiltersGrid: EditorExternalFiltersGridBuilder,
    ElementEditorFeedback: undefined,
    ElementImageControl: ElementImageControlBuilder,
    ElementInfo: undefined,
    EmailSubjectLine: undefined,
    EmbedCodePopup: undefined,
    ExternalReportCustomUrl: undefined,
    ExternalContentCustomUrl: undefined,
    FilterUsageRefreshButton: FilterUsageRefreshButtonBuilder,
    FolderContent: undefined,
    FoldersTree: undefined,
    FormButton: undefined,
    FormCheckbox: FormCheckboxBuilder,
    FormCompareField: undefined,
    FormCompareFieldBetween: undefined,
    FormCompareInput: undefined,
    FormComponent: undefined,
    FormDataSource: undefined,
    FormDatePicker: FormDatePickerBuilder,
    FormDateRange: undefined,
    FormDateSelect: undefined,
    FormGenericYNControl: undefined,
    FormHidden: undefined,
    FormPanelButtonAdd: undefined,
    FormPanelButtonDelete: undefined,
    FormPanelButtonDownload: undefined,
    FormPanelButtonDuplicate: undefined,
    FormPanelButtonExample: undefined,
    FormPanelButtonSave: undefined,
    FormPanelButtonEnableSave: undefined,
    FormPanelButtonSearch: undefined,
    FormPanelButtonText: undefined,
    FormPanelButtonMore: undefined,
    FormPanelButtonCollectData: undefined,
    FormPanelButtonBuildReport: undefined,
    FormPanelButtonTriggerControls: undefined,
    FormPanelButtonPreviewChanges: undefined,
    FormPanelButtonUpdateIndexes: undefined,
    FormPanelGenericButton: undefined,
    FormPanelSendTestEmailButton: undefined,
    FormPanelButtonUpdateLiveChart: undefined,
    FormPanelButtonBurstPreviewAndEnable: undefined,
    FormPanelButtonLineage: undefined,
    FormPanelButtonHtmlPreview: undefined,
    FormPanelButtonLoadFromFile: undefined,
    FormPanelButtonComponent: undefined,
    FormPanelButtonKpiSendTest: undefined,
    FormPanelButtonScheduleSendNow: undefined,
    FormPeriodField: undefined,
    FormRadioGroup: undefined,
    FormSelect: FormSelectBuilder,
    FormSlider: undefined,
    FormStateButton: undefined,
    FormStaticCodeBlock: undefined,
    FormStaticDivider: undefined,
    FormStaticIconLabel: undefined,
    FormStaticInfo: undefined,
    FormStaticLabel: undefined,
    FormStaticLink: undefined,
    FormSwitch: undefined,
    FormTags: undefined,
    FormText: FormTextManagerBuilder,
    FormRichText: FormRichTextBuilder,
    FormComponentSlot: FormComponentSlotBuilder,
    FormDescription: FormDescriptionManagerBuilder,
    FormTextarea: FormTextManagerBuilder,
    FormTimeField: undefined,
    FormTimeSelect: undefined,
    FormTinyMCE: undefined,
    FormToggleButtonGroup: undefined,
    FormTransferList: FormTransferListBuilder,
    FormEmailTemplateCustomization: FormEmailTemplateCustomizationBuilder,
    FormUniquenessText: undefined,
    FormTemplateField: FormTemplateFieldBuilder,
    FormUrlField: undefined,
    FormWrapperElementLabel: undefined,
    FormWrapperEmpty: undefined,
    FormWrapperGroup: undefined,
    FormWrapperRow: undefined,
    GlobalVariables: undefined,
    GridComponent: undefined,
    ImageListHandler: undefined,
    ImportErrorStack: undefined,
    IndexManager: undefined,
    InvolvedFields: undefined,
    JDBCControl: JDBCControlBuilder,
    MobilePreference: undefined,
    MoveTopics: undefined,
    NotificationFrequency: undefined,
    PageVariables: undefined,
    PageEntityFsAccess: undefined,
    PipelineList: undefined,
    PublicImagePreview: undefined,
    RatingEngagement: undefined,
    ReferenceExternalFiltersManager: ReferenceExternalFiltersManagerBuilder,
    ReferenceSelection: ReferenceSelectionBuilder,
    ReplaceTopics: undefined,
    ChangeVisibility: undefined,
    AddNewAlertPopup: undefined,
    AlertGridDimensionPopup: undefined,
    AddEntityExportGridPopup: undefined,
    ChangeVisibilityPopup: undefined,
    LoadFromFilePopup: undefined,
    SyncContentPopup: undefined,
    CreateDatasource: undefined,
    NewElementForm: undefined,
    BulkChangePopup: undefined,
    UnpublishTablesPopup: undefined,
    UserMassDeletePopup: undefined,
    ImportUsersPopup: undefined,
    AssociateRule: undefined,
    CreateDatasourceFormButton: undefined,
    ReportContentEditor: undefined,
    RuleCondition: undefined,
    SelectMap: SelectMapBuilder,
    SelectMapGrid: undefined,
    SendToGroup: undefined,
    SendToList: undefined,
    SimpleSchedule: undefined,
    StaticInfo: undefined,
    SyncWithGit: undefined,
    SystemStatus: undefined,
    TermsControl: undefined,
    UploadFile: undefined,
    UploadImage: undefined,
    ProfilePicture: undefined,
    UserActionLinks: undefined,
    UserAlert: undefined,
    VariablesGrid: undefined,
    FormCheckboxMigration: undefined,
    ContentFolderSelect: undefined,
    ElementSource: undefined,
    LineageFormButton: undefined,
    TokenlessUsers: FormTokenlessUsersBuilder,
    FormTriggerEventMessages: FormTriggerEventMessagesBuilder,
    FormPanelExternalReportViewButton: undefined,
    FormPanelMetricViewButton: undefined,
    FormPanelExternalContentViewButton: undefined,
    MetricResetForAllUsers: MetricResetForAllUsersBuilder,
    DisplayMaskExample: DisplayMaskExampleBuilder,
    KpiRuleInfo: FormKpiRuleInfoBuilder,
    KpiRuleAssignWorkflowLink: FormKpiRuleAssignWorkflowLinkBuilder,
    KpiRuleUserMapAccess: FormKpiRuleUserMapAccessBuilder,
    ExportResultPopup: undefined,
    CreateTemplatePopup: undefined,
    CodeEditorHistoryPopup: undefined,
    PreviewFavorite: undefined,
    RunCustomScript: RunCustomScriptBuilder,
    FolderSelection: FolderSelectionBuilder,
    PluginGetToken: PluginGetTokenBuilder,
    EditorTestUrl: EditorTestUrlBuilder,
    DatasetDeleteData: DatasetDeleteDataBuilder,
    SendBurstNow: undefined,
    DatasetDataStorage: DatasetDataStorageBuilder,
    DatasetBackFill: DatasetBackFillBuilder,
    VersionIdentifier: VersionIdentifierBuilder,
    InternalName: InternalNameBuilder,
    CertificationControl: CertificationControlBuilder,
    UserSelection: UserSelectionControlBuilder,
    SegmentSyncButton: SyncNowFormButtonBuilder,
    TriggerCompleted: FormTriggerCompletedBuilder,
    MyNotificationInfo: MyNotificationInfoBuilder,
    UncommittedChangesAlert: UncommittedChangesAlertBuilder,
    SystemNotificationInfo: SystemNotificationInfoBuilder,
    FormBurstStatus: FormBurstStatusBuilder,
    ThemeExample: FormThemeExampleBuilder,
    ThemeUsagePreview: FormThemeUsagePreviewBuilder,
    CollectionTrigger: FormCollectionTriggerBuilder,
    FormImagePreview: FormImagePreviewBuilder,
    ExternalReportTableauName: ExternalReportTableauNameBuilder,
    FormPanelButtonStartExporting: undefined,
    UploadFontPopup: undefined,
    FilterDateDefault: FilterDateDefaultBuilder,
};

export default function buildFormControlProperties(
    elementProps: FormElementProps,
    { onChange, value }: ControllerRenderProps,
    componentValues?: Array<FormComponentValue>,
    afterChange: (event: any) => void = (event: any) => {},
): {
    isValid: boolean;
    controlProps: FormControlProps;
    componentName: any;
} {
    const componentKey = elementProps.component.component;
    const controlPropsObj = {
        uid: elementProps.component.uid,
        form: elementProps.form,
        name: elementProps.component.name,
        label: elementProps.componentProps.label,
        addonLabel: elementProps.componentProps.addonLabel,
        translate: elementProps.componentProps.translate ?? true,
        helperText: elementProps.componentProps.helperText,
        helperTextTranslate: elementProps.componentProps.helperTextTranslate ?? true,
        value: value ?? '',
        onChange: (event) => {
            onChange(event);
            afterChange(event);
        },
        helper: elementProps.component.helper,
        readOnly: elementProps.componentProps.readOnly ?? false,
        // defaultValue: elementProps.data[elementProps.component.name],
        formIgnoreSubmit: elementProps.componentProps.formIgnoreSubmit ?? false,
        tooltip: elementProps.componentProps.tooltip,
        controlClass: elementProps.componentProps.controlClass,
        labelClass: elementProps.componentProps.labelClass,
        width: elementProps.componentProps.width,
    } as FormControlProps;

    let controlProps = { ...controlPropsObj };
    let validComponent = true;
    let componentName = Components[componentKey];

    // TODO:
    const getUploadComponentProps = function () {
        return {
            ...controlPropsObj,
            accept: elementProps.componentProps?.accept,
            view: elementProps.componentProps?.view ?? 'button',
            multiple: elementProps.componentProps?.multiple ?? false,
            maxFileSizeMB: Number(elementProps.componentProps?.maxFileSizeMB ?? 512),
            removeActionText: elementProps.componentProps?.removeActionText ?? 'remove_action_text',
            removeActionView: elementProps.componentProps?.removeActionView ?? 'link',
            removeButtonColor: elementProps.componentProps?.removeButtonColor ?? undefined,
            emptyStateTooltip: elementProps.componentProps?.emptyStateTooltip ?? undefined,
            showRemoveAction: elementProps.componentProps?.showRemoveAction ?? undefined,
            buttonTextFilled: elementProps.componentProps?.buttonTextFilled ?? undefined,
            buttonText: elementProps.componentProps?.buttonText ?? undefined,
            inputPlaceholder: elementProps.componentProps?.inputPlaceholder ?? undefined,
            buttonStartIcon: elementProps.componentProps?.buttonStartIcon ?? undefined,
            buttonFilledStartIcon: elementProps.componentProps?.buttonFilledStartIcon ?? undefined,
            previewsList: elementProps.componentProps?.previewsList ?? [],
            previewsPlaceholder: elementProps.componentProps?.previewsPlaceholder ?? undefined,
            sx: elementProps.componentProps?.sx ?? undefined,
            id: elementProps.componentProps?.id ?? undefined,
            ignoreStateField: elementProps.componentProps?.ignoreStateField ?? undefined,
            onChange: (event: any) => {
                const result = onChange(event.target?.files);
                afterChange(event.target?.files);
                return result;
            },
            ajaxApiUrl: elementProps.componentProps?.ajaxApiUrl ?? '',
            ajaxApiRemoveUrl: elementProps.componentProps?.ajaxApiRemoveUrl ?? '',
            onFilesChange: elementProps.componentProps?.onFilesChange ?? undefined,
        };
    };

    const className = FormComponentsBuilderMap[componentKey];
    if (className != undefined) {
        const builder = new className(elementProps, controlProps, componentValues);
        return {
            isValid: true,
            controlProps: builder.prepareProps(value, onChange, afterChange),
            componentName: componentName,
        };
    }

    switch (componentKey) {
        case 'SystemStatus':
        case 'FormHidden':
        case 'ChooseIcon':
        case 'ImportErrorStack':
            controlProps = { ...controlPropsObj };
            break;
        case 'GridComponent':
            controlProps = {
                ...controlPropsObj,
                urlParams: {
                    ...elementProps.urlParams,
                    ...elementProps.componentProps?.urlParams,
                } as UrlParams,
                lazy: elementProps.componentProps?.lazy ?? true,
                elementProps: elementProps,
                formGrid: true,
                setFormDirty: elementProps.componentProps?.setFormDirty ?? false,
                relatedFields: elementProps.componentProps?.relatedFields ?? [],
                removeHeaderIndent: elementProps.componentProps?.removeHeaderIndent ?? false,
                gridDescription: elementProps.componentProps?.gridDescription,
                jsonStore: elementProps.componentProps?.jsonStore,
            } as FormControlGridProps;
            componentName = require('components/common/form/FormGridComponent').default;
            break;
        case 'FormUniquenessText':
            controlProps = {
                ...controlPropsObj,
                urlParams: {
                    ...elementProps.urlParams,
                    ...elementProps.componentProps?.urlParams,
                } as UrlParams,
                checkUrl: elementProps.componentProps?.checkUrl ?? '',
                placeholder: elementProps.componentProps?.placeholder ?? '',
                rows: elementProps.componentProps?.rows ?? 4,
                asLabel: elementProps.componentProps?.asLabel ?? false,
                maxLength: elementProps.componentProps?.maxLength,
                maxWidth: elementProps.componentProps?.maxWidth,
                disabled: elementProps.componentProps?.disabled ?? false,
                fieldType: elementProps.componentProps?.fieldType ?? 'text',
            } as FormControlUniquenessTextProps;
            break;
        case 'FormUrlField':
            controlProps = {
                ...controlPropsObj,
                placeholder: elementProps.componentProps?.placeholder ?? '',
            } as FormControlURLFieldProps;
            break;
        case 'FormStateButton':
            controlProps = {
                ...controlPropsObj,
                defaultValue: elementProps.componentProps?.activeState ? 'Y' : 'N',
                defaultText: elementProps.componentProps?.defaultText ?? 'Show',
                defaultIcon: elementProps.componentProps?.defaultIcon,
                activeText: elementProps.componentProps?.activeText ?? 'Hide',
                activeIcon: elementProps.componentProps?.activeIcon,
                activeState: elementProps.componentProps?.activeState ?? false,
                dirtyIgnore: elementProps.componentProps?.dirtyIgnore ?? true,
            } as FormControlStateButtonProps;
            break;
        case 'FormCheckboxMigration':
            controlProps = {
                ...controlPropsObj,
                onChange: (value: any, checked: any) => {
                    const v = elementProps.componentProps?.asBoolean ? checked : checked ? 'Y' : 'N';
                    onChange(v);
                    afterChange(v);
                },
                type: elementProps.componentProps?.type ?? '',
                checked: elementProps.componentProps?.asBoolean ? value : value == 'Y',
                disabled: elementProps.componentProps?.disabled ?? false,
                lastExported: elementProps.componentProps?.lastExported ?? '',
            } as FormControlMigrationCheckboxProps;
            break;
        case 'FormGenericYNControl':
            controlProps = {
                ...controlPropsObj,
                data: componentValues,
            } as FormGenericYNControlProps;
            break;
        case 'FormTimeField':
            controlProps = {
                ...controlPropsObj,
                extraText: elementProps.componentProps?.extraText ?? '',
            } as FormControlTimeFieldProps;
            break;
        case 'FormCompareField':
        case 'FormCompareFieldBetween':
            controlProps = {
                ...controlPropsObj,
                data: componentValues,
                type: elementProps.componentProps?.type ?? '',
                variant: elementProps.componentProps?.variant ?? 'danger',
                extraText: elementProps.componentProps?.extraText ?? '',
                rangeEndName: elementProps.componentProps?.rangeEndName ?? '',
                rangeStartName: elementProps.componentProps?.rangeStartName ?? '',
                betweenLabel: elementProps.componentProps?.betweenLabel ?? '',
                extraTextBadge: elementProps.componentProps?.extraTextBadge ?? '',
                color: elementProps.componentProps?.color ?? false,
                urlParams: {
                    ...elementProps.urlParams,
                    ...elementProps.componentProps?.urlParams,
                } as UrlParams,
            } as FormControlCompareFieldProps;
            break;
        case 'FormCompareInput':
            controlProps = {
                ...controlPropsObj,
                extraText: elementProps.componentProps?.extraText ?? '',
                unitFieldName: elementProps.componentProps?.unitFieldName ?? '',
                periodFieldName: elementProps.componentProps?.periodFieldName ?? '',
            } as FormControlCompareInputFieldProps;
            break;
        case 'FormDateRange':
            controlProps = {
                ...controlPropsObj,
                selectedDates: value ?? '',
                defaultValue: elementProps.componentProps?.defaultValue ?? [],
            } as FormControlDateRangeProps;
            break;
        case 'FormTimeSelect':
            controlProps = {
                ...controlPropsObj,
                multiple: elementProps.componentProps?.multiple ?? false,
                hoursValue: elementProps.componentProps?.hoursValue ?? '',
                minutesValue: elementProps.componentProps?.minutesValue ?? '',
                hoursFieldName: elementProps.componentProps?.hours_field_name ?? '',
                minutesFieldName: elementProps.componentProps?.minutes_field_name ?? '',
            } as FormControlTimeSelectFieldProps;
            break;
        case 'FormPeriodField':
            controlProps = {
                ...controlPropsObj,
                data: componentValues,
                unitFieldName: elementProps.componentProps?.unitFieldName ?? '',
                periodFieldName: elementProps.componentProps?.periodFieldName ?? '',
            } as FormControlPeriodFieldProps;
            break;
        case 'FormDateSelect':
            controlProps = {
                ...controlPropsObj,
                multiple: elementProps.componentProps?.multiple ?? false,
                fiscal: elementProps.componentProps?.fiscal ?? false,
                selectedDates: elementProps.componentProps?.selectedDates ?? [],
            } as FormControlDateSelectFieldProps;
            break;
        case 'FormSwitch':
            const { disabled = false, checkedValue = 'Y', uncheckedValue = 'N' } = elementProps.componentProps;
            const defaultValue = value > '' ? value : uncheckedValue || checkedValue;
            const reversed = elementProps.componentProps?.reversed ?? false;
            controlProps = {
                ...controlPropsObj,
                disabled,
                checked: defaultValue === (reversed ? uncheckedValue : checkedValue),
                onChange: (_, checked: boolean) => {
                    if (reversed) {
                        onChange(checked ? uncheckedValue : checkedValue);
                        afterChange(checked ? uncheckedValue : checkedValue);
                    } else {
                        onChange(checked ? checkedValue : uncheckedValue);
                        afterChange(checked ? checkedValue : uncheckedValue);
                    }
                },
                onLabel: elementProps.componentProps?.onLabel,
                reversed,
                offLabel: elementProps.componentProps?.offLabel,
            } as FormControlSwitchProps;
            break;
        case 'FormTinyMCE':
            controlProps = {
                ...controlPropsObj,
                disabled: elementProps.componentProps.disabled,
            } as FormControlTinyMCEProps;
            break;
        case 'FormRadioGroup':
            controlProps = {
                ...controlPropsObj,
                data: componentValues,
                isRow: elementProps.componentProps?.row ?? true,
                disabled: elementProps.componentProps?.disabled ?? false,
                defaultValue: elementProps.componentProps?.defaultValue ?? '',
            } as FormControlRadioProps;
            break;
        case 'FormToggleButtonGroup':
            controlProps = {
                ...controlPropsObj,
                data: componentValues,
                isRow: elementProps.componentProps?.row ?? true,
                disabled: elementProps.componentProps?.disabled ?? false,
                fullWidth: elementProps.componentProps?.fullWidth ?? false,
                icons: elementProps.componentProps?.icons,
            } as FormControlToggleButtonGroupProps;
            break;
        case 'FormButton':
            controlProps = {
                ...controlPropsObj,
                urlParams: {
                    ...elementProps.urlParams,
                    ...elementProps.componentProps?.urlParams,
                } as UrlParams,
                url: elementProps.componentProps?.url ?? '',
                icon: elementProps.componentProps?.icon ?? undefined,
                disabled: elementProps.componentProps?.disabled ?? false,
                save: elementProps.componentProps?.save ?? false,
                variant: elementProps.componentProps?.variant ?? 'outlined',
                checkOnDirtyField: elementProps.componentProps?.checkOnDirtyField ?? '',
                action: elementProps.componentProps?.action ?? '',
                popupConfig: elementProps.componentProps?.popupConfig ?? {},
            } as FormControlButtonProps;
            break;
        case 'FormDataSource':
            controlProps = {
                ...controlPropsObj,
                urlParams: {
                    ...elementProps.urlParams,
                    ...elementProps.componentProps?.urlParams,
                } as UrlParams,
                data: componentValues,
                elementProps: elementProps,
                editorType: elementProps.componentProps?.editorType ?? 'dataset',
            } as FormControlDataSourceProps;
            break;
        case 'FormTags':
            controlProps = {
                ...controlPropsObj,
                data: componentValues,
                elementProps: elementProps,
                fetchOnTab: elementProps.componentProps.fetchOnTab ?? '',
                dataUrl: elementProps.componentProps.dataUrl ?? '',
            } as FormControlTagsProps;
            break;
        case 'UploadFile':
            //@ts-ignore
            controlProps = {
                ...getUploadComponentProps(),
                hidePreview: elementProps.componentProps?.hidePreview ?? true,
                previewType: elementProps.componentProps?.previewType ?? 'text',
            } as FormControlUploadFileProps;
            break;
        case 'UploadImage':
            //@ts-ignore
            controlProps = {
                ...getUploadComponentProps(),
                accept: 'image/*',
                hidePreview: elementProps.componentProps?.hidePreview ?? false,
                previewType: elementProps.componentProps?.previewType ?? 'image',
                useBase64Preview: elementProps.componentProps?.useBase64Preview ?? false,
            } as FormControlUploadImageProps;
            break;
        case 'ProfilePicture':
            //@ts-ignore
            controlProps = {
                ...getUploadComponentProps(),
                accept: 'image/*',
                hidePreview: elementProps.componentProps?.hidePreview ?? false,
                previewType: elementProps.componentProps?.previewType ?? 'image',
                useBase64Preview: elementProps.componentProps?.useBase64Preview ?? false,
            } as FormControlUploadImageProps;
            break;
        case 'ColorPicker':
            controlProps = {
                ...controlPropsObj,
                view: elementProps.componentProps?.view ?? 'field',
                defaultValue: elementProps.componentProps?.defaultValue ?? '',
                canReset: elementProps.componentProps?.canReset ?? false,
                buttonText: elementProps.componentProps?.buttonText ?? undefined,
                presetColors: elementProps.componentProps?.presetColors ?? undefined,
                pickerType: elementProps.componentProps?.pickerType ?? 'default',
                icon: elementProps.componentProps?.icon ?? undefined,
                toggleShow: elementProps.componentProps?.toggleShow ?? undefined,
            } as FormControlColorPickerProps;
            break;
        case 'UserActionLinks':
            controlProps = {
                ...controlPropsObj,
            } as FormControlUserActionLinksProps;
            break;
        case 'SelectMapGrid':
            controlProps = {
                ...controlPropsObj,
                async: elementProps.componentProps.async,
                selectMapData: elementProps.componentProps.data,
                settingsUrl: elementProps.componentProps.settingsUrl,
                hiddenColumns: elementProps.componentProps.hiddenColumns,
                urlParams: {
                    ...elementProps.urlParams,
                    ...elementProps.componentProps?.urlParams,
                } as UrlParams,
            } as FormControlSelectMapGridProps;
            break;
        case 'BrandingLogoControl':
            controlProps = {
                ...controlPropsObj,
                imgUrl: elementProps.componentProps?.imgUrl ?? '',
                isCustom: elementProps.componentProps?.isCustom ?? false,
            } as FormControlLogoImageProps;
            break;
        case 'TermsControl':
            controlProps = {
                ...controlPropsObj,
                fetchUrl: elementProps.componentProps?.fetchUrl ?? '',
                fetchOnTab: elementProps.componentProps?.fetchOnTab ?? '',
                renderTags: elementProps.componentProps?.renderTags ?? true,
                renderTopics: elementProps.componentProps?.renderTopics ?? true,
                showHeader: elementProps.componentProps?.showHeader ?? true,
                shortForm: elementProps.componentProps?.shortForm ?? false,
                urlParams: {
                    ...elementProps.urlParams,
                    ...elementProps.componentProps?.urlParams,
                } as UrlParams,
            } as FormTermsControlProps;
            break;
        case 'RatingEngagement':
        case 'ElementEditorFeedback':
            controlProps = {
                ...controlPropsObj,
                fetchOnTab: elementProps.componentProps?.fetchOnTab ?? '',
            } as FormControlCommonLazyLoad;
            break;
        case 'PublicImagePreview':
            controlProps = {
                ...controlPropsObj,
                urlParams: {
                    ...elementProps.urlParams,
                    ...elementProps.componentProps?.urlParams,
                } as UrlParams,
                previewUrl: elementProps.componentProps?.previewUrl ?? '',
                uploadImageUrl: elementProps.componentProps?.uploadImageUrl ?? '',
                saveImgUrl: elementProps.componentProps?.setupImgUrl ?? '',
                thumbnailUrl: elementProps.componentProps?.thumbnailUrl ?? '',
                hasImageUrl: elementProps.componentProps?.hasImageUrl ?? '',
                deleteImageUrl: elementProps.componentProps?.deleteImageUrl ?? '',
                blurSliderName: elementProps.componentProps?.blurSliderName ?? '',
            } as FormControlPublicImagePreviewProps;
            break;
        case 'BurstControl':
            controlProps = {
                ...controlPropsObj,
                urlParams: {
                    ...elementProps.urlParams,
                    ...elementProps.componentProps?.urlParams,
                } as UrlParams,
                data: elementProps.component.componentStartValues,
                fetchUrl: elementProps.componentProps?.fetchUrl ?? '',
                fetchOnTab: elementProps.componentProps?.fetchOnTab ?? '',
            } as FormBurstControlProps;
            break;
        case 'EmbedCodePopup':
            controlProps = {
                ...controlPropsObj,
            } as FormEmbedCodePopupProps;
            break;
        case 'SendToGroup':
            controlProps = {
                ...controlPropsObj,
                urlParams: {
                    ...elementProps.urlParams,
                    ...elementProps.componentProps?.urlParams,
                } as UrlParams,
                userEmail: elementProps.componentProps?.userEmail ?? '',
                burstOptions: elementProps.componentProps?.burstOptions ?? [],
                adminsGridUrl: elementProps.componentProps?.adminsGridUrl ?? '',
                recipientsGridUrl: elementProps.componentProps?.recipientsGridUrl ?? '',
            } as FormBurstSendToGroupControlProps;
            break;
        case 'MobilePreference':
            controlProps = {
                ...controlPropsObj,
                analystData: elementProps.componentProps?.analyst ?? [],
                executiveBriefData: elementProps.componentProps?.executive_brief ?? [],
                connectedDevice: elementProps.componentProps?.connectedDevice ?? '',
                connectedDate: elementProps.componentProps?.connectedDate ?? '',
                connectedTime: elementProps.componentProps?.connectedTime ?? '',
                mobileExecutiveBriefAvailable: elementProps.componentProps?.mobileExecutiveBriefAvailable ?? false,
            } as FormControlMobilePreferenceProps;
            break;
        case 'IndexManager':
            controlProps = {
                ...controlPropsObj,
                pkId: getPkId(elementProps.pk, elementProps.urlParams),
                datasetName: elementProps.data?.name ?? '',
            } as FormControlIndexManagerProps;
            break;
        case 'FolderContent':
            controlProps = {
                ...controlPropsObj,
                pkId: getPkId(elementProps.pk, elementProps.urlParams),
                baseUrl: elementProps.componentProps?.baseUrl ?? '',
                storeTreeCountInForm: elementProps.componentProps?.storeTreeCountInForm ?? false,
            } as FormControlFolderContentProps;
            break;
        case 'NotificationFrequency':
            controlProps = {
                ...controlPropsObj,
                timeSelectLabel: elementProps.componentProps?.timeSelectLabel ?? '',
            } as FormControlNotificationFrequencyProps;
            break;
        case 'CSVUploader':
            controlProps = {
                ...controlPropsObj,
                pkId: getPkId(elementProps.pk, elementProps.urlParams),
                saveHistoricalInstances: elementProps.data?.save_historical_instances_ind == 'Y',
                gridToRefresh: elementProps.componentProps?.gridToRefresh ?? '',
                refreshFields: elementProps.componentProps?.refreshFields ?? [],
                updateFields: elementProps.componentProps?.updateFields ?? [],
                dataPreviewUrl: elementProps.componentProps?.dataPreviewUrl ?? '',
            } as FormControlCSVUploaderProps;
            break;
        case 'CommandDetailsDownload':
            controlProps = {
                ...controlPropsObj,
                urlParams: {
                    ...elementProps.urlParams,
                    ...elementProps.componentProps?.urlParams,
                } as UrlParams,
                downloadUrl: elementProps.componentProps?.downloadUrl ?? '',
            } as FormControlCommandDetailsDownloadProps;
            break;
        case 'ShowData':
            controlProps = {
                ...controlPropsObj,
                pkId: getPkId(elementProps.pk, elementProps.urlParams),
                dataPreviewUrl: elementProps.componentProps.dataPreviewUrl ?? '',
            } as FormControlShowDataProps;
            break;
        case 'ElementInfo':
            controlProps = {
                ...controlPropsObj,
                data: elementProps.data,
                editorType: elementProps.componentProps.editorType ?? '',
            } as ElementViewerInfoProps;
            break;
        case 'FormSlider':
            controlProps = {
                ...controlPropsObj,
                min: elementProps.componentProps?.min,
                max: elementProps.componentProps?.max,
                step: elementProps.componentProps?.step,
                disabled: elementProps.componentProps?.disabled ?? false,
                defaultValue: elementProps.componentProps?.defaultValue ?? '0',
            } as FormControlSliderProps;
            break;
        case 'ImageListHandler':
            controlProps = {
                ...controlPropsObj,
                dataUrl: elementProps.componentProps.dataUrl,
                previewUrl: elementProps.componentProps.previewUrl,
                deleteUrl: elementProps.componentProps.deleteUrl,
                uploadUrl: elementProps.componentProps.uploadUrl,
                uploadFieldKey: elementProps.componentProps.uploadFieldKey,
                translationsKey: elementProps.componentProps.translationsKey,
                linkFileIdParamKey: elementProps.componentProps.linkFileIdParamKey ?? 'fileId',
                dataKeyMap: elementProps.componentProps.dataKeyMap ?? {},
                urlParams: {
                    ...elementProps.urlParams,
                    ...elementProps.componentProps?.urlParams,
                } as UrlParams,
            } as FormControlImageListHandlerProps;
            break;
        case 'SendToList':
            controlProps = {
                ...controlPropsObj,
                settingsUrl: elementProps.componentProps.settingsUrl ?? '',
                dataUrl: elementProps.componentProps.dataUrl ?? false,
                dataPageSize: elementProps.componentProps.dataPageSize ?? 100,
                urlParams: {
                    ...elementProps.urlParams,
                    ...elementProps.componentProps?.urlParams,
                } as UrlParams,
            } as FormControlSendToList;
            break;
        case 'GlobalVariables':
            controlProps = {
                ...controlPropsObj,
            } as FormControlGlobalVariablesProps;
            break;
        case 'VariablesGrid':
            controlProps = {
                ...controlPropsObj,
            } as FormControlGlobalVariablesProps;
            break;
        case 'AssetsTree':
            controlProps = {
                ...controlPropsObj,
                pkId: getPkId(elementProps.pk, elementProps.urlParams),
                baseUrl: elementProps.componentProps?.baseUrl ?? '',
                assetEditUrl: elementProps.componentProps?.assetEditUrl ?? '',
                assetDeleteUrl: elementProps.componentProps?.assetDeleteUrl ?? '',
            } as FormControlAssetsTreeProps;
            break;
        case 'SyncWithGit':
            controlProps = {
                ...controlPropsObj,
                pkId: getPkId(elementProps.pk, elementProps.urlParams),
                baseUrl: elementProps.componentProps?.baseUrl ?? '',
            } as FormControlSyncWithGitProps;
            break;
        case 'PageVariables':
            controlProps = {
                ...controlPropsObj,
                pkId: getPkId(elementProps.pk, elementProps.urlParams),
                uploadAssetUrl: elementProps.componentProps?.uploadAssetUrl ?? '',
                loadSelectDataUrl: elementProps.componentProps?.loadSelectDataUrl ?? '',
                assetDeleteUrl: elementProps.componentProps?.assetDeleteUrl ?? '',
            } as FormControlPageVariablesProps;
            break;
        case 'PageEntityFsAccess':
            controlProps = {
                ...controlPropsObj,
                pkId: getPkId(elementProps.pk, elementProps.urlParams),
            } as FormControlPageEntityFsAccessProps;
            break;
        case 'ReportContentEditor':
            controlProps = {
                ...controlPropsObj,
                isPermittedToEditDataset: elementProps.componentProps?.isPermittedToEditDataset ?? false,
            } as FormControlReportContentEditorProps;

            break;
        case 'PipelineList':
            controlProps = {
                ...controlPropsObj,
            } as FormControlPipelineListProps;

            break;
        case 'FoldersTree':
            controlProps = {
                ...controlPropsObj,
                urlParams: {
                    ...elementProps.urlParams,
                    ...elementProps.componentProps?.urlParams,
                } as UrlParams,
                fetchUrl: elementProps.componentProps?.fetchUrl ?? '',
                importUrl: elementProps.componentProps?.importUrl ?? '',
                fetchOnTab: elementProps.componentProps?.fetchOnTab ?? '',
            } as FormControlFoldersTreeProps;

            break;
        case 'UserAlert':
            controlProps = {
                ...controlPropsObj,
            } as FormControlUserAlertProps;

            break;
        case 'ChangeTemplate':
            controlProps = {
                ...controlPropsObj,
                changeTemplateUrl: elementProps.componentProps?.changeTemplateUrl ?? '',
                getAvailableTemplatesUrl: elementProps.componentProps?.getAvailableTemplatesUrl ?? '',
                templateName: elementProps.componentProps?.templateName ?? '',
                templateId: elementProps.componentProps?.templateId ?? '',
            } as FormControlChangeTemplateProps;

            break;
        case 'DropFileUpload':
            controlProps = {
                ...controlPropsObj,
                gridName: elementProps.componentProps?.gridName ?? '',
                baseUrl: elementProps.componentProps?.baseUrl ?? '',
                parentFolderId: elementProps.componentProps?.parentFolderId ?? 0,
                allowedTypes: elementProps.componentProps?.allowedTypes ?? [],
            } as FormControlDropFileUploadProps;

            break;

        case 'AutoSyncInfo':
            controlProps = {
                ...controlPropsObj,
                name: elementProps.componentProps?.name ?? '',
            } as FormControlAutoSyncProps;

            break;
        case 'RuleCondition':
            controlProps = {
                ...controlPropsObj,
                availableStages: elementProps.componentProps?.availableStages ?? [],
            } as FormControlRuleConditionProps;

            break;
        case 'InvolvedFields':
            controlProps = {
                ...controlPropsObj,
                baseUrl: elementProps.componentProps?.baseUrl ?? '',
            } as FormControlInvolvedFieldsProps;

            break;
        case 'EmailSubjectLine':
            controlProps = {
                ...controlPropsObj,
                urlParams: {
                    ...elementProps.urlParams,
                    ...elementProps.componentProps?.urlParams,
                } as UrlParams,
                fetchUrl: elementProps.componentProps?.fetchUrl ?? '',
            } as FormEmailSubjectLineControlProps;

            break;
        case 'SimpleSchedule':
            controlProps = {
                ...controlPropsObj,
                hideBottomInfo: elementProps.componentProps?.hideBottomInfo ?? false,
            } as FormControlSimpleScheduleProps;
            break;
        case 'ExternalReportCustomUrl':
            controlProps = {
                ...controlPropsObj,
                urlParams: {
                    ...elementProps.urlParams,
                    ...elementProps.componentProps?.urlParams,
                } as UrlParams,
                mode: elementProps.componentProps?.mode ?? '',
                buildUrl: elementProps.componentProps?.buildUrl ?? '',
                plugin: elementProps.componentProps?.plugin ?? '',
                report_type_id: elementProps.componentProps?.report_type_id ?? '',
                report_display: elementProps.componentProps?.report_display ?? '',
                template_name: elementProps.componentProps?.template_name ?? '',
                show_test_url: elementProps.componentProps?.show_test_url ?? true,
            } as ExternalReportCustomUrlControlProps;
            break;
        case 'ExternalContentCustomUrl':
            controlProps = {
                ...controlPropsObj,
                urlParams: {
                    ...elementProps.urlParams,
                    ...elementProps.componentProps?.urlParams,
                } as UrlParams,
                mode: elementProps.componentProps?.mode ?? '',
                buildUrl: elementProps.componentProps?.buildUrl ?? '',
                report_type_id: elementProps.componentProps?.report_type_id ?? '',
                show_test_url: elementProps.componentProps?.show_test_url ?? true,
            } as ExternalContentCustomUrlControlProps;
            break;
        case 'BurstFolderSelect':
            controlProps = {
                ...controlPropsObj,
                pkId: getPkId(elementProps.pk, elementProps.urlParams),
                baseUrl: elementProps.componentProps?.baseUrl ?? '',
            } as FormControlBurstFolderSelectProps;
            break;
        case 'LineageFormButton':
            controlProps = {
                ...controlPropsObj,
                elementId: elementProps.componentProps?.elementId ?? '',
                elementType: elementProps.componentProps?.elementType ?? '',
            } as FormControlLineageFormButtonProps;
            break;
        case 'ElementSource':
            controlProps = {
                ...controlPropsObj,
                elementData: elementProps.componentProps?.elementData,
                sourceData: elementProps.componentProps?.sourceData,
            } as FormControlElementSourceProps;
            break;
        case 'ContentFolderSelect':
            controlProps = {
                ...controlPropsObj,
                treeData: elementProps.componentProps?.treeData,
            } as FormControlContentFolderSelectProps;
            break;
        case 'BurstSharingAlert':
            controlProps = {
                ...controlPropsObj,
                urlParams: {
                    ...elementProps.urlParams,
                    ...elementProps.componentProps?.urlParams,
                } as UrlParams,
                fetchUrl: elementProps.componentProps?.fetchUrl ?? '',
            } as FormBurstSharingAlertProps;
            break;
        default:
            validComponent = false;
    }
    return {
        isValid: validComponent,
        controlProps: controlProps,
        componentName: componentName,
    };
}
