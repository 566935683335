import React, { useEffect, useState } from 'react';
import { GridData, RowExtraInfoConfig } from '../index';
import { Box, Stack, Tooltip } from '@mui/material';
import useBundleTranslation from 'i18n';

export const useGridExtraInfo = (gridData: GridData, rowExtraInfoConfig?: RowExtraInfoConfig) => {
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
    const { t } = useBundleTranslation(['components/common/grid/grid']);

    if (!rowExtraInfoConfig) {
        return {};
    }

    useEffect(() => {
        const itemKeys = gridData
            .filter((row: any) => {
                return row[rowExtraInfoConfig.messageColumn] > '';
            })
            .map((row: any) => row[rowExtraInfoConfig.idColumn]);

        setExpandedRowKeys(itemKeys);
    }, [gridData]);

    const renderRowExpanded = (rowData: any) => {
        const initErrorMessage: string = rowData[rowExtraInfoConfig.messageColumn];

        const showMore = initErrorMessage.length > 110;

        return (
            <Stack
                direction={'row'}
                sx={{ height: '100%', padding: '4px' }}
                justifyContent={'space-between'}
                alignItems={'flex-start'}
            >
                <Box
                    dangerouslySetInnerHTML={{ __html: initErrorMessage }}
                    sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                />
                {showMore && (
                    <Tooltip
                        title={
                            <Box
                                style={{ color: 'error.main' }}
                                dangerouslySetInnerHTML={{ __html: initErrorMessage }}
                            />
                        }
                        placement={'right-start'}
                        arrow
                    >
                        <Box sx={{ color: 'primary.main', minWidth: '80px' }}>{t('show_more')}</Box>
                    </Tooltip>
                )}
            </Stack>
        );
    };

    const extraInfoConfig = {
        rowExpandedHeight: rowExtraInfoConfig.rowHeight ?? 24,
        expandedRowKeys: expandedRowKeys,
        renderRowExpanded: renderRowExpanded,
        rowKey: rowExtraInfoConfig.idColumn,
    };

    return { extraInfoConfig };
};
