import {
    FormComponentValue,
    FormControlProps,
    FormElementControlPropsType,
    prepareFormComponentValues,
} from 'components/common/form/layout/control';
import { Params as UrlParams } from '@remix-run/router/dist/utils';
import MITable from 'components/common/grid/MITable';
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { gridAPI } from 'api/grid';
import { ColumnType, CustomCellProps, GridData, GridDataRow } from 'components/common/grid';
import { RawFormComponentType } from 'components/common/form';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import update from 'immutability-helper';
import { editorUserMapAPI } from 'api/editor/user-map';
import ReactSelect from 'components/common/react-select/ReactSelect';
import useBundleTranslation from 'i18n';
import { FormComponentBuilder } from 'components/common/form/layout';
import { AssocArray, YNValues } from 'tools/types';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import { getElementFieldValue, prepareFormElementProps, processSettingsUrl } from 'components/common/form/formTools';
import { Box, FormGroup, FormLabel, Stack } from '@mui/material';

type SegmentSelection = 'none' | 'compound' | 'simple';

const columnsInStandardMode = ['value_set', 'edit'];
const columnsInUserMapMode = ['user_map_column_select', 'edit_user_map'];

export interface EditorExternalFiltersGridControlProps extends FormControlProps {
    urlParams: UrlParams;
    gridUrl: string;
    reorderUrl: string;
    segmentSelection: SegmentSelection;
    userMapId: string;
    usageMethod: 'standard' | 'user map';
}

interface FieldColumnRecord {
    filterColumn: string;
    mapColumn: string;
}

function UserMapColumnSelect({
    row,
    fieldColumnMap,
    userMapAllowedValues,
    onChange,
}: {
    row: GridDataRow;
    fieldColumnMap: Array<FieldColumnRecord>;
    userMapAllowedValues: Array<FormComponentValue>;
    onChange: (filterId: string, columnId: string) => void;
}) {
    const filterId = String(row.filter_id);
    const record = fieldColumnMap.find((m) => m.filterColumn == filterId);
    const value = String(record ? record.mapColumn : 0);

    const selectedList = fieldColumnMap.filter((m) => m.filterColumn != filterId).map((m) => m.mapColumn);

    const currentList = userMapAllowedValues.filter((v) => {
        return !selectedList.includes(v.value) || v.value == '0';
    });

    return (
        <ReactSelect
            data={currentList}
            value={value}
            update={(value) => {
                onChange(filterId, String(value));
            }}
            placeholder={'--'}
        />
    );
}

export default function EditorExternalFiltersGrid({
    controlProps,
}: FormElementControlPropsType<EditorExternalFiltersGridControlProps>) {
    const { t } = useBundleTranslation();
    // Prepare URLs
    const pks = Object.keys(controlProps.urlParams);
    const fetchUrl = processSettingsUrl(controlProps.gridUrl, pks, controlProps.urlParams);
    const reorderUrl = processSettingsUrl(controlProps.reorderUrl, pks, controlProps.urlParams);
    const [gridReorderLoading, setGridReorderLoading] = useState(false);

    // Selected Field-UserMapColumn
    const [fieldColumnMap, setFieldColumnMap] = useState<Array<FieldColumnRecord>>([]);
    // Checked Visible In Viewer
    const [visibleInViewer, setVisibleInViewer] = useState<AssocArray<YNValues>>({});
    const visibleInViewerRef = useRef<AssocArray<YNValues>>(visibleInViewer);
    useEffect(() => {
        visibleInViewerRef.current = visibleInViewer;
    }, [visibleInViewer]);
    // Load UserMapColumns
    const userMapId = Number(controlProps.userMapId);
    const { data: userMapData } = useQuery<Array<FormComponentValue>, Error>({
        queryKey: ['userMapColumns', userMapId],
        queryFn: () => {
            if (userMapId == 0) {
                return [];
            }
            return editorUserMapAPI.getUserMapColumnsAsValues(userMapId);
        },
    });
    const [userMapAllowedValues, setUserMapAllowedValues] = useState<Array<FormComponentValue>>([]);
    useEffect(() => {
        if (Array.isArray(userMapData)) {
            setUserMapAllowedValues([{ label: '--', value: '0' }].concat(prepareFormComponentValues(userMapData)));
        }
    }, [userMapData]);
    // Reset selected Columns on UserMap Change
    useEffect(() => {
        const allowedColumns = userMapAllowedValues.map((row) => String(row.value));
        setFieldColumnMap(
            fieldColumnMap.map((row) => {
                if (!allowedColumns.includes(row.mapColumn)) {
                    row.mapColumn = '0';
                }
                return row;
            }),
        );
    }, [userMapAllowedValues]);

    // Load grid Data
    const { data, isLoading, refetch } = useQuery<any, Error>({
        queryKey: ['gridUID', fetchUrl, controlProps.urlParams.gridRefetch],
        queryFn: () => {
            if (
                typeof controlProps.urlParams?.reference == 'undefined' ||
                Number(controlProps.urlParams?.reference) <= 0
            ) {
                return false;
            }
            return gridAPI.load(fetchUrl);
        },
    });
    // Store gridData & provide Ref for HookFormSubscription
    const [gridData, setGridData] = useState<GridData>([]);
    const [processedGridData, setProcessedGridData] = useState<GridData>([]);
    const gridDataRef = useRef<GridData>(gridData);
    useEffect(() => {
        segmentSelectionRef.current = controlProps.segmentSelection;
        setProcessedGridData(applyDisabledToRows(gridData));
    }, [controlProps.segmentSelection]);
    // Store Segment Type & provide Ref for HookFormSubscription
    const segmentSelectionRef = useRef<SegmentSelection>(controlProps.segmentSelection);
    useEffect(() => {
        gridDataRef.current = gridData;
        setProcessedGridData(applyDisabledToRows(gridData));
        // Fill FieldUserMapColumn with initial values;
        if (fieldColumnMap.length == 0) {
            setFieldColumnMap(
                gridData.map((row) => ({
                    filterColumn: String(row.filter_id),
                    mapColumn: String(row.user_map_column_id == '' ? '0' : row.user_map_column_id),
                })),
            );
        }
        // Fill VisibleInViewer with initial values;
        const list: AssocArray<YNValues> = {};
        gridData.forEach((row) => {
            list[String(row.filter_id)] = row.visible_in_viewer_ind == 'Y' ? 'Y' : 'N';
        });
        setVisibleInViewer(list);
    }, [gridData]);

    // Grid Columns
    const [columns, setColumns] = useState<ColumnType[]>([]);
    const [processedGridColumns, setProcessedGridColumns] = useState<ColumnType[]>([]);
    // List of values for DropDowns
    const [selectionList, setSelectionList] = useState<Array<FormComponentValue>>([]);
    // Process grid data response
    useEffect(() => {
        if (data) {
            setColumns(data.data.settings.columns);
            setGridData(data.data.data.rows);
            setSelectionList(
                prepareFormComponentValues(
                    data.data.data.rows.map((row: any) => ({
                        value: String(row.filter_id),
                        label: String(row.name_raw),
                    })),
                ),
            );
        }
    }, [data]);
    // Hide UserMap Column if usageMethod is 'standard'
    useEffect(() => {
        let list = columns.slice();
        if (controlProps.usageMethod == 'standard') {
            list = list.filter((c) => !columnsInUserMapMode.includes(c.name));
        }
        if (controlProps.usageMethod == 'user map') {
            list = list.filter((c) => !columnsInStandardMode.includes(c.name));
        }
        setProcessedGridColumns(list.map((c) => ({ ...c, resizable: false })));
    }, [columns, controlProps.usageMethod]);
    // Prepare settings from FormSelects components
    const selectPropsFirst = prepareFormElementProps({
        form: controlProps.form,
        ...{
            component: {
                props: { addonLabel: 'Filter used as Dimension' },
                name: 'primary_external_filter_id',
                component: 'FormSelect',
            } as RawFormComponentType,
        },
    });
    const selectPropsSecond = prepareFormElementProps({
        form: controlProps.form,
        ...{
            component: {
                props: { addonLabel: 'Filter used as Dimension' },
                name: 'secondary_external_filter_id',
                component: 'FormSelect',
            } as RawFormComponentType,
        },
    });
    // Drag and Drop options
    const dragAndDrop = {
        enabled: true,
        url: reorderUrl,
        idColumn: 'filter_id',
    };
    // Drag and drop handlers
    const handleDropRow = (sourceId: string, targetId: string) => {
        const dragItem = gridData.find((item) => item['filter_id'] === sourceId);
        const targetItem = gridData.find((item) => item['filter_id'] === targetId);

        if (dragItem && targetItem) {
            const dragItemIndex = gridData.indexOf(dragItem);
            const targetItemIndex = gridData.indexOf(targetItem);
            setGridReorderLoading(true);

            const newGridData = update(gridData, {
                $splice: [
                    [dragItemIndex, 1],
                    [targetItemIndex, 0, dragItem],
                ],
            });

            gridAPI
                .updateGridOrder(dragAndDrop.url, {
                    new_order: newGridData.map((item) => {
                        return item['filter_id'];
                    }),
                })
                .then((response) => {
                    if (response.data.status === 'OK') {
                        refetch().then(() => {
                            setGridReorderLoading(false);
                        });
                    } else {
                        setGridReorderLoading(false);
                    }
                });
        }
    };

    // Mark rows as Disabled
    const applyDisabledToRows = (rawData: GridData): GridData => {
        const v1 =
            segmentSelectionRef.current != 'none'
                ? String(controlProps.form.hookFormGetValues('primary_external_filter_id'))
                : '';
        const v2 =
            segmentSelectionRef.current == 'compound'
                ? String(controlProps.form.hookFormGetValues('secondary_external_filter_id'))
                : '';

        // Mark as checked
        const visibleList = { ...visibleInViewerRef.current };
        if (typeof visibleList[v1] != 'undefined') {
            visibleList[v1] = 'Y';
        }
        if (typeof visibleList[v2] != 'undefined') {
            visibleList[v2] = 'Y';
        }
        if (JSON.stringify(visibleInViewerRef.current) != JSON.stringify(visibleList)) {
            setVisibleInViewer(visibleList);
        }

        // Process row
        const list = rawData.slice();
        return list.map((row) => {
            const rowCopy = { ...row };
            if ([v1, v2].includes(String(row.filter_id))) {
                rowCopy.enabled_ind = 'N';
                rowCopy.edit = '';
                rowCopy.edit_user_map = '';
                rowCopy.value_set = t('all_values');
            }

            return rowCopy;
        });
    };

    // Subscribe to DropDown Changes
    useEffect(() => {
        const subscription = controlProps.form.hookFormWatch((value, { name, type }) => {
            if (name == 'primary_external_filter_id' || name == 'secondary_external_filter_id') {
                setProcessedGridData(applyDisabledToRows(gridDataRef.current));
            }
        });
        return () => subscription?.unsubscribe();
    }, [controlProps.form.hookFormWatch]);

    const handleColumnSelectChange = (filterId: string, columnId: string) => {
        const list = fieldColumnMap.slice();
        const index = fieldColumnMap.findIndex((m) => m.filterColumn == filterId);
        if (index == -1) {
            return;
        }
        list[index].mapColumn = columnId;
        setFieldColumnMap(list);
        setTimeout(() => controlProps.form.hookFormSetValue('', '', { shouldDirty: true }), 100);
    };
    // Visible CheckBox Changes
    const handleVisibleInViewerChange = (filterId: string, checked: boolean) => {
        const list = { ...visibleInViewer };
        list[filterId] = checked ? 'Y' : 'N';
        setVisibleInViewer(list);
        setTimeout(() => controlProps.form.hookFormSetValue('', '', { shouldDirty: true }), 100);
    };
    // Update component Form Value
    useEffect(() => {
        const result = fieldColumnMap.map((row) => {
            return {
                filterId: row.filterColumn,
                columnId: row.mapColumn,
                isVisible: visibleInViewer[row.filterColumn] == 'Y' ? 'Y' : 'N',
            };
        });
        if (data?.data?.data?.results == 0) {
            controlProps.form.hookFormSetValue(controlProps.name, '[]');
        } else {
            controlProps.form.hookFormSetValue(controlProps.name, JSON.stringify(result));
        }
    }, [fieldColumnMap, visibleInViewer, JSON.stringify(processedGridData), JSON.stringify(data)]);

    const [segmentLabel, setSegmentLabel] = useState('');
    useEffect(() => {
        const subscription = controlProps.form.hookFormWatch((value, { name, type }) => {
            if (name === 'segment_id') {
                setSegmentLabel(getElementFieldValue(controlProps.form, `segment_id.$option.label`) ?? '');
            }
        });
        return () => subscription?.unsubscribe();
    }, [controlProps.form.hookFormWatch]);

    useEffect(() => {
        if (segmentSelectionRef.current == 'none') {
            return;
        }
        if (!controlProps.form.getElementValuesRegister('primary_external_filter_id') && selectionList.length) {
            controlProps.form.hookFormSetValue('primary_external_filter_id', selectionList[0].value);
        }
        if (segmentSelectionRef.current == 'compound') {
            if (
                !controlProps.form.getElementValuesRegister('secondary_external_filter_id') &&
                selectionList.length > 1
            ) {
                controlProps.form.hookFormSetValue('secondary_external_filter_id', selectionList[1].value);
            }
        }
    }, [segmentSelectionRef.current]);

    return (
        <>
            <input
                name={controlProps.name}
                type={'hidden'}
                value={controlProps.value}
                onChange={controlProps.onChange}
            />
            {data && (
                <div>
                    <MITable
                        legendConfig={[
                            {
                                type: 'disabled',
                                message: '',
                                hidden: true,
                            },
                        ]}
                        gridName={'external_filter'}
                        autoHeight
                        data={processedGridData}
                        isLoading={isLoading}
                        columns={processedGridColumns}
                        massActionsConfig={{ enabled: false }}
                        dragAndDrop={dragAndDrop}
                        handleDropRow={handleDropRow}
                        reloadGridData={() => {
                            refetch();
                        }}
                        gridReorderLoading={gridReorderLoading}
                        customCells={{
                            mySelect: ({ data }: CustomCellProps) => (
                                <UserMapColumnSelect
                                    userMapAllowedValues={userMapAllowedValues}
                                    row={data ?? {}}
                                    fieldColumnMap={fieldColumnMap}
                                    onChange={handleColumnSelectChange}
                                />
                            ),
                            myCheckbox: ({ data }: CustomCellProps) => {
                                const v1 =
                                    segmentSelectionRef.current != 'none'
                                        ? String(controlProps.form.hookFormGetValues('primary_external_filter_id'))
                                        : '';
                                const v2 =
                                    segmentSelectionRef.current == 'compound'
                                        ? String(controlProps.form.hookFormGetValues('secondary_external_filter_id'))
                                        : '';

                                const id = String(data?.filter_id);
                                return (
                                    <Checkbox
                                        onChange={(event, checked) => handleVisibleInViewerChange(id, checked)}
                                        checked={visibleInViewer[id] == 'Y'}
                                        value={'Y'}
                                        disabled={id == v1 || id == v2}
                                    />
                                );
                            },
                        }}
                    />
                    <Stack direction={'column'}>
                        {controlProps.segmentSelection != 'none' && (
                            <Box>
                                <FormGroup>
                                    <FormLabel>{t('filter_user_as', { v: segmentLabel })}</FormLabel>
                                    <ReactHookFormController
                                        elementProps={selectPropsFirst}
                                        componentValues={selectionList}
                                    />
                                </FormGroup>
                            </Box>
                        )}
                        {controlProps.segmentSelection == 'compound' && (
                            <Box sx={{ pt: '16px' }}>
                                <ReactHookFormController
                                    elementProps={selectPropsSecond}
                                    componentValues={selectionList}
                                />
                            </Box>
                        )}
                    </Stack>
                </div>
            )}
        </>
    );
}

export class EditorExternalFiltersGridBuilder extends FormComponentBuilder {
    prepareProps(): EditorExternalFiltersGridControlProps {
        return {
            ...this.controlProps,
            urlParams: {
                ...this.elementProps.urlParams,
                ...this.elementProps.componentProps?.urlParams,
            } as UrlParams,
            gridUrl: this.elementProps.componentProps?.gridUrl ?? '',
            reorderUrl: this.elementProps.componentProps?.reorderUrl ?? '',
            segmentSelection: this.elementProps.componentProps?.segmentSelection ?? 'none',
            userMapId: this.elementProps.componentProps?.userMapId ?? '0',
            usageMethod: this.elementProps.componentProps?.usageMethod ?? 'standard',
        } as EditorExternalFiltersGridControlProps;
    }
}
