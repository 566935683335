import { Box } from '@mui/material';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import useBundleTranslation from 'i18n';
import { useIsConfigConstantEqual } from 'hooks/useConfigConstant';

function useAdjustIframeLayout(
    iframeRef: MutableRefObject<HTMLIFrameElement | undefined>,
    imageSize?: { height: number; width: number },
): number {
    const [resultHeight, setResultHeight] = useState(0);
    const adjustIframeHeight = function () {
        if (!imageSize || imageSize?.width == 0 || !iframeRef.current) {
            setResultHeight(0);
            return;
        }
        let height = 650;
        const src = iframeRef.current.src;
        const iWidth = iframeRef.current.getBoundingClientRect().width;
        if ('undefined' !== typeof src && src && -1 !== src.indexOf('index/intermediate/')) {
            height = Math.floor((imageSize.height / imageSize.width) * iWidth) + 1;
        } else {
            height = Math.floor(((imageSize.height + 75) / imageSize.width) * iWidth) + 35;
        }
        setResultHeight(height < 500 ? 500 : height);
    };
    useEffect(() => {
        const onResize = () => {
            adjustIframeHeight();
        };
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, [imageSize, iframeRef]);

    useEffect(() => {
        adjustIframeHeight();
    }, [iframeRef.current, imageSize]);

    return resultHeight;
}

export default function EmbedElement({
    embedUrl,
    displayType,
    url,
    iframeHeight,
    iframeWidth,
    fullScreen,
    imageSize,
}: {
    embedUrl: string;
    displayType: 'image' | 'iframe' | 'pdf';
    fullScreen?: boolean;
    url?: string;
    iframeHeight?: number;
    iframeWidth?: number;
    imageSize?: {
        height: number;
        width: number;
    };
}) {
    const [isLoaded, setIsLoaded] = useState(false);
    const handleOnLoad = (e: any) => {
        if (displayType == 'image') {
            if (imgRef?.current?.complete) {
                setIsLoaded(true);
            }
        } else {
            if (e?.target?.src != window.location.href && e?.target?.src != encodeURI(window.location.href)) {
                setIsLoaded(true);
            }
        }
    };

    const imgRef = useRef<HTMLImageElement>(null);
    const image = (
        <img
            ref={imgRef}
            onLoad={handleOnLoad}
            onError={handleOnLoad}
            style={{ minHeight: '650px', maxWidth: '100%' }}
            src={embedUrl}
        />
    );

    // Default Height
    let height = fullScreen ? '100%' : '650px';
    if (displayType == 'iframe' && iframeHeight) {
        height = iframeHeight + 'px';
    } else if (displayType == 'image') {
        height = '100%';
    }
    const { t } = useBundleTranslation(['components/report-content']);

    const iframeRef = useRef<HTMLIFrameElement>();

    const adjustHeight = useAdjustIframeLayout(iframeRef, displayType == 'iframe' ? imageSize : undefined);
    if (adjustHeight > 0) {
        height = adjustHeight + 'px';
    }

    const isAIClipboard = useIsConfigConstantEqual('ALLOW_IFRAME_CLIPBOARD_READ_WRITE', true);

    return (
        <div
            data-test={'external-embed-element'}
            style={{
                width: '100%',
                height: height,
                overflow: 'auto',
                textAlign: 'center',
                position: 'relative',
            }}
        >
            {!isLoaded && (
                <LoadingPlaceholder sx={{ position: 'absolute' }}>
                    <>{t('loading___')}</>
                </LoadingPlaceholder>
            )}
            {displayType == 'image' ? (
                url ? (
                    <a target={'_blank'} href={url}>
                        {image}
                    </a>
                ) : (
                    <span>{image}</span>
                )
            ) : (
                <Box
                    allow={isAIClipboard ? 'clipboard-read; clipboard-write' : undefined}
                    ref={iframeRef}
                    id={'iframe-component'}
                    onLoad={handleOnLoad}
                    onError={handleOnLoad}
                    component="iframe"
                    width={iframeWidth ? iframeWidth : '100%'}
                    height={height}
                    src={embedUrl}
                    sx={{ border: 0, display: 'block', minHeight: iframeHeight ? undefined : '650px' }}
                />
            )}
        </div>
    );
}
