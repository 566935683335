import React, { useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { ButtonConfig, PopupMode, PopupType } from 'components/common/grid/';
import { logWarning, prepareTranslatedText } from 'tools/tools';
import { TFunction } from 'i18next';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import { useNavigate } from 'react-router-dom';
import IconHandler from 'components/common/icon/IconHandler';
import { onButtonClickAction } from './index';
import showdown from 'showdown';
import parse from 'html-react-parser';
import { FormRendererAPIType } from 'components/common/form';
import { gridAPI } from 'api/grid';

const converter = new showdown.Converter();

export default function CreateButton({
    config,
    controllerUrl,
    setPopupType,
    setPopupMode,
    setPopupRedirectAfter,
    setPopupUrl,
    setPopupConfig,
    setPopupGetMethod,
    setPopupComponent,
    setPopupComponentProps,
    setFormPostData,
    t,
    id,
    selectedItems,
    reloadGridData,
    setShowLoader,
    form,
}: {
    config: ButtonConfig;
    controllerUrl: string;
    setPopupType?: (type: PopupType) => void;
    setPopupMode?: (mode: PopupMode) => void;
    setPopupRedirectAfter?: (redirect: boolean) => void;
    setPopupUrl?: (url: string) => void;
    setPopupConfig?: (popupConfig: PopupSettings) => void;
    setFormPostData?: (formData: any) => void;
    setPopupGetMethod?: (getMethod: boolean) => void;
    setPopupComponent?: (popupComponent: string) => void;
    setPopupComponentProps?: (popupComponentProps: any) => void;
    t: TFunction;
    id: string;
    selectedItems?: Array<string | number>;
    reloadGridData?: () => void;
    setShowLoader?: (value: boolean) => void;
    form?: FormRendererAPIType;
}) {
    const startIcon = config.icon ?? { type: 'mi', value: 'new' };
    const navigate = useNavigate();

    const [errorPopupMessage, setErrorPopupMessage] = useState('');
    const [errorLink, setErrorLink] = useState('');

    const popupSettings: PopupSettings = {
        title: config.errorTitle ?? t('error'),
        noOk: true,
        textCancel: t('close'),
        needTranslation: false,
    };

    const onErrorPopupHide = () => {
        setErrorPopupMessage('');
        setErrorLink('');
    };

    const buttonClick = () =>
        onButtonClickAction(
            config,
            controllerUrl,
            'create',
            navigate,
            {
                setPopupUrl,
                setPopupConfig,
                setPopupType,
                setPopupMode,
                setPopupRedirectAfter,
                setFormPostData,
                setPopupGetMethod,
                setPopupComponent,
                setPopupComponentProps,
            },
            config.withSelected ? selectedItems : undefined,
            setShowLoader,
            reloadGridData,
            undefined,
            undefined,
            setErrorPopupMessage,
            undefined,
            setErrorLink,
        );

    const onCreateClick = () => {
        try {
            if (form && config.saveBefore) {
                form.formSave().then(() => {
                    buttonClick();
                });
            } else {
                buttonClick();
            }
        } catch (error: any) {
            logWarning(error.message);
            return;
        }
    };

    const showErrorPopup = errorPopupMessage > '';
    const { text: btnText, needTranslation } = prepareTranslatedText(config.text ?? 'Create');
    return (
        <>
            <Grid item key={config.name} sx={{ flexShrink: 0 }}>
                <Button
                    onClick={onCreateClick}
                    key={config.name + '-create-button'}
                    color={config.color ? (config.color as any) : 'success'}
                    startIcon={<IconHandler icon={startIcon} />}
                    variant={config.variant ? (config.variant as any) : 'light'}
                    data-test={id}
                >
                    {needTranslation === false ? btnText : t(btnText)}
                </Button>
            </Grid>
            {showErrorPopup && (
                <Popup settings={popupSettings} open={showErrorPopup} onHide={onErrorPopupHide} maxWidth={'popupSm'}>
                    {errorLink && errorLink > '' ? (
                        <Box>
                            {`${errorPopupMessage} `}
                            <Box
                                component={'a'}
                                onClick={() => {
                                    gridAPI.asyncAction(errorLink).catch((error) => {
                                        console.error(error);
                                    });
                                    onErrorPopupHide();
                                }}
                                sx={{ cursor: 'pointer' }}
                            >
                                {t('link')}
                            </Box>
                        </Box>
                    ) : (
                        <Box>{parse(converter.makeHtml(errorPopupMessage))}</Box>
                    )}
                </Popup>
            )}
        </>
    );
}
