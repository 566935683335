import { Box, Button, Stack, Typography } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import React, { useMemo } from 'react';
import Group from './condition/Group';
import { ColumnData, GroupRule, RulesConfig } from '../index';
import useBundleTranslation from 'i18n';
import styles from './ConditionBlock.styles';

function ConditionBlock({
    filter,
    fields,
    builderDirty,
    applyChanges,
    onCurrentFilterUpdate,
    compactView,
    showGroupApply,
}: {
    filter: RulesConfig;
    fields: ColumnData[];
    builderDirty: boolean;
    applyChanges: () => void;
    onCurrentFilterUpdate: (newFilter: RulesConfig) => void;
    compactView?: boolean;
    showGroupApply?: boolean;
}) {
    const { t } = useBundleTranslation(['components/dataset-viewer/dataset_viewer']);

    const labelMain = filter.history ? t('condition.define_filters_changes') : t('condition.define_filters');
    const labelNew = filter.history
        ? t('condition.define_filters_for.main') +
          (filter.trackNewRows ? ` ${t('condition.define_filters_for.part_new')}` : '') +
          (filter.trackNewRows && filter.trackRemovedRows ? ` ${t('condition.define_filters_for.part_and')}` : '') +
          (filter.trackRemovedRows ? ` ${t('condition.define_filters_for.part_removed')}` : '')
        : '';

    const showChangesBlock = (filter.history && filter.trackChangedRows) || !filter.history;
    const showNewBlock = filter.history && (filter.trackNewRows || filter.trackRemovedRows);

    const currentFields = useMemo(
        () =>
            fields.map((field) => {
                const newField = { ...field };
                const key = field.derived ? field.id : field.id.replace(' ', '_');

                newField.key = `${key}-current`;

                return newField;
            }),
        [fields],
    );

    const priorFields = useMemo(() => {
        const newFields = [...currentFields];

        if (filter.history) {
            currentFields.forEach((field) => {
                const priorField = { ...field };
                const key = field.derived ? field.id : field.id.replace(' ', '_');
                priorField.label = `Prior ${priorField.label}`;
                priorField.key = `${key}-prior`;
                priorField.current = false;
                newFields.push(priorField);
            });
        }

        return newFields;
    }, [currentFields, filter.history]);

    const onGroupUpdate = (newRule: GroupRule, isNew: boolean = false) => {
        const newFilter = { ...filter };

        if (isNew) {
            newFilter.group_new = newRule;
        } else {
            newFilter.group = newRule;
        }

        onCurrentFilterUpdate(newFilter);
    };

    const applyButton = (
        <Button
            data-test={'apply-button'}
            variant={'contained'}
            color={'success'}
            sx={{ flexShrink: 0 }}
            startIcon={<IconMi icon={'check-square'} />}
            disabled={!builderDirty}
            onClick={applyChanges}
        >
            {t('condition.define_filters_apply_btn')}
        </Button>
    );
    if (!(currentFields.length > 0 && priorFields.length > 0)) {
        return null;
    }

    return (
        <Stack sx={styles.wrapper} className={compactView ? 'view--compact' : 'view--dataset'}>
            <Stack sx={styles.content}>
                {showChangesBlock && (
                    <Stack spacing={2} sx={{ px: 3, pb: 2 }}>
                        {!compactView && (
                            <Stack
                                data-test={'header-row'}
                                direction="row"
                                alignItems="flex-end"
                                sx={styles.stickyHeader}
                            >
                                <Typography variant="heading" width={'100%'}>
                                    {labelMain}
                                </Typography>
                            </Stack>
                        )}

                        {filter.group && (
                            <Group
                                compactView={compactView}
                                groupRule={filter.group}
                                groupIndex={0}
                                fields={priorFields}
                                key={`root-group-current`}
                                onUpdate={onGroupUpdate}
                                type={'current'}
                                isRoot
                                applyButton={applyButton}
                                showGroupApply={showGroupApply}
                            />
                        )}
                    </Stack>
                )}

                {filter.history && showNewBlock && (
                    <Stack spacing={2} sx={{ px: 3, pb: 2 }}>
                        <Stack direction="row" alignItems="flex-end" sx={styles.stickyHeader}>
                            <Typography variant="heading" width={'100%'}>
                                {labelNew}
                            </Typography>
                        </Stack>
                        {filter.group_new && (
                            <Group
                                groupRule={filter.group_new}
                                fields={currentFields}
                                key={`root-group-new`}
                                groupIndex={1}
                                onUpdate={onGroupUpdate}
                                type={'new'}
                                isRoot
                            />
                        )}
                    </Stack>
                )}

                {!showChangesBlock && !(filter.history && showNewBlock) && (
                    <Stack sx={{ px: 3, pb: 2 }}>
                        <Stack direction="row" alignItems="flex-end" sx={styles.stickyHeader}>
                            <Typography variant="heading" width={'100%'}>
                                {' '}
                            </Typography>
                        </Stack>
                    </Stack>
                )}

                {!compactView && <Box sx={styles.fixedButtonHolder}>{applyButton}</Box>}
            </Stack>
        </Stack>
    );
}

export default ConditionBlock;
